import * as services from '../../services/api/index';
import { buildTreeData, resetNameArr, defaultExpend, toMatch } from '../../utils/tree';
const initialState = {
  dataTreelists: {},
  dataTree: [], // name组成的数组，重组之后的一维的数组
  filterDataTreelists: {},
  treeLoad: false, // 树加载
  selectedTreeKeys: [],
  expandedTreeKeys: [],
  classify: true, // project筛选参数： project 分类
  currentProjectName: '', // project筛选参数：当前项目名
  types: '', // project筛选参数：项目type
  searchValue: '', // 搜索value
  projectNames: [],
  selected: {}, // 当前被选中的节点对象
  copied: {}, // 被复制的节点对象
  isCut: false // 是否为剪切
};
export default {
  name: 'projectTree',
  state: initialState,
  reducers: {
    updateLists: (state, { data }) => {
      buildTreeData(data, {});
      const dataTree = resetNameArr(data, []);
      return {
        ...state,
        dataTreelists: data,
        dataTree,
        filterDataTreelists: data
      };
    },
    updateProjectNames: (state, { data }) => {
      return {
        ...state,
        projectNames: data
      };
    },
    updateClassify: (state, { data }) => {
      return {
        ...state,
        classify: data
      };
    },
    updateCurrentProjectName: (state, { data }) => {
      return {
        ...state,
        currentProjectName: data
      };
    },
    updateTypes: (state, { data }) => {
      return {
        ...state,
        types: data
      };
    },
    updateSelectedTreeKeys: (state, { data }) => {
      return {
        ...state,
        selectedTreeKeys: data
      };
    },
    updateExpandedTreeKeys: (state, { data }) => {
      return {
        ...state,
        expandedTreeKeys: data
      };
    },
    updateExpandedTreeKeysForClose: state => {
      const ids = defaultExpend(state.dataTreelists, 1, 1, []);
      return {
        ...state,
        expandedTreeKeys: ids
      };
    },
    updateExpandedTreeKeysForOpen: state => {
      const ids = defaultExpend(state.dataTreelists, 3, 1, []);
      return {
        ...state,
        expandedTreeKeys: ids
      };
    },
    updateExpandedTreeKeysForMatch: (state, { data }) => {
      const expandedTreeKeys = toMatch(state.dataTreelists, data);
      return {
        ...state,
        expandedTreeKeys
      };
    },
    updateSelected: (state, { data }) => {
      return {
        ...state,
        selected: data
      };
    },
    updateCopy: (state, { data }) => {
      return {
        ...state,
        copied: data,
        isCut: false
      };
    },
    updateCut: (state, { data }) => {
      return {
        ...state,
        copied: data,
        isCut: true
      };
    },
    updateCurrentSelectedId: (state, { data }) => {
      return {
        ...state,
        currentSelectedId: data
      };
    },
    updateTreeLoad: (state, { data }) => {
      return {
        ...state,
        treeLoad: data
      };
    }
  },
  effects: {
    queryLists: async ({ params, isOpen }, { dispatch }, { state }) => {
      dispatch({ type: 'projectTree/updateTreeLoad', data: true });
      const res = await services.project.lists(params).catch(err => {});
      dispatch({ type: 'projectTree/updateTreeLoad', data: false });

      if (res.code !== 0) {
        return;
      }
      dispatch({ type: 'projectTree/updateLists', data: res.data.rootFile });
      if (!params.projectName) {
        dispatch({ type: 'projectTree/updateProjectNames', data: res.data.projectNames });
      }
      // 未传入isOpen的时候
      if (isOpen === undefined) {
        // 如果已存在展开的节点，则延续之前的展开节点
        if (state.expandedTreeKeys.length > 0) {
          dispatch({ type: 'projectTree/updateExpandedTreeKeys', data: state.expandedTreeKeys });
          // 否则默认展开两层
        } else {
          const ids = defaultExpend(res.data.rootFile, 2, 1, []); //展开两层
          dispatch({ type: 'projectTree/updateExpandedTreeKeys', data: ids });
        }
      } else {
        if (isOpen) {
          dispatch({ type: 'projectTree/updateExpandedTreeKeysForOpen' }); // 展开3层
        } else {
          dispatch({ type: 'projectTree/updateExpandedTreeKeysForClose' }); // 关闭
        }
      }

      return res.data;
    }
  }
};
