import services from '../createServices';

export const userGroup = {
  query: params => services.post('/user/group/query', { params, isLoading: true }),
  add: params => services.post('/user/group/add', { params }),
  update: params => services.post('/user/group/update', { params }),
  remove: params => services.post(`/user/group/delete`, { params, isFormdata: true }),
  detail: id => services.get(`/user/group/detail/${id}`, { params: {}, errorCatch: true }),
  parentOFgroup: params => services.get('/user/group/self?includeBelong=true', { params }),
  workspaceList: parentGroupId =>
    services.get(`/workspace/list/all?parentGroupId=${parentGroupId || ''}`, { params: {} }),
  authorityAll: parentGroupId => services.get(`/authority/all?parentGroupId=${parentGroupId || ''}`, { params: {} })
};
