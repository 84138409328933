const initialState = {
  projectName: '',
  loading: false
};
export default {
  name: 'global',
  state: initialState,
  reducers: {
    updateLoading: (state, { data }) => {
      return {
        ...state,
        loading: data
      };
    },
    updateProjectName: (state, { data }) => {
      return {
        ...state,
        projectName: data
      };
    }
  }
};
