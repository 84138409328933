import React from 'react';
import ConditionSelector from './Selector/ConditionSelector';
import InputValue from './Common/InputValue';
import DynamicVariableValue from './Common/DynamicVariableValue';
import MethodValue from './Common/MethodValue';
import FunctionValue from './Common/FunctionValue';
import ConstantsValue from './Common/ConstantsValue';
import VariableValue from './Common/VariableValue';
import ComplexArithmetic from './ComplexArithmetic';
import ParameterValue from './Common/ParameterValue';
import Range from './Common/Range';
import { ruleObj } from '../../../utils/rule';

class ConditionValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }

  choose = (data, type) => {
    const { item } = this.props;
    item.value = {};
    switch (type) {
      case 'variable':
        this.setVariableValue(item.value, data, true);
        break;
      case 'input':
        this.setInputValue(item.value);
        break;
      case 'constant':
        this.setConstantsValue(item.value, data, true);
        break;
      case 'parameter':
        this.setParameterValue(item.value, data, true);
        break;
      case 'method':
        this.setMethodsValue(item.value, data, true);
        break;
      case 'commonfunction':
        this.setFunctionValue(item.value, data, true);
        break;

      default:
        break;
    }
  };

  getMenus = () => {
    const menus = [
      {
        name: '输入值',
        action: this.choose.bind(this, {}, 'input')
      }
    ];
    const { item } = this.props;
    const {
      variables,
      constants,
      params,
      methods,
      functions,
      hasVariables,
      hasParams,
      hasMethods,
      hasFunctions,
      hasConstants
    } = ruleObj.file;
    if (variables && hasVariables) {
      menus.push({
        name: '选择变量',
        action: null,
        children: this.getVariableMenus(variables[0] || [], true)
      });
    }
    menus.push({
      name: '搜索变量',
      action: this.setDynamicVariableValue.bind(this, item.value, true)
    });
    if (constants && hasConstants) {
      menus.push({
        name: '选择常量',
        action: null,
        children: this.getConstantsMenus(constants)
      });
    }
    if (params && hasParams) {
      menus.push({
        name: '选择参数',
        action: null,
        children: this.getParameterMenus(params[0] || [])
      });
    }
    if (methods && hasMethods) {
      menus.push({
        name: '选择方法',
        action: null,
        children: this.getMethodsMenus(methods)
      });
    }
    if (functions && hasFunctions) {
      menus.push({
        name: '选择函数',
        action: null,
        children: this.getFunctionsMenus(functions)
      });
    }
    return menus;
  };

  // 获取最终的type, 为了区分搜索变量
  getFinallyType = (type, data) => {
    if (type === 'Method') {
      if (data.methodName === 'getRemoteData' || data.methodName === 'getRemoteDataV2') {
        return 'DynamicVariable';
      } else {
        return type;
      }
    } else {
      return type;
    }
  };

  renderInputType = (data, isValue) => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    const type = this.getFinallyType(data.valueType, data);
    switch (type) {
      case 'Input':
        this.isDisabledSelector = false;
        return <InputValue data={data} {...props} />;
      case 'Variable':
      case 'VariableCategory':
        this.isDisabledSelector = false;
        return <VariableValue data={data} isValue={isValue} {...props} />;
      case 'DynamicVariable':
        this.isDisabledSelector = false;
        return <DynamicVariableValue data={data} isValue={isValue} {...props} />;
      case 'Constant':
        this.isDisabledSelector = false;
        return <ConstantsValue data={data} isValue={isValue} {...props} />;
      case 'Method':
        this.isDisabledSelector = true;
        return <MethodValue data={data} isValue={isValue} {...props} />;
      case 'Parameter':
        this.isDisabledSelector = false;
        return <ParameterValue data={data} isValue={isValue} {...props} />;
      case 'CommonFunction':
        this.isDisabledSelector = true;
        return <FunctionValue data={data} isValue={isValue} {...props} />;
      case 'Range':
        this.isDisabledSelector = true;
        return <Range data={data} isValue={isValue} {...props} />;
      default:
        this.isDisabledSelector = false;
        break;
    }
  };
  // 增对不同的操作比较符，有些在值后面有对应的文字
  getEndingText = op => {
    switch (op) {
      case 'In':
      case 'NotIn':
        return <span style={{ color: 'rgb(255, 0, 0)', margin: '0 2px' }}>之中</span>;

      default:
        break;
    }
  };
  getValue = () => {
    const { item } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    if (item.value && Object.keys(item.value).length === 0) {
      this.isDisabledSelector = false;
    }
    return (
      <span>
        {item.value && (
          <span>
            <span className='selector-text'>
              {Object.keys(item.value).length !== 0 && this.renderInputType(item.value, true)}
            </span>
            {item.value && Object.keys(item.value).length !== 0 && <ComplexArithmetic item={item.value} {...props} />}
          </span>
        )}

        {item.op && this.getEndingText(item.op)}
      </span>
    );
  };

  getPopText = () => {
    const { item } = this.props;

    if (item.value) {
      const { arithmetic, ...exceptArithmetic } = item.value;
      return (
        <span>
          {Object.keys(exceptArithmetic).length !== 0 ? (
            item.value.valueType !== 'Range' && <span className='dashed-selector' />
          ) : (
            <span style={{ color: '#2fa2ca' }} className='selector-text'>
              请选择值
            </span>
          )}
        </span>
      );
    } else {
      return null;
    }
  };
}
export default ConditionValue;
