/**
 * @param {Bealoon} isLoading 默认false/undefined，不启用loading效果。
 * @param {Bealoon} errorCatch 默认false/undefined,自动catch错误，如果想自己catch错误信息，处理错误信息，请传true
 * @param {Array} errorCode 默认undefined, 系统默认执行ERROR_CODE中的行为方式，要禁止系统弹窗，请传[] 空数组
 * @param {Array} successCode 默认undefined, 系统默认执行SUCCESS_CODE中的行为方式，要禁止系统弹窗，请传[] 空数组
 * @param {Array} warningCode 默认undefined, 系统默认执行WARNING_CODE中的行为方式，要禁止系统弹窗，请传[] 空数组
 *
 */

import * as event from './event/componentEvent';
import storage from '../utils/storage';
import handleCode from '../utils/handleCode';
import { ERROR_CODE, SUCCESS_CODE, WARNING_CODE } from './constant';

// getApiUrl
function getApiUrl(url) {
  return `${url}`;
}

export default function request(url, { ...options }, { isLoading, errorCatch, errorCode, successCode, warningCode }) {
  //拦截检查userInfo
  event && event.eventEmitter.emit(event.CHECK_USERINFO);
  window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.CHECK_USERINFO);
  if (
    (window.currentWS && storage.currentWS !== window.currentWS) ||
    (window.top.currentWS && storage.currentWS !== window.top.currentWS)
  ) {
    return new Promise(() => {});
  }
  if (isLoading) {
    event && event.eventEmitter.emit(event.SHOW_LOADING);
    window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.SHOW_LOADING);
  }
  return fetch(getApiUrl(url), { ...options }).then(response => {
    const contentType = response.headers.get('content-type');

    // 验证不通过，httpCode 返回403，跳回登录
    if (response.status === 403) {
      response.json().then(res => {
        if (res.data !== '' && res.data !== null && res.data !== undefined) {
          window.location.href = res.data;
        } else {
          if (!window.top.location.href.includes('/login')) {
            window.top.location.href = '/login';
          }
        }
        storage.clear();
      });
      // httpCode 返回200，进入具体业务
    } else if (response.status === 200) {
      if (contentType && contentType.indexOf('json') !== -1) {
        return response.json().then(json => {
          if (isLoading) {
            event && event.eventEmitter.emit(event.HIDE_LOADING);
            window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
          }
          const codes = {
            ERROR_CODE: errorCode ? errorCode : ERROR_CODE,
            SUCCESS_CODE: successCode ? successCode : SUCCESS_CODE,
            WARNING_CODE: warningCode ? warningCode : WARNING_CODE
          };
          handleCode(json, codes); // 统一处理code
          return json;
        });
      } else {
        return response.text().then(text => {
          if (isLoading) {
            event && event.eventEmitter.emit(event.HIDE_LOADING);
            window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
          }
          return text;
        });
      }
      // httpCode 为其他状态，如500，
    } else {
      if (isLoading) {
        event && event.eventEmitter.emit(event.HIDE_LOADING);
        window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
      }
      if (contentType && contentType.indexOf('json') !== -1) {
        return response.json().then(r => {
          // 交由用户自行处理
          if (errorCatch) {
            return Promise.reject(r);
            //  统一处理error弹窗
          } else {
            if (event) {
              event.eventEmitter.emit(event.SHOW_ERROR_MSG, r.msg);
            } else {
              window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.SHOW_ERROR_MSG, r.msg);
            }
            console.log('统一处理', r);
            return;
          }
        });
      } else {
        return response.text().then(r => {
          // 交由用户自行处理
          if (errorCatch) {
            return Promise.reject(r);
            //  统一处理error弹窗
          } else {
            event.eventEmitter.emit(event.SHOW_ERROR_MSG, r.msg);
            window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.SHOW_ERROR_MSG, r.msg);
            console.log('统一处理', r);
            return;
          }
        });
      }
    }
  });
}
