import React, { Component } from 'react';
import { Table } from 'antd';

class RuleFile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { libraries } = this.props;
    const columns = [
      {
        title: '库文件路径',
        dataIndex: 'path',
        key: 'path'
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: value => (
          <span>
            {value === 'Variable' ? '变量' : value === 'Constant' ? '常量' : value === 'Action' ? '函数' : '参数'}
          </span>
        )
      }
    ];
    return (
      <div>
        <h4 style={{ marginBottom: '10px' }}>库文件</h4>
        <Table rowKey='path' columns={columns} dataSource={libraries} size='small' pagination={false} bordered />
      </div>
    );
  }
}

export default RuleFile;
