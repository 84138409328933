const storage = {};
Object.defineProperties(storage, {
  token: {
    enumerable: true,
    get() {
      const cookies = document.cookie.split(/;\s*/g);
      let cookieItem;
      while ((cookieItem = cookies.shift())) {
        if (cookieItem.indexOf('token') === 0) {
          return cookieItem.split('=')[1];
        }
      }
      return null;
    },
    set(val) {
      document.cookie = `token=${val};path=/`;
    }
  },
  sessionId: {
    enumerable: true,
    get() {
      const cookies = document.cookie.split(/;\s*/g);
      let cookieItem;
      while ((cookieItem = cookies.shift())) {
        if (cookieItem.indexOf('JSESSIONID') === 0) {
          return cookieItem.split('=')[1];
        }
      }
      return null;
    },
    set(val) {
      document.cookie = `JSESSIONID=${val};path=/`;
    }
  },
  userInfo: {
    enumerable: true,
    get() {
      if (localStorage.userInfo !== undefined) {
        return JSON.parse(localStorage.userInfo);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.userInfo = JSON.stringify(val);
    }
  },
  config: {
    enumerable: true,
    get() {
      if (localStorage.config !== undefined) {
        return JSON.parse(localStorage.config);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.config = JSON.stringify(val);
    }
  },
  currentWS: {
    enumerable: true,
    get() {
      if (localStorage.currentWS !== undefined) {
        return JSON.parse(localStorage.currentWS);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.currentWS = JSON.stringify(val);
    }
  },
  clipboard: {
    enumerable: true,
    get() {
      if (localStorage.clipboard !== undefined) {
        return JSON.parse(localStorage.clipboard);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.clipboard = JSON.stringify(val);
    }
  },

  tabCloseConfirm: {
    enumerable: true,
    get() {
      if (localStorage.tabCloseConfirm !== undefined) {
        return JSON.parse(localStorage.tabCloseConfirm);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.tabCloseConfirm = JSON.stringify(val);
    }
  },

  syncOnlineFiles: {
    enumerable: true,
    get() {
      if (localStorage.syncOnlineFiles !== undefined) {
        return JSON.parse(localStorage.syncOnlineFiles);
      } else {
        return {};
      }
    },
    set(val) {
      localStorage.syncOnlineFiles = JSON.stringify(val);
    }
  },

  clear: {
    value() {
      const temp = new Date();
      temp.setTime(temp.getTime() - 100);
      document.cookie = `token='';expires=${temp.toGMTString()};path=/`;
      localStorage.removeItem('userInfo');
      localStorage.removeItem('currentWS');
      localStorage.removeItem('config');
    }
  }
});

export default storage;
