import React, { Component } from 'react';
import RulePanel from './RulePanel';
import { connect } from 'react-redux';
import storage from '../../../utils/storage';
import { clipboardConstant } from '../../../utils/constant';
import { randomKey } from '../../../utils/utils';
import { conditionsRandomId } from '../Components/Condition';
import { actionsRandomId } from '../Components/Action';
import { unitsRandomId } from '../Components/Units';
import Sortable from 'react-sortablejs';
import { ruleObj } from '../../../utils/rule';
import { deepCopy, getCodeByKey } from '../../../utils/utils';
import RuleTool from './RuleTool';
import Modal from '../../../components/Modal';
import '../../../tantuer.less'; // tantuer skin
import './rule.less';
import PropertyPanel from '../../../components/PropertyPanel/index';
import RuleFile from '../Components/RuleFile';
import FileReference from '../../../components/FileReference';
import NavLink from './NavLink';
import RuleAddBtn from './RuleAddBtn';
import RuleSaveNotification from './RuleSaveNotification';
import PopMenu from '../../../components/PopMenu';
import * as services from '../../../services/api/index';
import TabWrapper from '../TabWrapper/index';

window._toggleBtn = () => {
  document.getElementById('backBtn').style.display = 'block';
};

@connect(mapStateToProps)
class Rule extends Component {
  constructor() {
    super();
    this.state = {
      checkList: [],
      currentNavLinkId: '',
      renderBody: false
    };

    this.file = '';
    this.rulePannels = [];
  }

  componentDidMount() {
    this.file = getCodeByKey(this.props.location.search, 'file');
    const ruleName = getCodeByKey(this.props.location.search, 'ruleName');
    this.ruleName = ruleName !== null ? decodeURI(ruleName) : ruleName;
    this.projectName = this.props.match.params.projectName;

    // 请求rules列表
    this.queryLists();

    // 请求函数 基本信息
    this.props.dispatch({
      type: 'file/loadFunctions',
      params: {
        filePath: decodeURI(this.file)
      }
    });

    // 请求version
    this.fileVersions();

    // 监听键盘
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
  }
  /**
   * 计算出target对象在window窗口中的列表，进行加载
   */
  renderInWindow = target => {
    const wrap = target.getBoundingClientRect();
    const t = wrap.top;
    const b = wrap.bottom;
    const s = target.scrollTop;
    document.querySelectorAll('.rule-pannel-collapse').forEach(item => {
      const it = item.getBoundingClientRect();
      if (it.top >= t + s && it.top <= b + s) {
        const id = item.getAttribute('id');
        const current = this.rulePannels.find(item => item.props.id === id);
        current.renderBody();
      }
    });
  };

  // 请求rules列表
  queryLists = () => {
    this.props.dispatch({
      type: 'rule/queryLists',
      params: {
        filePath: decodeURI(this.file)
      },
      isLoading: true
    });
  };

  fileVersions = async () => {
    const res = await services.file.currentVersion({ filePath: decodeURI(this.file) });
    if (res.code === 0) {
      this.setState({
        version: res.data
      });
    }
  };

  // 将子组件存入数组
  setChildrensToParent = (childrenArr, el) => {
    this[childrenArr].push(el);
  };

  // onKeyDown
  onKeyDown = e => {
    const { ruleIds } = this.props;
    const {
      selectedConditions,
      selectedActions,
      selectedRules,
      selectedActionAreas,
      selectedUnits,
      keyPressForCtrl
    } = ruleObj;
    const isSelectedTxt = !!window.getSelection().toString(); // 是否选中了文本，是--》js终端复制功能
    const isFocusOnInput =
      document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA' ? true : false;

    if (isSelectedTxt || isFocusOnInput) {
      return;
    }
    switch (e.key) {
      case 'Meta': // mac command键
      case 'Control': // mac Control键 /  windows ctrl
        ruleObj.keyPressForCtrl = true;
        PopMenu.close(); // 关闭菜单
        break;
      case 'c': // mac C 键
        // selectedConditions 被选中的条件不为空，则复制条件
        if (keyPressForCtrl && selectedConditions.length > 0) {
          this.props.dispatch({ type: 'rule/copyConditions', conditions: selectedConditions });
          return;
        }
        // selectedActions 被选中的动作不为空，则复制动作
        if (keyPressForCtrl && selectedActions.length > 0) {
          this.props.dispatch({ type: 'rule/copyActions', actions: selectedActions });
          return;
        }

        // selectedUnits 被选中的判断单元不为空，则复制判断单元
        if (keyPressForCtrl && selectedUnits.length > 0) {
          this.props.dispatch({ type: 'rule/copyUnits', units: selectedUnits });
          return;
        }

        // selectedRules 被选中的规则不为空，则复制规则
        if (keyPressForCtrl && selectedRules.length > 0) {
          this.props.dispatch({ type: 'rule/copyRules', rules: selectedRules });
        }
        break;
      case 'v': // mac V 键
        // 当被选中的节点有且仅有一个,粘贴条件
        if (keyPressForCtrl && selectedConditions.length === 1 && selectedRules.length === 1) {
          const criterion = selectedConditions[0];
          const id = criterion.id;
          const currentRule = selectedRules[0];

          // 并且是联合节点
          if (
            criterion.junctionType &&
            criterion.criterions instanceof Array &&
            storage.clipboard.type === clipboardConstant.conditions
          ) {
            let currentCriterion = {};
            // 如果是循环规则
            if (currentRule.loopRule) {
              for (let i = 0; i < currentRule.units.length; i++) {
                const item = currentRule.units[i];
                currentCriterion = this.findCurrentCriterion(item.lhs.criterion, id);
                if (currentCriterion && Object.keys(currentCriterion).length > 0) {
                  break;
                }
              }

              // 非循环规则
            } else {
              currentCriterion = this.findCurrentCriterion(currentRule.lhs.criterion, id); // 查找到被选中节点的对象引用
            }

            this.props.dispatch({ type: 'rule/pasteCondition', criterion: currentCriterion, ruleId: currentRule.id });
          }
          return;
        }
        // selectedActions 粘贴动作
        if (
          keyPressForCtrl &&
          selectedRules.length === 1 &&
          selectedActionAreas.length === 1 &&
          storage.clipboard.type === clipboardConstant.actions
        ) {
          const currentRule = selectedRules[0];
          const currentActionsArea = selectedActionAreas[0];
          this.props.dispatch({ type: 'rule/pasteActions', data: currentActionsArea, ruleId: currentRule.id });
          return;
        }

        // selectedUnits 被选中的单元有且只有一个，粘贴单元
        if (
          keyPressForCtrl &&
          selectedRules.length === 1 &&
          selectedRules[0].loopRule &&
          selectedUnits.length === 1 &&
          storage.clipboard.type === clipboardConstant.units
        ) {
          const currentRule = selectedRules[0];
          const currentUnit = selectedUnits[0];
          // 查找出index
          let index = 0;
          currentRule.units.forEach((it, i) => {
            if (it.id === currentUnit.id) {
              index = i + 1;
            }
          });
          this.props.dispatch({ type: 'rule/pasteUnits', index, currentRule });
          return;
        }

        // selectedRules 被选中的规则有且只有一个，粘贴规则
        if (keyPressForCtrl && selectedRules.length === 1 && storage.clipboard.type === clipboardConstant.rules) {
          // 查找出index
          let index = 0;
          ruleIds.forEach((it, i) => {
            if (it === selectedRules[0].id) {
              index = i + 1;
            }
          });
          this.props.dispatch({ type: 'rule/pasteRules', index });
        }
        break;
      default:
        break;
    }
  };

  // onKeyUp
  onKeyUp = e => {
    switch (e.key) {
      case 'Meta': // mac command键
      case 'Control': // mac Control键 /  windows ctrl
        ruleObj.keyPressForCtrl = false;
        PopMenu.close(); // 关闭菜单
        break;
      case 'c': // mac C 键
      case 'v': // mac V 键
        PopMenu.close(); // 关闭菜单
        break;
      default:
        break;
    }
  };

  findCurrentCriterion = (data, id) => {
    if (data.id === id) {
      return data;
    }
    if (data.criterions && data.criterions.length > 0) {
      for (let i = 0; i < data.criterions.length; i++) {
        const res = this.findCurrentCriterion(data.criterions[i], id);
        if (res) {
          return res;
        }
      }
    }
  };

  addRule = ({ index, rules, loopRule }) => {
    const { ruleIds } = this.props;
    addRule({ ruleIds, index, rules, loopRule }).then(({ ruleIds, items }) => {
      items.forEach(item => {
        this.props.dispatch({
          type: 'rule/updateRule',
          data: JSON.parse(JSON.stringify({ [item.id]: item }))
        });
      });
      this.props.dispatch({ type: 'rule/updateRuleIds', data: JSON.parse(JSON.stringify(ruleIds)) });
    });
  };

  copyAdd = ({ index, rule }) => {
    this.addRule({ index, rules: [rule] });
  };

  // pasteRule
  pasteRule = ({ index }) => {
    this.props.dispatch({ type: 'rule/pasteRules', index });
  };

  // 确认删除
  delRuleConfirm = key => {
    Modal.open({
      title: '提示',
      content: '确认要删除该规则？',
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.delRule({ key });
        Modal.close();
      }
    });
  };

  // 删除规则
  delRule = ({ key }) => {
    const { ruleIds } = this.props;
    const newRules = ruleIds.filter(id => {
      return id !== key;
    });
    this.props.dispatch({ type: 'rule/updateRuleIds', data: JSON.parse(JSON.stringify(newRules)) });
    this.props.dispatch({ type: 'rule/removeRuleById', id: key });
  };

  // 排序
  sortableChange = (order, sortable, evt) => {
    const { ruleIds } = this.props;
    const oldDraggableIndex = evt.oldDraggableIndex;
    const newDraggableIndex = evt.newDraggableIndex;
    const current = ruleIds.splice(oldDraggableIndex, 1);
    ruleIds.splice(newDraggableIndex, 0, current[0]);
    this.props.dispatch({ type: 'rule/updateRuleIds', data: JSON.parse(JSON.stringify(ruleIds)) });
  };

  // 取消掉选中的
  unSelectedConditions = () => {
    ruleObj.selectedConditions = [];
    ruleObj.selectedActions = [];
    ruleObj.selectedActionAreas = [];
    ruleObj.selectedUnits = [];

    // actions-area
    document.querySelectorAll('.actions-area').forEach(item => {
      item.style.background = '';
    });
    // action
    document.querySelectorAll('.action-item').forEach(item => {
      item.style.background = '';
    });

    // condition
    document.querySelectorAll('.condition-item').forEach(item => {
      item.style.background = '';
    });

    // unit
    document.querySelectorAll('.unit').forEach(item => {
      item.className = 'unit ';
    });
  };

  changeRemark = value => {
    this.props.dispatch({ type: 'rule/updateRemark', data: value });
  };

  setNavCurrentId = id => {
    this.setState({
      currentNavLinkId: id
    });
  };
  updateRuleIds = newRuleIds => {
    this.props.dispatch({ type: 'rule/updateRuleIds', data: JSON.parse(JSON.stringify(newRuleIds)) });
  };
  updateIsNeedSave = () => {
    this.props.dispatch({ type: 'rule/updateIsNeedSave', data: false });
  };

  // 加载组件id 为入参id的pannel组件中的内容部分
  updateRenderBody = id => {
    const current = this.rulePannels.find(item => item.props.id === id);
    if (!current.state.renderBody) {
      current.renderBody();
    }
  };
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
    this.props.dispatch({ type: 'rule/resetState' });
  }
  render() {
    const { libraries, ruleIds, remark } = this.props;
    const { currentNavLinkId, version, renderBody } = this.state;
    this.file = getCodeByKey(this.props.location.search, 'file');
    return (
      <TabWrapper file={this.file}>
        <div className="container">
          <div className="rule-container tantuer " onClick={this.unSelectedConditions}>
            <RuleSaveNotification />
            <RuleTool
              rulePannels={this.rulePannels}
              addRule={this.addRule}
              remark={remark}
              changeRemark={this.changeRemark}
              file={decodeURI(this.file)}
              queryLists={this.queryLists}
              version={version}
              getFileVersions={this.fileVersions}
              updateRuleIds={this.updateRuleIds}
              updateIsNeedSave={this.updateIsNeedSave}
              updateRenderBody={this.updateRenderBody}
            />
            <div className="rules" id="scroller">
              <RuleAddBtn addRule={this.addRule} pasteRule={this.pasteRule} index={0} />

              {ruleIds.length > 0 && (
                <Sortable
                  tag="div"
                  onChange={this.sortableChange}
                  options={{ filter: '.ant-input', preventOnFilter: false }}
                >
                  {ruleIds.map((id, index) => {
                    return (
                      <RulePanel
                        id={id}
                        key={id}
                        index={index}
                        length={ruleIds.length}
                        copyAdd={this.copyAdd}
                        delRule={this.delRuleConfirm.bind(this, id)}
                        projectName={this.projectName}
                        setChildrensToParent={this.setChildrensToParent}
                        defaultExpendRuleName={this.ruleName}
                        renderBody={renderBody}
                      />
                    );
                  })}
                </Sortable>
              )}
              {ruleIds.length > 0 && <RuleAddBtn addRule={this.addRule} pasteRule={this.pasteRule} />}
            </div>
          </div>
          <PropertyPanel>
            <PropertyPanel.Item title="属性面板" key="1">
              <RuleFile libraries={libraries} />
              <FileReference file={this.file} title="引用详情" event={window.parent.componentEvent} />
            </PropertyPanel.Item>
            <PropertyPanel.Item title="导航" key="2">
              <div style={{ height: 'calc(100% - 100px)', overflow: 'auto' }}>
                {ruleIds.map(id => (
                  <NavLink
                    key={id}
                    id={id}
                    setNavCurrentId={this.setNavCurrentId}
                    currentNavLinkId={currentNavLinkId}
                  />
                ))}
              </div>
            </PropertyPanel.Item>
          </PropertyPanel>
        </div>
      </TabWrapper>
    );
  }
}
function mapStateToProps(state) {
  return {
    ruleIds: state.rule.ruleIds,
    remark: state.rule.remark,
    libraries: state.file.libraries
  };
}
export default Rule;

/** 添加规则
 * @ruleIds Array 数组ids
 * @index Number 插入的位置
 * @rules Array 插入的规则对象数组
 * @isNotCopied Boolean  非复制的对象，用于区分是否需要重新设置id
 * @loopRule Boolean 默认undefined ，插入的规则类型，true->循环规则
 */
export function addRule({ ruleIds, index, rules, isNotCopied, loopRule }) {
  return new Promise((resolve, reject) => {
    let items = [];
    let ids = [];
    const len = ruleIds.length;
    const i = index !== undefined ? index : len;

    if (rules && rules.length > 0) {
      // 复制的，需要重新设置id
      if (!isNotCopied) {
        items = rules.map(item => {
          if (item.loopRule) {
            return rulesRandomIdForLoopRule(item);
          } else {
            return rulesRandomId(item);
          }
        });
        ids = items.map(item => item.id);
      } else {
        items = rules;
        ids = rules.map(it => it.id);
      }
    } else {
      //循环规则
      if (loopRule) {
        // 初始化rule数据
        const initId = randomKey();
        items = [
          {
            id: initId,
            name: createCopyName('rule', '-新增'),
            remark: '',
            // 属性 start
            debug: false,
            enabled: true,
            salience: 0,
            // 属性 end
            loopRule: true,
            loopStart: {
              id: randomKey(),
              actions: []
            },
            loopEnd: {
              id: randomKey(),
              actions: []
            },
            loopTarget: {
              value: {}
            },
            loopType: {
              value: {}
            },
            units: [
              {
                id: initId,
                name: 'Condition Unit',
                lhs: {
                  criterion: {
                    id: randomKey(),
                    criterions: [],
                    junctionType: 'and'
                  }
                },
                rhs: {
                  id: randomKey(),
                  actions: []
                },
                other: {
                  id: randomKey(),
                  actions: []
                }
              }
            ],
            open: true
          }
        ];
        ids = [initId];

        // 非循环规则
      } else {
        // 初始化rule数据
        const initId = randomKey();
        items = [
          {
            id: initId,
            name: createCopyName('rule', '-新增'),
            remark: '',
            // 属性 start
            debug: false,
            enabled: true,
            loop: false,
            salience: 0,
            // 属性 end
            lhs: {
              criterion: {
                id: randomKey(),
                criterions: [],
                junctionType: 'and'
              }
            },
            rhs: {
              id: randomKey(),
              actions: []
            },
            other: {
              id: randomKey(),
              actions: []
            },
            open: true
          }
        ];
        ids = [initId];
      }
    }
    ruleIds.splice(i, 0, ...ids);
    resolve({ ruleIds, items });
  });
}

// rulesRandomId
export function rulesRandomId(data) {
  const newData = deepCopy(data);
  //重置条件id
  newData.lhs.criterion = {
    ...newData.lhs.criterion,
    id: randomKey(),
    criterions: data.lhs.criterion.criterions.map(item => {
      return conditionsRandomId(item);
    })
  };
  // 重置then动作id
  newData.rhs = {
    id: randomKey(),
    actions: newData.rhs.actions.map(it => {
      return actionsRandomId(it);
    })
  };

  // 重置else动作id
  newData.other = {
    id: randomKey(),
    actions: newData.other.actions.map(it => {
      return actionsRandomId(it);
    })
  };
  return Object.assign({}, newData, { id: randomKey(), name: createCopyName(newData.name, '-副本'), open: true });
}

// rulesRandomId 循环规则
export function rulesRandomIdForLoopRule(data) {
  const newData = deepCopy(data);

  newData.loopStart = {
    id: randomKey(),
    actions: newData.loopStart.actions.map(it => {
      return actionsRandomId(it);
    })
  };
  newData.loopEnd = {
    id: randomKey(),
    actions: newData.loopEnd.actions.map(it => {
      return actionsRandomId(it);
    })
  };
  newData.units = newData.units.map(it => {
    return unitsRandomId(it, newData, true);
  });

  return Object.assign({}, newData, { id: randomKey(), name: createCopyName(newData.name, '-副本'), open: true });
}

// 粘贴生成不同规则名
function createCopyName(name, type) {
  // 如果名称相同
  if (ruleObj.rules.some(it => it.name === name)) {
    const nArr = name.split(type);
    const prevName = nArr[0];
    const nameIndex = nArr.length > 1 ? Number(nArr[1]) + 1 : 1;
    return createCopyName(`${prevName}${type}${nameIndex}`, type);
  } else {
    return name;
  }
}
