/**
 * VariableValue 变量选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import { ruleObj } from '../../../../utils/rule';

class VariableValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getMenus = () => {
    const { variables } = ruleObj.file;
    return this.getVariableMenus(variables[0] || [], false);
  };
  choose = value => {
    const { data, isValue, isAssignLeft } = this.props;
    this.setVariableValue(data, value, isValue, isAssignLeft);
  };
  renderValueText = data => {
    const { variableCategoryLabel, variableLabel } = data;
    if (!variableLabel) {
      return `${variableCategoryLabel}`;
    }
    return `${variableCategoryLabel}.${variableLabel}`;
  };
  getPopText = () => {
    const { data, isValue } = this.props;
    return (
      <span style={{ color: '#19885f', cursor: 'pointer' }}>
        {!isValue
          ? data.leftPart
            ? this.renderValueText(data.leftPart)
            : '请选择变量'
          : Object.keys(data).length !== 0
          ? this.renderValueText(data)
          : '请选择值'}
      </span>
    );
  };
}
export default VariableValue;
