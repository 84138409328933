import React, { Component } from 'react';
import Sortable from 'react-sortablejs';
import ActonItem from './ActionItem';
import Modal from '../../../components/Modal';
import { ruleObj } from '../../../utils/rule';
import { randomKey } from '../../../utils/utils';

//添加动作
export function addAction({ actions, index, actionsForAdd }) {
  return new Promise((resolve, reject) => {
    let items = [];
    const len = actions.length;
    const i = index !== undefined ? index : len;
    if (actionsForAdd && actionsForAdd.length > 0) {
      items = actionsForAdd.map(item => {
        return actionsRandomId(item);
      });
    } else {
      items = [{ id: randomKey() }];
    }
    actions.splice(i, 0, ...items);
    resolve();
  });
}

// actionsRandomId
export function actionsRandomId(data) {
  return Object.assign({}, data, { id: randomKey() });
}

class Action extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initAction = {};
  }
  componentDidMount() {
    this.props.setChildToParent(this);
  }
  updateRule = () => {
    this.props.updateRule();
  };

  //添加动作
  addAction = ({ index, actionsForAdd }) => {
    const { data } = this.props;
    addAction({ actions: data.actions, index, actionsForAdd }).then(() => {
      this.updateRule();
    });
  };

  /** 复制动作
   */
  copyActions = actions => {
    this.props.copyActions(actions);
  };

  // 粘贴动作
  pasteActions = () => {
    const { data } = this.props;
    this.props.pasteActions(data);
  };

  //删除动作
  delAction = ({ item }) => {
    const { data } = this.props;
    data.actions = data.actions.filter(it => {
      return it.id !== item.id;
    });
    this.updateRule();
  };

  // 删除所有动作
  delAllActions = () => {
    const { data } = this.props;
    data.actions = [];
    this.updateRule();
  };

  // 选择动作
  selectedThis = (item, e) => {
    if (this.props.disabled) {
      return;
    }
    const { ruleId, rule, data, unit } = this.props;
    const { selectedActions, selectedRules, selectedActionAreas, selectedUnits, keyPressForCtrl } = ruleObj;
    let newselectedActions = [];
    let newSelectedRules = [];
    let newSelectedActionAreas = [];
    let newSelectedUnits = [];
    // 如果是多选，按住ctrl/command
    if (keyPressForCtrl) {
      newselectedActions = selectedActions.some(it => it.id === item.id)
        ? selectedActions.filter(it => it.id !== item.id)
        : selectedActions.concat([item]);
      newSelectedRules = selectedRules.some(it => it.id === ruleId) ? selectedRules : selectedRules.concat(rule);
      newSelectedActionAreas = selectedActionAreas.some(it => it.id === data.id)
        ? selectedActionAreas
        : selectedActionAreas.concat(data);

      // 单选
    } else {
      newselectedActions = [item];
      newSelectedRules = [rule];
      newSelectedActionAreas = [data];
    }

    // 把选中的存储全局变量ruleObj
    ruleObj.selectedActionAreas = newSelectedActionAreas;
    ruleObj.selectedActions = newselectedActions;
    ruleObj.selectedRules = newSelectedRules;
    ruleObj.selectedConditions = [];

    // actions-area
    document.querySelectorAll('.actions-area').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedActionAreas.some(it => it.id === id)) {
        item.style.background = 'rgb(226, 241, 251)';
      } else {
        item.style.background = '';
      }
    });

    // action
    document.querySelectorAll('.action-item').forEach(item => {
      const id = item.getAttribute('id');
      if (newselectedActions.some(it => it.id === id)) {
        item.style.background = 'rgb(209, 228, 243)';
      } else {
        item.style.background = '';
      }
    });

    // pannel
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });

    // condition
    document.querySelectorAll('.condition-item').forEach(item => {
      item.style.background = '';
    });

    // 如果是条件单元
    if (unit) {
      // 如果是多选，按住ctrl/command
      if (keyPressForCtrl) {
        newSelectedUnits = selectedUnits.some(it => it.id === unit.id) ? selectedUnits : selectedUnits.concat(unit);
        //单选
      } else {
        newSelectedUnits = [unit];
      }
      ruleObj.selectedUnits = newSelectedUnits;
      document.querySelectorAll('.unit').forEach(item => {
        const id = item.getAttribute('id');
        if (newSelectedUnits.some(it => it.id === id)) {
          item.className = 'unit active';
        } else {
          item.className = 'unit ';
        }
      });
    } else {
      ruleObj.selectedUnits = [];
      document.querySelectorAll('.unit').forEach(item => {
        item.className = 'unit ';
      });
    }

    e.stopPropagation(); //阻止冒泡
  };

  // 选择动作区域
  selectedThisAction = (data, e) => {
    if (this.props.disabled) {
      return;
    }
    const { ruleId, rule, unit } = this.props;
    const { selectedActionAreas, selectedRules, selectedUnits, keyPressForCtrl } = ruleObj;
    let newSelectedActionAreas = [];
    let newSelectedRules = [];
    let newSelectedUnits = [];

    // 如果是多选，按住ctrl/command
    if (keyPressForCtrl) {
      newSelectedActionAreas = selectedActionAreas.some(it => it.id === data.id)
        ? selectedActionAreas.filter(it => it.id !== data.id)
        : selectedActionAreas.concat([data]);
      newSelectedRules = selectedRules.some(it => it.id === ruleId) ? selectedRules : selectedRules.concat(rule);

      // 单选
    } else {
      newSelectedActionAreas = [data];
      newSelectedRules = [rule];
    }
    // 把选中的存储全局变量ruleObj
    ruleObj.selectedActionAreas = newSelectedActionAreas;
    ruleObj.selectedActions = [];
    ruleObj.selectedRules = newSelectedRules;
    ruleObj.selectedConditions = [];

    // actions-area
    document.querySelectorAll('.actions-area').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedActionAreas.some(it => it.id === id)) {
        item.style.background = 'rgb(226, 241, 251)';
      } else {
        item.style.background = '';
      }
    });

    // pannel
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });

    // condition
    document.querySelectorAll('.condition-item').forEach(item => {
      item.style.background = '';
    });

    // action
    document.querySelectorAll('.action-item').forEach(item => {
      item.style.background = '';
    });

    // 如果是条件单元
    if (unit) {
      // 如果是多选，按住ctrl/command
      if (keyPressForCtrl) {
        newSelectedUnits = selectedUnits.some(it => it.id === unit.id) ? selectedUnits : selectedUnits.concat(unit);
        //单选
      } else {
        newSelectedUnits = [unit];
      }
      ruleObj.selectedUnits = newSelectedUnits;
      document.querySelectorAll('.unit').forEach(item => {
        const id = item.getAttribute('id');
        if (newSelectedUnits.some(it => it.id === id)) {
          item.className = 'unit active';
        } else {
          item.className = 'unit ';
        }
      });
    } else {
      ruleObj.selectedUnits = [];
      document.querySelectorAll('.unit').forEach(item => {
        item.className = 'unit ';
      });
    }

    e.stopPropagation(); //阻止冒泡
  };

  // 删除所有
  delAllConfirm = item => {
    Modal.open({
      title: '提示',
      content: '真的要删除当前区域所有动作？',
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.delAllActions();
        Modal.close();
      }
    });
  };
  // 排序
  sortableChange = (order, sortable, evt) => {
    const { data } = this.props;
    const oldDraggableIndex = evt.oldDraggableIndex;
    const newDraggableIndex = evt.newDraggableIndex;
    const current = data.actions.splice(oldDraggableIndex, 1);
    data.actions.splice(newDraggableIndex, 0, current[0]);
    this.updateRule();
  };

  getLine = () => {
    const { data } = this.props;
    const nums = [];
    for (let i = 0; i < data.actions.length; i++) {
      nums.push(i + 1);
    }
    return (
      <div className="line-wrap">
        {nums.map(num => {
          return (
            <div className="line-number" style={{ lineHeight: '30px' }} key={num}>
              {num}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const height = data.actions.length * 30;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      copyActions: this.copyActions,
      pasteActions: this.pasteActions,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    const actions = (
      <>
        {data.actions &&
          data.actions.length > 0 &&
          data.actions.map((item, index) => {
            const pos = {
              position: 'absolute',
              left: 0,
              top: 30 * index
            };
            return (
              <ActonItem
                item={item}
                index={index}
                pos={pos}
                key={item.id}
                del={this.delAction}
                copy={this.copyActions}
                move={this.moveAction}
                copyAdd={this.addAction}
                selectedThis={this.selectedThis}
                {...props}
              />
            );
          })}
      </>
    );
    return (
      <div style={{ position: 'relative', height: height + 'px', marginLeft: '15px' }}>
        {this.getLine()}
        {this.props.disabled ? (
          actions
        ) : (
          <Sortable tag="div" onChange={this.sortableChange} options={{ filter: '.ant-input', preventOnFilter: false }}>
            {actions}
          </Sortable>
        )}
      </div>
    );
  }
}
export default Action;
