import services from '../createServices';

export const ruleTemplate = {
  listStatus: params => services.get('/setting/template/rules/listStatus', { params }),
  queryRuleTemplate: params => services.post('/setting/template/rules/list', { params, isLoading: true }),
  create: params => services.post('/setting/template/rules/create', { params, isFormdata: true, isLoading: true }),
  existCheck: params => services.get('/setting/template/rules/isExist', { params }),
  updateStatus: params => services.post('/setting/template/rules/updateStatus', { params, isLoading: true }),
  delete: params => services.post('/setting/template/rules/delete', { params, isLoading: true }),
  save: params => services.post('/setting/template/rules/save', { params, isLoading: true }),
  copy: params => services.post('/setting/template/rules/copy', { params, isLoading: true }),
  loadDefaultAction: params => services.get('/setting/template/defaultAction/load', { params, isLoading: true }),
  getDefaultAction: params => services.get('/setting/template/defaultAction/get', { params, isLoading: true }),
  saveDefaultAction: params =>
    services.post('/setting/template/defaultAction/save', { params, isFormdata: true, isLoading: true }),
  import: params => services.upload('/setting/template/rules/online/import', { params, isLoading: true }),
  forceImport: params =>
    services.upload('/setting/template/rules/online/forceImport', { params, isFormdata: true, isLoading: true }),
  compare: params =>
    services.get('/setting/template/rules/online/listCompares', { params, isFormdata: true, isLoading: true }),
  goLine: params =>
    services.post('/setting/template/rules/online/goLine', { params, isLoading: true, errorCatch: true }),
  onlineLoadXml: params => services.post('/setting/template/rules/online/loadXml', { params, isLoading: true }),
  getLog: params => services.get('/setting/template/rules/online/goLineLogs', { params }),
  export: params => services.post('/setting/template/rules/export', { params, isLoading: true }),
  exportAll: params => services.get('/setting/template/rules/exportAll', { params, isLoading: true }),
  syncOnline: params => services.post('/setting/template/rules/syncOnline', { params, isLoading: true }),
  syncOnlineCommit: params => services.post('/setting/template/rules/syncOnline/commit', { params, isLoading: true }),
  syncOnlineLoadXml: params => services.post('/setting/template/rules/syncOnline/loadXml', { params, isLoading: true }),
  syncOnlineListCompares: params =>
    services.get('/setting/template/rules/syncOnline/listCompares', { params, isLoading: true }),
  syncOnlineLogs: params => services.get('/setting/template/rules/syncOnline/logs', { params, isLoading: true }),
  cancelGoline: params => services.post('/setting/template/rules/online/cancelGoline', { params, isLoading: true }),
  syncOnlineCancel: params => services.post('/setting/template/rules/syncOnline/cancel', { params, isLoading: true })
};
