/**
 * FunctionValue 函数选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import ConditionValue from '../ConditionValue';
import FunctionProperty from './FunctionProperty';
import { ruleObj } from '../../../../utils/rule';

class FunctionValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getMenus = () => {
    const { functions } = ruleObj.file;
    return this.getFunctionsMenus(functions);
  };
  choose = value => {
    const { data, isValue } = this.props;
    this.setFunctionValue(data, value, isValue);
  };
  renderValueText = data => {
    const { parameter } = data;
    const { id, actionType, ...functionValue } = data;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    if (Object.keys(functionValue).length === 0) {
      return null;
    }

    data.value = parameter ? data.value || parameter.objectParameter : {};

    return (
      <>
        {parameter && Object.keys(parameter).length !== 0 && (
          <span>
            <span style={{ color: '#333333' }}>{parameter.name}:</span>
            <ConditionValue item={data} {...props} />,
            {parameter.propertyLabel !== undefined && (
              <>
                <span style={{ color: '#333333' }}>属性:</span>
                <FunctionProperty data={parameter} {...props} />
              </>
            )}
          </span>
        )}
        <span style={{ color: '#70196b' }}>)</span>
      </>
    );
  };
  getPopText = () => {
    const { data, isValue } = this.props;
    const { id, actionType, ...functionValue } = data;
    return (
      <span style={{ color: 'darkcyan', cursor: 'pointer' }}>
        {!isValue ? (
          data.leftPart ? (
            <span className="selector-text">
              <span style={{ color: '#70196b' }}>{data.leftPart.label}(</span>
            </span>
          ) : (
            '请选择函数'
          )
        ) : Object.keys(functionValue).length !== 0 ? (
          <span className="selector-text">
            <span style={{ color: '#70196b' }}>{data.label}(</span>
          </span>
        ) : (
          '请选择函数'
        )}
      </span>
    );
  };
  getValue = () => {
    const { data, isValue } = this.props;
    return <>{!isValue ? this.renderValueText(data.leftPart) : this.renderValueText(data)}</>;
  };
}
export default FunctionValue;
