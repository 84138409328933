import React, { Component } from 'react';
import { Button } from 'antd';
import Unit from './Unit';
import { randomKey } from '../../../utils/utils';
import { deepCopy } from '../../../utils/utils';
import { conditionsRandomId } from '../Components/Condition';
import Modal from '../../../components/Modal';
import { actionsRandomId } from '../Components/Action';
import Sortable from 'react-sortablejs';

export const addUnit = ({ units, index, unitsForAdd, currentRule }) => {
  return new Promise((resolve, reject) => {
    let items = [];
    const len = units.length;
    const i = index !== undefined ? index : len;

    if (unitsForAdd && unitsForAdd.length > 0) {
      items = unitsForAdd.map(item => {
        return unitsRandomId(item, currentRule);
      });
    } else {
      // 初始化rule数据
      const initId = randomKey();
      items = [
        {
          id: initId,
          name: createCopyName('Condition Unit', '-新增', currentRule),
          lhs: {
            criterion: {
              id: randomKey(),
              criterions: [],
              junctionType: 'and'
            }
          },
          rhs: {
            id: randomKey(),
            actions: []
          },
          other: {
            id: randomKey(),
            actions: []
          }
        }
      ];
    }
    units.splice(i, 0, ...items);
    resolve(items);
  });
};

// unitsRandomId
export function unitsRandomId(data, currentRule, isNotCopyName) {
  const newData = deepCopy(data);
  //重置条件id
  newData.lhs.criterion = {
    ...newData.lhs.criterion,
    id: randomKey(),
    criterions: data.lhs.criterion.criterions.map(item => {
      return conditionsRandomId(item);
    })
  };
  // 重置then动作id
  newData.rhs = {
    id: randomKey(),
    actions: newData.rhs.actions.map(it => {
      return actionsRandomId(it);
    })
  };

  // 重置else动作id
  newData.other = {
    id: randomKey(),
    actions: newData.other.actions.map(it => {
      return actionsRandomId(it);
    })
  };
  return Object.assign({}, newData, {
    id: randomKey(),
    name: isNotCopyName ? newData.name : createCopyName(newData.name, '-副本', currentRule)
  });
}

// 粘贴生成不同规则名
const createCopyName = (name, type, currentRule) => {
  const units = currentRule.units;
  // 如果名称相同
  if (units.some(it => it.name === name)) {
    const nArr = name.split(type);
    const prevName = nArr[0];
    const nameIndex = nArr.length > 1 ? Number(nArr[1]) + 1 : 1;
    return createCopyName(`${prevName}${type}${nameIndex}`, type, currentRule);
  } else {
    return name;
  }
};

class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  addUnit = ({ index, unitsForAdd }) => {
    const { data, rule } = this.props;
    addUnit({ units: data, index, unitsForAdd, currentRule: rule }).then(() => {
      this.updateRule();
    });
  };

  // 复制
  copyUnits = data => {
    this.props.copyUnits(data);
  };

  copyAdd = ({ index, unit }) => {
    this.addUnit({ index, unitsForAdd: [unit] });
  };

  // 确认删除
  delUnitConfirm = ({ id }) => {
    Modal.open({
      title: '提示',
      content: '确认要删除该判断单元？',
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.delUnit({ id });
        Modal.close();
      }
    });
  };

  // 删除单元
  delUnit = ({ id }) => {
    const { data, rule } = this.props;
    const newData = data.filter(item => item.id !== id);
    rule.units = newData;
    this.updateRule();
  };

  updateRule = () => {
    this.props.updateRule();
  };

  // 排序
  sortableChange = (order, sortable, evt) => {
    const { data } = this.props;
    const oldDraggableIndex = evt.oldDraggableIndex;
    const newDraggableIndex = evt.newDraggableIndex;
    const current = data.splice(oldDraggableIndex, 1);
    data.splice(newDraggableIndex, 0, current[0]);
    this.updateRule();
  };
  render() {
    const { data } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <div className="con-container">
        <div className="con-text">
          <strong>条件判断单元</strong>
          <Button className="btn-small lightblue" type="primary" onClick={this.addUnit.bind({ index: 0 })}>
            添加
          </Button>
        </div>

        <div className="unit-areas">
          <Sortable tag="div" onChange={this.sortableChange} options={{ filter: '.ant-input', preventOnFilter: false }}>
            {data.map((item, index) => {
              return (
                <Unit
                  data={item}
                  index={index}
                  units={data}
                  projectName={this.props.projectName}
                  {...props}
                  key={item.id}
                  id={item.id}
                  copyAdd={this.copyAdd}
                  copy={this.copyUnits}
                  delUnitConfirm={this.delUnitConfirm}
                />
              );
            })}
          </Sortable>
        </div>
      </div>
    );
  }
}
export default Units;
