import services from '../createServices';

// project
export const project = {
  lists: params => services.post('/dmpFrame/loadProjects', { params }),
  listsAll: params => services.get('/dmpFrame/loadProjectNames', { params }),
  existCheck: params => services.post('/dmpFrame/projectExistCheck', { params }),
  create: params => services.post('/dmpFrame/createProject', { params, isLoading: true }), // 创建项目
  filePaste: params => services.post('/dmpFrame/filePaste', { params, isLoading: true }),
  importProject: params => services.upload('/dmpFrame/importProject', { params, isLoading: true }), // 上传，导入项目
  pullProjects: params => services.get('/pull/remote/projects', { params, isLoading: true }), // 拉取远程项目
  importRemoteProjects: params => services.post('/pull/remote/download', { params, isLoading: true }), // 导入远程项目
  onlineImportProjects: params => services.upload('/online/import/project', { params, isLoading: true }), //上传，上线项目
  loadProjectTemplates: params =>
    services.post('/online/load/project/files/templates', { params, isFormdata: true, isLoading: true }), //获取导入的项目文件
  importProjectTemplates: params =>
    services.post('/online/import/project/files/templates', { params, isFormdata: false, isLoading: true }), //导入选择的项目文件
  rename: params => services.post('/dmpFrame/fileRename', { params, isLoading: true }), //重命名
  deleteFile: params => services.post('/dmpFrame/deleteFile', { params, isLoading: true }), //删除文件
  exportProject: params => services.post('/dmpFrame/exportProject', { params, isLoading: true }) //导出项目
};

export const file = {
  fileSource: params => services.post('/dmpFrame/fileSource', { params, isLoading: true }), //查看源码
  saveFile: params => services.post('/common/saveFile', { params, isLoading: true }), //保存文件
  currentVersion: params => services.post('/common/currentVersion', { params }), //查看当前版本
  fileVersions: params => services.post('/dmpFrame/fileVersions', { params, isLoading: true }), //文件版本列表
  downloadLibTemplate: params => services.post('/setting/libs/downloadLibTemplate', { params, isLoading: true }), //文件版本列表
  existCheck: params => services.post('/dmpFrame/fileExistCheck', { params }), // 检查文件是否存在
  createFile: params => services.post('/dmpFrame/createFile', { params, isLoading: true }), // 创建文件
  createFolder: params => services.post('/dmpFrame/createFolder', { params, isLoading: true }), // 创建文件夹
  rename: params => services.post('/dmpFrame/fileRename', { params, isLoading: true }), //重命名
  lock: params => services.post('/dmpFrame/lockFile', { params, isLoading: true }), //锁定
  unlock: params => services.post('/dmpFrame/unlockFile', { params, isLoading: true }) //解锁
};
