import services from '../createServices';

// common
export const common = {
  loadXml: (params, isLoading) => services.post('/common/loadXml', { params, isLoading: isLoading }),
  loadFlowDefinition: params => services.get('/ruleflowdesigner/loadFlowDefinition', { params, isFormdata: true }),
  loadFunctions: params => services.get('/common/loadFunctions', { params }),
  search: params => services.post('/feature/search', { params }),
  getDefaultActions: params => services.get('/default/get', { params, isFormdata: true, isLoading: true }),
  saveTemplate: params => services.post('/template/saveTemplate', { params, isFormdata: true, isLoading: true }),
  loadTemplates: params => services.post('/template/loadTemplates', { params, isFormdata: true, isLoading: true }),
  loadRuleTemplate: params => services.post('/common/loadRuleTemplate', { params, isLoading: true }),
  saveFile: params => services.post('/common/saveFile', { params, isLoading: true }),
  loadDefaultRule: params => services.post('/common/loadDefaultRule', { params, isLoading: true }),
  saveDefaultActions: params => services.post('/default/save', { params, isLoading: true }),
  reference: (params, isLoading) => services.post('/common/file/reference', { params, isLoading })
};
