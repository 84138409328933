import CommonToXml from './CommonToXml';
/**  ActionsToXml
 *   动作数组转xml
 *   @data：动作构成的数组
 */
export default class ActionsToXml extends CommonToXml {
  constructor(data) {
    super();
    this.data = data;
  }

  toXml() {
    let actionsXml = '';
    this.data.forEach(item => {
      // 如果是单纯的条件
      actionsXml += `${this.actionToXml(item)}`;
    });
    return actionsXml;
  }

  actionToXml(item) {
    if (!item.actionType) {
      throw '请选择动作';
    }
    switch (item.actionType) {
      case 'ExecuteMethod':
        return this.executeMethodToXml(item, item.actionType);
      case 'ConsolePrint':
        return this.consolePrintToXml(item, item.actionType);
      case 'ExecuteCommonFunction':
        return this.executeCommonFunctionToXml(item, item.actionType);
      case 'VariableAssign':
        return this.variableAssignToXml(item, item.type);
      default:
        return '>';
    }
  }

  // 输出到控制台
  consolePrintToXml(data, type) {
    if (!data.value) {
      throw '输出值不能为空';
    }
    return `<console-print>
             ${this.valueToXml(data.value, type)}
            </console-print>`;
  }

  // 变量赋值
  variableAssignToXml(data, type) {
    if (!type) {
      throw '变量不能为空！';
    }
    let propertyXml = '>';
    if (type === 'variable' || type === 'Variable' || type === 'variableCategory') {
      propertyXml = this.variableValueToXml(data, type);
    }
    if (type === 'parameter' || type === 'Parameter') {
      propertyXml = this.parameterValueToXml(data, type);
    }
    return `<var-assign ${propertyXml}
    ${this.valueToXml(data.value, type)}
      </var-assign>`;
  }

  // 方法
  executeMethodToXml(data, type) {
    if (!type) {
      throw '执行方法不能为空！';
    }
    let parameterXml = '';
    if (data.parameters && data.parameters.length > 0) {
      data.parameters.forEach(item => {
        parameterXml += `<parameter name="${item.name}" type="${item.type}">${
          item.value ? this.valueToXml(item.value) : ''
        }</parameter>`;
      });
    }
    return `<execute-method bean="${data.beanId}" bean-label="${data.beanLabel}" method-label="${
      data.methodLabel
    }" method-name="${data.methodName}">${parameterXml}</execute-method>`;
  }

  // 函数
  executeCommonFunctionToXml(data, type) {
    if (!data.label && !data.label) {
      throw '执行函数不能为空！';
    }
    let parameterXml = '';
    if (data.value && Object.keys(data.value).length > 0) {
      parameterXml += `<function-parameter name="${data.parameter.name}">${this.valueToXml(
        data.value
      )}</function-parameter>`;
    }
    return `<execute-function function-label="${data.label}" function-name="${
      data.name
    }">${parameterXml}</execute-function>`;
  }
}
