/**
 * ConditionSelector 条件选择组件
 * 包含条件选择器中所有的选择方法
 *   */
import React from 'react';
import Selector from './index';
import { Descriptions } from 'antd';
class ConditionSelector extends Selector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  /** 选择变量菜单
   *  */
  getVariableMenus = (data, isValue, categoryName, categoryLabel) => {
    const menus = [];
    data.forEach(item => {
      const name = item.label;
      const menu = { name, action: this.choose.bind(this, item, 'variable') };
      if (!categoryName) {
        if (item.type === 'Custom') {
          item.categoryName = item.name;
          item.categoryLabel = item.label;
          menu.action = isValue ? this.choose.bind(this, item, 'variable') : null;
        }
      } else {
        item.categoryName = categoryName;
        item.categoryLabel = categoryLabel;
      }
      if (item.variables && item.variables.length > 0) {
        const children = this.getVariableMenus(item.variables, isValue, item.categoryName, item.categoryLabel);
        menu.children = children;
        menu.filter = true;
      }
      menus.push(menu);
    });
    return menus;
  };
  /**
   * 设置选择变量值类型
   * @item: 当前被操作的对象
   * @data： 被选中的变量对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   * @isAssignLeft
   *  */
  setVariableValue = (item, data, isValue, isAssignLeft) => {
    // 左条件
    if (!isValue) {
      const value = {
        variableCategory: data.categoryName,
        variableCategoryLabel: data.categoryLabel,
        datatype: data.type
      };
      if (data.type === 'Custom') {
        item.type = 'variableCategory';
      } else {
        item.type = 'variable';
        value.variableLabel = data.label;
        value.variableName = data.name;
      }
      item.leftPart = value;
      // 如果是值
    } else {
      delete item.variableLabel;
      delete item.variableName;
      item.variableCategory = data.categoryName;
      item.variableCategoryLabel = data.categoryLabel;
      item.datatype = data.type;
      if (data.type === 'Custom') {
        if (isAssignLeft) {
          item.type = 'variableCategory';
        } else {
          item.valueType = 'VariableCategory';
        }
      } else {
        item.variableLabel = data.label;
        item.variableName = data.name;
        if (isAssignLeft) {
          item.type = 'variable';
        } else {
          item.valueType = 'Variable';
        }
      }
    }
    this.updateRule();
  };

  /**
   * 设置搜索变量类型
   * @item: 当前被操作的对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   *  */
  setDynamicVariableValue = (item, isValue) => {
    // 左条件
    if (!isValue) {
      const value = {
        methodName: 'getRemoteDataV2' // 默认getRemoteDataV2
      };
      item.leftPart = value;
      item.type = 'method';
    } else {
      item.methodName = 'getRemoteDataV2'; // 默认getRemoteDataV2
      item.valueType = 'Method';
    }

    this.updateRule();
  };

  /**
   * 设置输入值类型
   * @item: 当前被操作的对象
   * @data： 被选中的变量对象
   *  */
  setInputValue = item => {
    item.content = '';
    item.valueType = 'Input';
    this.updateRule();
  };

  /** 选择参数菜单
   *  */
  getParameterMenus = data => {
    const menus = [];
    data.forEach(item => {
      const name = item.label;
      const menu = { name, action: this.choose.bind(this, item, 'parameter') };
      menus.push(menu);
    });
    return menus;
  };

  /**
   * 设置选择参数值类型
   * @item: 当前被操作的对象
   * @data： 被选中的变量对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   * @isAssignLeft
   *  */
  setParameterValue = (item, data, isValue, isAssignLeft) => {
    // 左条件
    if (!isValue) {
      const value = {
        variableCategory: '参数',
        variableLabel: data.label,
        variableName: data.name,
        datatype: data.type
      };
      item.leftPart = value;
      item.type = 'parameter';
      // 如果是值
    } else {
      item.variableLabel = data.label;
      item.variableName = data.name;
      item.datatype = data.type;
      if (isAssignLeft) {
        item.type = 'parameter';
      } else {
        item.valueType = 'Parameter';
      }
    }
    this.updateRule();
  };

  // 描述信息
  getMeta = data => {
    return (
      <div className='tantuer'>
        <Descriptions bordered column={1}>
          {data.map(item => {
            return (
              <Descriptions.Item label={item.label} key={item.value}>
                {item.value}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </div>
    );
  };

  /** 选择常量菜单
   *  */
  getConstantsMenus = (data, parentObj) => {
    const menus = [];
    data.forEach(item => {
      let menu = {};
      const contants = item.apiEnabled ? item.apiConstants : item.constants;

      if (contants && contants.length > 0) {
        menu = { name: item.label, key: item.name + item.label, action: null };
        if (item.name === 'CL_MODEL_PRE_VALUE' || item.name === 'CL_MODEL_CODE' || item.name === 'CL_PRODUCT') {
          menu.filter = true;
        }
        const children = this.getConstantsMenus(contants, item);
        menu.children = children;
      } else {
        if (parentObj) {
          item.constantCategoryLabel = parentObj.label;
        }
        menu = {
          name: item.label,
          key: item.name + item.label,
          action: this.choose.bind(this, item, 'constant')
        };
        if (item.metaInfo) {
          const data = JSON.parse(item.metaInfo) || {};
          menu.popover = this.getMeta(data.details);
        }
      }
      menus.push(menu);
    });
    return menus;
  };

  /**
   * 设置选择常量值类型
   * @item: 当前被操作的对象
   * @data： 被选中的变量对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   *  */
  setConstantsValue = (item, data, isValue) => {
    // 左条件
    if (!isValue) {
      const value = {
        constantCategoryLabel: data.constantCategoryLabel,
        constantLabel: data.label,
        constantName: data.name
      };
      item.leftPart = value;
      item.type = 'constant';
      // 如果是值
    } else {
      item.constantCategoryLabel = data.constantCategoryLabel;
      item.constantLabel = data.label;
      item.constantName = data.name;
      item.valueType = 'Constant';
    }
    this.updateRule();
  };

  /** 选择方法菜单
   *  */
  getMethodsMenus = (data, parentObj) => {
    const menus = [];
    data.forEach(item => {
      let menu = {};
      if (item.methods && item.methods.length > 0) {
        menu = { name: item.name, action: null };
        const children = this.getMethodsMenus(item.methods, item);
        menu.children = children;
      } else {
        if (parentObj) {
          item.beanId = parentObj.id;
          item.beanLabel = parentObj.name;
        }
        menu = { name: item.name, key: item.name + item.methodName, action: this.choose.bind(this, item, 'method') };
      }
      menus.push(menu);
    });
    return menus;
  };

  /**
   * 设置方法
   * @item: 当前被操作的对象
   * @data： 被选中的methods对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   *  */
  setMethodsValue = (item, data, isValue) => {
    // 左条件
    if (!isValue) {
      const value = {
        beanId: data.beanId,
        beanLabel: data.beanLabel,
        methodName: data.methodName,
        methodLabel: data.name,
        parameters: data.parameters.map(item => {
          item.value = {};
          return item;
        })
      };
      item.leftPart = value;
      item.type = 'method';
      // 如果是值
    } else {
      item.beanId = data.beanId;
      item.beanLabel = data.beanLabel;
      item.methodName = data.methodName;
      item.methodLabel = data.name;
      item.parameters = data.parameters.map(item => {
        item.value = {};
        return item;
      });
      item.valueType = 'Method';
    }
    this.updateRule();
  };

  /** 选择函数菜单
   *  */
  getFunctionsMenus = data => {
    const menus = [];
    data.forEach(item => {
      const menu = {
        name: item.label,
        key: item.name + item.label,
        action: this.choose.bind(this, item, 'commonfunction')
      };
      menus.push(menu);
    });
    return menus;
  };
  /**
   * 设置函数
   * @item: 当前被操作的对象
   * @data： 被选中的commonfunction对象
   * @isValue： 默认undefined/false: 左条件， true: 右值
   *  */
  setFunctionValue = (item, data, isValue) => {
    // 左条件
    if (!isValue) {
      const value = {
        label: data.label,
        name: data.name
      };
      if (data.argument) {
        value.parameter = {
          name: data.argument.name,
          objectParameter: {}
        };
        if (data.argument.needProperty) {
          value.parameter.property = '';
          value.parameter.propertyLabel = '';
        }
      }

      item.leftPart = value;
      item.type = 'commonfunction';
      // 如果是值
    } else {
      item.label = data.label;
      item.name = data.name;
      if (data.argument) {
        item.parameter = {
          name: data.argument.name,
          objectParameter: {}
        };
        if (data.argument.needProperty) {
          item.parameter.property = '';
          item.parameter.propertyLabel = '';
        }
      }

      item.valueType = 'CommonFunction';
    }
    this.updateRule();
  };

  /**
   * 设置属性值类型
   * @item: 当前被操作的对象
   * @data： 被选中的变量对象
   *  */
  setPropertyValue = (item, data) => {
    item.property = data.name;
    item.propertyLabel = data.label;
    this.updateRule();
  };

  /** 选择动作菜单
   *  */
  getActionsMenus = (data, parentObj) => {
    const menus = [];
    data.forEach(item => {
      let menu = {};
      if (item.type) {
        menu = { name: item.name, key: item.type, action: this.choose.bind(this, item) };
      } else {
        if (item.methods && item.methods.length > 0) {
          menu = { name: item.name, action: null };
          const children = this.getMethodsMenus(item.methods, item);
          menu.children = children;
        } else {
          if (parentObj) {
            item.beanId = parentObj.id;
            item.beanLabel = parentObj.name;
          }
          menu = { name: item.name, key: item.name + item.methodName, action: this.choose.bind(this, item, 'method') };
        }
      }

      menus.push(menu);
    });
    return menus;
  };

  /**
   * 设置动作
   * @item: 当前被操作的对象
   * @data： 被选中的actions对象
   *  */
  setActionsValue = (item, data) => {
    for (let i in item) {
      if (i !== 'id') {
        delete item[i];
      }
    }
    switch (data.type) {
      case 'ConsolePrint':
        item.actionType = data.type;
        item.value = {};
        break;
      case 'VariableAssign':
        item.actionType = data.type;
        item.value = {};
        break;
      case 'ExecuteCommonFunction':
        item.actionType = data.type;
        break;
      default:
        item.beanId = data.beanId;
        item.beanLabel = data.beanLabel;
        item.methodName = data.methodName;
        item.methodLabel = data.name;
        item.parameters = data.parameters.map(item => {
          item.value = {};
          return item;
        });
        item.actionType = 'ExecuteMethod';
        break;
    }
    this.updateRule();
  };

  updateRule = () => {
    this.props.updateRule();
  };
}

export default ConditionSelector;
