import * as services from '../../services/api/index';
import { resetRuleData } from '../../utils/rule';
import { ruleObj, updateRules } from '../../utils/rule';
import copy from 'copy-to-clipboard';
import storage from '../../utils/storage';
import { clipboardConstant } from '../../utils/constant';
import { randomKey } from '../../utils/utils';
import RulesToXml from '../../utils/ToXml/Rules/RulesToXml';
import UnitsToXml from '../../utils/ToXml/Rules/UnitsToXml';
import ConditionsToXml from '../../utils/ToXml/Rules/ConditionsToXml';
import ActionsToXml from '../../utils/ToXml/Rules/ActionsToXml';
import { addRule } from '../../routers/Editor/Rule/index';
import { addCondition } from '../../routers/Editor/Components/Condition';
import { addAction } from '../../routers/Editor/Components/Action';
import { addUnit } from '../../routers/Editor/Components/Units';
import { normalize, schema } from 'normalizr';
import * as event from '../../utils/event/componentEvent';
const initialState = {
  copiedOrPasted: false, // 已复制或者已粘贴
  remark: '',
  isNeedSave: false,
  ruleById: {}, // rule对象
  ruleIds: [] // 存储rule id的数组
};
export default {
  name: 'rule',
  state: initialState,
  reducers: {
    updateRule: (state, { data, isFirst }) => {
      const newState = {
        ...state,
        ...data
      };
      if (!isFirst) {
        newState.isNeedSave = true;
      }
      ruleObj.rules = updateRules(state.ruleIds, newState); //更新全局变量rules
      return newState;
    },
    updateRemark: (state, { data, isFirst }) => {
      const newState = {
        ...state,
        remark: data
      };
      if (!isFirst) {
        newState.isNeedSave = true;
      }
      return newState;
    },
    removeRuleById: (state, { id }) => {
      const newState = {
        ...state,
        ruleById: {
          ...state.ruleById,
          ...{ [id]: undefined },
          isNeedSave: true
        }
      };
      ruleObj.rules = updateRules(state.ruleIds, newState); //更新全局变量rules
      return newState;
    },
    updateRuleIds: (state, { data, isFirst }) => {
      const newState = {
        ...state,
        ruleIds: data
      };
      if (!isFirst) {
        newState.isNeedSave = true;
      }
      return newState;
    },
    updateCopiedOrPasted: (state, { data }) => {
      return {
        ...state,
        copiedOrPasted: data
      };
    },
    updateIsNeedSave: (state, { data }) => {
      return {
        ...state,
        isNeedSave: data
      };
    },
    resetState: state => {
      return {
        copiedOrPasted: false, // 已复制或者已粘贴
        remark: '',
        isNeedSave: false,
        ruleById: {}, // rule对象
        ruleIds: [] // 存储rule id的数组
      };
    }
  },
  effects: {
    queryLists: async ({ params, isLoading }, { dispatch }, { state }) => {
      const res = await services.common.loadXml(params, isLoading);

      if (res.code !== 0) {
        return;
      }

      // 由于老数据问题，默认倒序
      const rules = [];
      const data = res.data;
      data &&
        data.length > 0 &&
        data[0].rules &&
        data[0].rules.forEach((item, index) => {
          const it = resetRuleData(item);
          rules.unshift(it);
        });
      dispatch({ type: 'file/updateLibraries', data: data[0].libraries });
      const p = [];
      data[0].libraries &&
        data[0].libraries.forEach(async item => {
          p.push(dispatch({ type: 'file/loadXml', params: { filePath: item.path }, loadTtype: item.type }));
        });
      Promise.all(p)
        .then(values => {
          event && event.eventEmitter.emit(event.HIDE_LOADING);
          window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
        })
        .catch(errs => {
          console.log('错误', errs);
          event && event.eventEmitter.emit(event.HIDE_LOADING);
          window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
        });
      const rule = new schema.Entity('rule');
      const ruleById = new schema.Entity('ruleById', {
        rule: [rule]
      });
      const normalizedData = normalize(rules, [ruleById]);
      dispatch({ type: 'rule/updateRule', data: normalizedData.entities.ruleById, isFirst: true });
      dispatch({ type: 'rule/updateRuleIds', data: normalizedData.result, isFirst: true });
      dispatch({ type: 'rule/updateRemark', data: data[0].remark, isFirst: true });
      ruleObj.rules = updateRules(normalizedData.result, normalizedData.entities.ruleById); //更新全局变量rules
      return res.data;
    },

    queryRuleTemplate: async ({ params, isLoading }, { dispatch }, { state }) => {
      const res = await services.common.loadXml(params, isLoading);
      if (res.code !== 0) {
        return;
      }
      // 由于老数据问题，默认倒序
      const rules = [];
      const data = res.data;
      if (data && data.length > 0 && data[0].rules && data[0].rules.length > 0) {
        data[0].rules.forEach((item, index) => {
          const it = resetRuleData(item);
          rules.unshift(it);
        });
      } else {
        rules.push({
          id: randomKey(),
          name: 'rule',
          remark: '',
          // 属性 start
          debug: false,
          enabled: true,
          loop: false,
          salience: 0,
          // 属性 end
          lhs: {
            criterion: {
              id: randomKey(),
              criterions: [],
              junctionType: 'and'
            }
          },
          rhs: {
            id: randomKey(),
            actions: []
          },
          other: {
            id: randomKey(),
            actions: []
          },
          open: true
        });
      }
      const p = [];
      data[0].libraries &&
        data[0].libraries.forEach(async item => {
          p.push(dispatch({ type: 'file/loadXml', params: { filePath: item.path }, loadTtype: item.type }));
        });
      Promise.all(p)
        .then(values => {
          event && event.eventEmitter.emit(event.HIDE_LOADING);
          window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
        })
        .catch(errs => {
          console.log('错误', errs);
          event && event.eventEmitter.emit(event.HIDE_LOADING);
          window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.HIDE_LOADING);
        });

      const rule = new schema.Entity('rule');
      const ruleById = new schema.Entity('ruleById', {
        rule: [rule]
      });
      const normalizedData = normalize(rules, [ruleById]);
      dispatch({ type: 'rule/updateRule', data: normalizedData.entities.ruleById, isFirst: true });
      dispatch({ type: 'rule/updateRuleIds', data: normalizedData.result, isFirst: true });
      ruleObj.rules = updateRules(normalizedData.result, normalizedData.entities.ruleById); //更新全局变量rules
      return res.data;
    },

    loadTemplateDefaultAction: async ({ params, isLoading }, { dispatch }, { state }) => {
      const res = await services.ruleTemplate.loadDefaultAction(params, isLoading);
      if (res.code !== 0) {
        return;
      }
      // 由于老数据问题，默认倒序
      const rules = [];
      if (res.data.rules && res.data.rules.length > 0) {
        res.data.rules.forEach((item, index) => {
          const it = resetRuleData(item);
          rules.unshift(it);
        });
      } else {
        rules.push({
          id: randomKey(),
          lsh: {},
          rhs: {
            id: randomKey(),
            actions: []
          },
          other: {
            id: randomKey(),
            actions: []
          },
          open: true
        });
      }

      dispatch({ type: 'file/updateLibraries', data: res.data.libraries });
      res.data.libraries &&
        res.data.libraries.forEach(item => {
          dispatch({ type: 'file/loadXml', params: { filePath: item.path }, loadTtype: item.type });
        });

      const rule = new schema.Entity('rule');
      const ruleById = new schema.Entity('ruleById', {
        rule: [rule]
      });
      const normalizedData = normalize(rules, [ruleById]);
      dispatch({ type: 'rule/updateRule', data: normalizedData.entities.ruleById, isFirst: true });
      dispatch({ type: 'rule/updateRuleIds', data: normalizedData.result, isFirst: true });
      ruleObj.rules = updateRules(normalizedData.result, normalizedData.entities.ruleById); //更新全局变量rules
      return res.data;
    },

    //项目默认动作
    loadDefaultAction: async ({ params, isLoading }, { dispatch }, { state }) => {
      const res = await services.common.loadDefaultRule(params, isLoading);
      if (res.code !== 0) {
        return;
      }
      // 由于老数据问题，默认倒序
      const rules = [];
      if (res.data[0].rules && res.data[0].rules.length > 0) {
        res.data[0].rules.forEach((item, index) => {
          const it = resetRuleData(item);
          rules.unshift(it);
        });
      } else {
        rules.push({
          id: randomKey(),
          lsh: {},
          rhs: {
            id: randomKey(),
            actions: []
          },
          other: {
            id: randomKey(),
            actions: []
          },
          open: true
        });
      }

      dispatch({ type: 'file/updateLibraries', data: res.data[0].libraries });
      res.data[0].libraries &&
        res.data[0].libraries.forEach(item => {
          dispatch({ type: 'file/loadXml', params: { filePath: item.path }, loadTtype: item.type });
        });

      const rule = new schema.Entity('rule');
      const ruleById = new schema.Entity('ruleById', {
        rule: [rule]
      });
      const normalizedData = normalize(rules, [ruleById]);
      dispatch({ type: 'rule/updateRule', data: normalizedData.entities.ruleById, isFirst: true });
      dispatch({ type: 'rule/updateRuleIds', data: normalizedData.result, isFirst: true });
      ruleObj.rules = updateRules(normalizedData.result, normalizedData.entities.ruleById); //更新全局变量rules
      return res.data;
    },

    //复制规则
    copyRules: async ({ rules }, { dispatch }, { state }) => {
      if (rules.length === 0) {
        return;
      }
      return new Promise((resolve, reject) => {
        try {
          console.log(rules);
          const rulesToXml = new RulesToXml(rules);
          const xml = rulesToXml.toXml();
          // xml存到系统剪切板
          copy(xml, {
            debug: true,
            message: 'Press #{key} to copy'
          });
          // json对象存储到localstorage
          storage.clipboard = {
            content: rules,
            type: clipboardConstant.rules
          };
          resolve(xml);
        } catch (err) {
          reject(err);
        }
      });
    },

    /** 粘贴规则
     *  */
    pasteRules: async ({ index }, { dispatch }, { state }) => {
      const clipboard = storage.clipboard;
      // 如果剪切板，localstorage中存储的不是规则
      if (clipboard.type !== clipboardConstant.rules) {
        return;
      }
      addRule({ ruleIds: state.ruleIds, index: index, rules: clipboard.content }).then(({ ruleIds, items }) => {
        items.forEach(item => {
          dispatch({
            type: 'rule/updateRule',
            data: JSON.parse(JSON.stringify({ [item.id]: item }))
          });
        });
        dispatch({ type: 'rule/updateRuleIds', data: JSON.parse(JSON.stringify(ruleIds)) });
        dispatch({ type: 'rule/updateCopiedOrPasted', data: true });
      });
    },

    //复制条件
    copyConditions: async ({ conditions }, { dispatch }, { state }) => {
      if (conditions.length === 0) {
        return;
      }
      return new Promise((resolve, reject) => {
        try {
          const conditionsToXml = new ConditionsToXml(conditions);
          const xml = conditionsToXml.toXml();
          // xml存到系统剪切板
          copy(xml, {
            debug: true,
            message: 'Press #{key} to copy'
          });
          // json对象存储到localstorage
          storage.clipboard = {
            content: conditions,
            type: clipboardConstant.conditions
          };
          resolve(xml);
        } catch (err) {
          reject(err);
        }
      });
    },

    //复制动作
    copyActions: async ({ actions }, { dispatch }, { state }) => {
      if (actions.length === 0) {
        return;
      }
      return new Promise((resolve, reject) => {
        try {
          const actionsToXml = new ActionsToXml(actions);
          const xml = actionsToXml.toXml();

          // xml存到系统剪切板
          copy(xml, {
            debug: true,
            message: 'Press #{key} to copy'
          });
          // json对象存储到localstorage
          storage.clipboard = {
            content: actions,
            type: clipboardConstant.actions
          };
          resolve(xml);
        } catch (err) {
          reject(err);
        }
      });
    },

    /** 粘贴到联合条件
     *  */
    pasteCondition: async ({ criterion, ruleId }, { dispatch }, { state }) => {
      const clipboard = storage.clipboard;
      // 如果剪切板，localstorage中存储的不是条件
      if (clipboard.type !== clipboardConstant.conditions) {
        return;
      }
      addCondition({ criterion, conditions: clipboard.content }).then(() => {
        dispatch({ type: 'rule/updateRule', data: JSON.parse(JSON.stringify({ [ruleId]: state[ruleId] })) });
        dispatch({ type: 'rule/updateCopiedOrPasted', data: true });
      });
    },

    /** 粘贴到动作区域
     *  */
    pasteActions: async ({ data, ruleId }, { dispatch }, { state }) => {
      const clipboard = storage.clipboard;
      // 如果剪切板，localstorage中存储的不是动作
      if (clipboard.type !== clipboardConstant.actions) {
        return;
      }
      addAction({ actions: data.actions, actionsForAdd: clipboard.content }).then(() => {
        dispatch({ type: 'rule/updateRule', data: JSON.parse(JSON.stringify({ [ruleId]: state[ruleId] })) });
      });
    },

    //复制循环规则判断单元
    copyUnits: async ({ units }, { dispatch }, { state }) => {
      if (units.length === 0) {
        return;
      }
      return new Promise((resolve, reject) => {
        try {
          const unitsToXml = new UnitsToXml(units);
          const xml = unitsToXml.toXml();
          // xml存到系统剪切板
          copy(xml, {
            debug: true,
            message: 'Press #{key} to copy'
          });
          // json对象存储到localstorage
          storage.clipboard = {
            content: units,
            type: clipboardConstant.units
          };
          resolve(xml);
        } catch (err) {
          reject(err);
        }
      });
    },

    /** 粘贴循环规则判断单元
     *  */
    pasteUnits: async ({ index, currentRule }, { dispatch }, { state }) => {
      const clipboard = storage.clipboard;
      // 如果剪切板，localstorage中存储的不是units
      if (clipboard.type !== clipboardConstant.units) {
        return;
      }
      addUnit({
        units: currentRule.units,
        currentRule: currentRule,
        index: index,
        unitsForAdd: clipboard.content
      }).then(() => {
        dispatch({
          type: 'rule/updateRule',
          data: JSON.parse(JSON.stringify({ [currentRule.id]: state[currentRule.id] }))
        });
      });
    }
  }
};
