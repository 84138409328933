import React, { Component } from 'react';

import { Tooltip } from 'antd';
import Modal from '../../../components/Modal';
import { IconFont } from '../../../components/IconFont';
import ActionType from './ActionType';
class ActionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  // 删除当前
  del = item => {
    Modal.open({
      title: '提示',
      content: '真的要删除当前动作？',
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.props.del({ item });
        Modal.close();
      }
    });
  };

  // 复制
  copy = item => {
    this.props.copy && this.props.copy([item]);
  };

  // 复制新增
  copyAdd = item => {
    const { index } = this.props;
    this.props.copyAdd && this.props.copyAdd({ index, actionsForAdd: [item] });
  };

  // 选择条件
  selectedThis = (item, e) => {
    this.props.selectedThis && this.props.selectedThis(item, e);
  };
  stopPropagation = e => {
    e.stopPropagation();
  };

  render() {
    const { item, pos } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <span
        className='action-item'
        style={pos}
        id={item.id}
        onClick={this.selectedThis.bind(this, item)}
        onContextMenu={this.stopPropagation.bind(this)}
      >
        <ActionType data={item} {...props} />
        {!this.props.disabled && (
          <>
            <Tooltip title='复制'>
              <IconFont type='iconfuzhi1' className='condition-icon' onClick={this.copy.bind(this, item)} />
            </Tooltip>
            <Tooltip title='复制并新增'>
              <IconFont type='iconfuzhi' className='condition-icon' onClick={this.copyAdd.bind(this, item)} />
            </Tooltip>
            <Tooltip title='删除'>
              <IconFont type='iconweb-icon-' className='condition-icon' onClick={this.del.bind(this, item)} />
            </Tooltip>
          </>
        )}
      </span>
    );
  }
}

export default ActionItem;
