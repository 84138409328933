import React, { Component } from 'react';
import { Input, Icon } from 'antd';

class SearchTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: ''
    };
  }
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearAndReload) {
      this.clearAndSearch();
    }
  }
  onChange = e => {
    const value = e.target.value;
    this.setState({
      searchValue: value
    });
  };
  clearAndSearch = e => {
    this.setState(
      {
        searchValue: ''
      },
      () => {
        this.props.search(this.state.searchValue);
      }
    );
  };
  handleSearch = () => {
    this.props.search(this.state.searchValue);
  };

  render() {
    const { searchValue } = this.state;
    const { placeholder } = this.props;
    const searchIcon = !searchValue ? (
      <Icon type="search" onClick={this.handleSearch} />
    ) : (
      <span className="flex">
        <Icon
          type="close-circle"
          onClick={this.clearAndSearch}
          theme="filled"
          style={{ marginRight: '5px', fontSize: '12px', color: '#999' }}
        />
        <Icon type="search" onClick={this.handleSearch} />
      </span>
    );
    const style = this.props.width ? { width: this.props.width + 'px' } : {};
    return (
      <Input
        className="search-clear"
        placeholder={placeholder}
        onPressEnter={this.handleSearch}
        onChange={this.onChange}
        value={searchValue}
        suffix={searchIcon}
        style={style}
      />
    );
  }
}

export default SearchTool;
