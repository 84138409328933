import React from 'react';
import ConditionSelector from './Selector/ConditionSelector';
import VariableValue from './Common/VariableValue';
import DynamicVariableValue from './Common/DynamicVariableValue';
import MethodValue from './Common/MethodValue';
import FunctionValue from './Common/FunctionValue';
import ParameterValue from './Common/ParameterValue';
import ConstantsValue from './Common/ConstantsValue';
import ComplexArithmetic from './ComplexArithmetic';
import { ruleObj } from '../../../utils/rule';
class ConditionType extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  choose = (data, type) => {
    const { item } = this.props;
    item.left = item.left || {};

    switch (type) {
      case 'variable':
        this.setVariableValue(item.left, data, false);
        break;
      case 'parameter':
        this.setParameterValue(item.left, data, false);
        break;
      case 'method':
        this.setMethodsValue(item.left, data, false);
        break;
      case 'commonfunction':
        this.setFunctionValue(item.left, data, false);
        break;
      default:
        break;
    }
  };

  getMenus = () => {
    const menus = [];
    const { item } = this.props;
    const { variables, params, methods, functions, hasVariables, hasParams, hasMethods, hasFunctions } = ruleObj.file;
    item.left = item.left || {};
    if (variables && hasVariables) {
      menus.push({
        name: '选择变量',
        action: null,
        children: this.getVariableMenus(variables[0] || [], false)
      });
    }
    menus.push({
      name: '搜索变量',
      action: this.setDynamicVariableValue.bind(this, item.left)
    });
    if (params && hasParams) {
      menus.push({
        name: '选择参数',
        action: null,
        children: this.getParameterMenus(params[0] || [])
      });
    }
    if (methods && hasMethods) {
      menus.push({
        name: '选择方法',
        action: null,
        children: this.getMethodsMenus(methods)
      });
    }
    if (functions && hasFunctions) {
      menus.push({
        name: '选择函数',
        action: null,
        children: this.getFunctionsMenus(functions)
      });
    }
    return menus;
  };

  // 获取最终的type, 为了区分搜索变量
  getFinallyType = (type, data) => {
    if (type === 'method') {
      if (data.leftPart.methodName === 'getRemoteData' || data.leftPart.methodName === 'getRemoteDataV2') {
        return 'dynamicVariable';
      } else {
        return type;
      }
    } else {
      return type;
    }
  };

  renderInputType = (data, isValue) => {
    const type = this.getFinallyType(data.type, data);
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    switch (type) {
      case 'variable':
      case 'variableCategory':
        this.isDisabledSelector = false;
        return <VariableValue data={data} isValue={isValue} {...props} />;
      case 'dynamicVariable':
        this.isDisabledSelector = false;
        return <DynamicVariableValue data={data} isValue={isValue} {...props} />;
      case 'constant':
        this.isDisabledSelector = false;
        return <ConstantsValue data={data} isValue={isValue} {...props} />;
      case 'method':
        this.isDisabledSelector = true;
        return <MethodValue data={data} isValue={isValue} {...props} />;
      case 'parameter':
        this.isDisabledSelector = false;
        return <ParameterValue data={data} isValue={isValue} {...props} />;
      case 'commonfunction':
        this.isDisabledSelector = true;
        return <FunctionValue data={data} isValue={isValue} {...props} />;
      default:
        this.isDisabledSelector = false;
        break;
    }
  };

  getValue = () => {
    const { item } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return item.left ? (
      <>
        <span className='selector-text'>{this.renderInputType(item.left, false)}</span>
        {Object.keys(item.left).length > 0 && <ComplexArithmetic item={item.left} {...props} />}
      </>
    ) : null;
  };

  getPopText = () => {
    const { item } = this.props;
    return (
      <span>
        {Object.keys(item.left).length !== 0 ? (
          <span className='dashed-selector' />
        ) : (
          <span style={{ color: '#2fa2ca' }} className='selector-text'>
            请选择类型
          </span>
        )}
      </span>
    );
  };
}
export default ConditionType;
