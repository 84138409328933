import React, { Component } from 'react';
import { Tooltip, Icon } from 'antd';
import Modal from '../../../components/Modal';
import ContitionInfo from './ContitionInfo';
import { IconFont } from '../../../components/IconFont';

class ConditionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.conditionItem = React.createRef();
  }
  componentDidMount() {}

  // 删除当前
  del = (item, criterion) => {
    if (this.props.disabled) {
      return;
    }
    Modal.open({
      title: '提示',
      content: '真的要删除当前条件？',
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.props.del({ item, criterion, isContain: true });
        Modal.close();
      }
    });
  };

  // 复制
  copy = item => {
    if (this.props.disabled) {
      return;
    }
    this.props.copy && this.props.copy([item]);
  };

  // 复制新增
  copyAdd = (item, criterion) => {
    if (this.props.disabled) {
      return;
    }
    const { index } = this.props;
    this.props.copyAdd && this.props.copyAdd({ criterion, index: index + 1, conditions: [item] });
  };

  // 移动
  move = ({ criterion, index, direction }) => {
    if (this.props.disabled) {
      return;
    }
    this.props.move && this.props.move({ criterion, index, direction });
  };

  // 选择条件
  selectedThis = (item, e) => {
    if (this.props.disabled) {
      return;
    }
    this.props.selectedThis && this.props.selectedThis(item, e);
  };

  render() {
    const { endX, endY, item, criterion, index } = this.props;
    const style = {
      position: 'absolute',
      left: endX,
      top: endY,
      whiteSpace: 'nowrap'
    };

    const props = {
      updateRule: this.props.updateRule,
      copyConditions: this.props.copyConditions,
      pasteCondition: this.props.pasteCondition,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };

    return (
      <div
        className='condition-item'
        style={style}
        onClick={this.selectedThis.bind(this, item)}
        ref={this.conditionItem}
        id={item.id}
      >
        <ContitionInfo item={item} {...props} />
        {!this.props.disabled && (
          <>
            <Tooltip title='复制'>
              <IconFont type='iconfuzhi1' className='condition-icon' onClick={this.copy.bind(this, item, criterion)} />
            </Tooltip>
            <Tooltip title='复制并新增'>
              <IconFont
                type='iconfuzhi'
                className='condition-icon'
                onClick={this.copyAdd.bind(this, item, criterion)}
              />
            </Tooltip>
            {index !== 0 ? (
              <Tooltip title='上移'>
                <Icon
                  type='arrow-up'
                  className='condition-icon'
                  style={{ fontSize: '16px' }}
                  onClick={this.move.bind(this, { criterion, index, direction: 'up' })}
                />
              </Tooltip>
            ) : (
              <Icon type='arrow-up' className='condition-icon disabled' style={{ fontSize: '16px' }} />
            )}
            {index !== criterion.criterions.length - 1 ? (
              <Tooltip title='下移'>
                <Icon
                  type='arrow-down'
                  className='condition-icon'
                  style={{ fontSize: '16px' }}
                  onClick={this.move.bind(this, { criterion, index, direction: 'down' })}
                />
              </Tooltip>
            ) : (
              <Icon type='arrow-down' className='condition-icon disabled' style={{ fontSize: '16px' }} />
            )}
            <Tooltip title='删除'>
              <IconFont
                type='iconweb-icon-'
                className='condition-icon'
                onClick={this.del.bind(this, item, criterion)}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
  }
}
export default ConditionItem;
