import services from '../createServices';

export const projectOnline = {
  import: params => services.upload('/online/import/project', { params, isLoading: true }),
  forceImport: params => services.upload('/online/force/import/project', { params, isLoading: true }),
  importRemote: params => services.post('/online/import/remote/project', { params, isLoading: true }),
  forceImportRemote: params => services.post('/online/force/import/remote/project', { params, isLoading: true }),
  compare: params => services.post('/online/list/fileCompares', { params, isLoading: true }),
  loadXml: params => services.post('/online/loadXml', { params }),
  saveFile: params => services.post('/online/saveFile', { params, isLoading: true }),
  preview: params => services.post('/online/load/preview', { params, isLoading: true }),
  online: params => services.post('/online/goLine', { params, isLoading: true }),
  getLog: params => services.get('/online/get/goLineLogs', { params })
};
