import * as services from '../../services/api/index';
import { normalize, schema } from 'normalizr';

const initialState = {
  isEditMode: false,
  cateIds: [] // 存储rule id的数组
};
export default {
  name: 'variable',
  state: initialState,
  reducers: {
    toggleMode: (state, { data }) => {
      return {
        ...state,
        ...{
          isEditMode: !data
        }
      };
    },
    updateCate: (state, { data }) => {
      return {
        ...state,
        ...data
      };
    },
    updateVariable: (state, { data }) => {
      return {
        ...state,
        ...data
      };
    },
    removeCateById: (state, { id }) => {
      delete state[id];
      return {
        ...state
      };
    },
    removeVariableById: (state, { id }) => {
      delete state[id];
      return {
        ...state
      };
    },
    updateCateIds: (state, { data }) => {
      return {
        ...state,
        cateIds: data
      };
    },
    updateInitialState: state => {
      return {
        ...initialState,
        isEditMode: state.isEditMode
      };
    }
  },
  effects: {
    queryLists: async ({ params }, { dispatch }, { state }) => {
      const res = await services.variable.getList(params);
      if (res.code === 0) {
        const data = res.data;
        const cates = data && data[0];
        cates &&
          cates.forEach(cate => {
            cate.id = cate.name;
            cate.variables &&
              cate.variables.forEach(variable => {
                variable.id = cate.name + '.' + variable.name;
                if (!variable.desc) {
                  variable.desc = '';
                }
                if (!variable.metaInfo) {
                  variable.metaInfo = '';
                }
              });
          });

        const variable = new schema.Entity('variable');
        const cate = new schema.Entity('cate', { variables: [variable] });
        const normalizedData = normalize(cates, [cate]);
        dispatch({ type: 'variable/updateInitialState' });
        dispatch({ type: 'variable/updateCate', data: normalizedData.entities.cate });
        dispatch({ type: 'variable/updateCateIds', data: normalizedData.result });
        dispatch({ type: 'variable/updateVariable', data: normalizedData.entities.variable });

        return cates;
      }
    }
  }
};
