import React from 'react';
import Selector from '../Components/Selector';
import ConditionValue from './ConditionValue';

class NextType extends Selector {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMenus = () => {
    return [
      {
        name: '值',
        type: 'Value',
        action: this.choose.bind(this, '')
      },
      {
        name: '括号',
        type: 'Paren',
        action: this.choose.bind(this, 'Paren')
      }
    ];
  };

  choose = (type, isClearValue) => {
    const { item } = this.props;
    if (type === 'Paren') {
      item.value = { value: {} };
      item.value.valueType = type;
    } else {
      item.value = {};
    }
    this.updateRule();
  };

  updateRule = () => {
    this.props.updateRule();
  };

  getValue = () => {
    const { item } = this.props;
    return item.value ? <span>{this.renderNextType(item)}</span> : null;
  };

  getPopText = () => {
    return (
      <span>
        <span className="dashed-selector" />
      </span>
    );
  };

  renderNextType = item => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    switch (item.value.valueType) {
      case 'Paren':
        return (
          <span>
            (<ConditionValue item={item.value} {...props} />)
          </span>
        );
      default:
        return <ConditionValue item={item} {...props} />;
    }
  };
}
export default NextType;
