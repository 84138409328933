import { Button } from 'antd';
import React from 'react';
import ActionArea from './ActionArea';

class Else extends ActionArea {
  constructor(props) {
    super(props);
    this.areaTitle = '否则';
  }
  componentDidMount() {
    this.props.setChildToParent(this);
  }

  getButtons = () => {
    return (
      <>
        {!this.props.disabled && (
          <Button className="btn-small lightblue" type="primary" onClick={this.addAction}>
            添加动作
          </Button>
        )}
      </>
    );
  };
}

export default Else;
