import { randomKey } from './utils';

// 重组rule数据
export function resetRuleData(item) {
  item.id = randomKey();
  // 属性默认值
  item.activeKey = ['1'];
  item.isShown = true;
  if (item.enabled === undefined) {
    item.enabled = true;
  }
  if (item.debug === undefined) {
    item.debug = false;
  }
  if (item.loop === undefined) {
    item.loop = false;
  }
  if (item.rulePreconditionEnabled === undefined) {
    item.rulePreconditionEnabled = false;
  }

  // 如果是循环规则
  if (item.loopRule) {
    // 循环对象 loopTarget

    item.loopTarget = item.loopTarget ? { ...item.loopTarget, id: randomKey() } : { value: {}, id: randomKey() };
    item.loopType = item.loopType ? { ...item.loopType, id: randomKey() } : { value: {}, id: randomKey() };

    initActions(item, 'loopStart'); // 开始前动作
    initActions(item, 'loopEnd'); // 结束后动作
    // 条件判断单元
    if (item.units && item.units.length > 0) {
      item.units.forEach(it => {
        it.id = randomKey();
        if (!it.name) {
          it.name = 'Condition Unit';
        }
        initConditions(it);
        initActions(it, 'rhs');
        initActions(it, 'other');
      });
    }
  } else {
    initConditions(item);
    initActions(item, 'rhs');
    initActions(item, 'other');
  }
  return item;
}

// 初始化条件
export function initConditions(item) {
  const lhs =
    item && item.lhs && Object.keys(item.lhs).length > 0
      ? item.lhs
      : {
          criterion: { criterions: [], junctionType: 'and' }
        };

  if (lhs.criterion) {
    item.lhs = { criterion: resetLhs(lhs.criterion) };
  }
}

// 初始化动作
export function initActions(item, key) {
  if (item[key]) {
    item[key].id = randomKey();
    if (item[key].actions && item[key].actions.length > 0) {
      item[key].actions.map(it => {
        it.id = randomKey();
        return it;
      });
    } else {
      item[key].actions = [];
    }
  } else {
    item[key] = {
      actions: [],
      id: randomKey()
    };
  }
}

// 重组条件数据，新增id
export function resetLhs(data) {
  data.id = randomKey();
  if (data && data.criterions && data.criterions.length > 0) {
    data.criterions.forEach(item => {
      resetLhs(item);
    });
  }
  return data;
}

// 全局变量，用于规则集，不用redux存储避免重复render
export const ruleObj = {
  selectedConditions: [],
  selectedRules: [],
  selectedActions: [],
  selectedActionAreas: [],
  selectedUnits: [],
  keyPressForCtrl: false,
  rules: [], // 存储全部的rules
  file: {
    libraries: [],
    nodes: [],
    methods: [], //动作方法
    constants: [], //常量
    variables: [], //变量
    params: [], //参数
    functions: [], //函数
    hasConstants: false,
    hasVariables: false,
    hasParams: false,
    hasFunctions: false,
    hasMethods: false
  }
};

export function updateRules(ruleIds, state) {
  return ruleIds.map(id => {
    return state[id];
  });
}
