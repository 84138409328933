import React, { Component } from 'react';
import { randomKey } from '../../../utils/utils';
import Condition from './Condition';
import PaperCondition from './PaperCondition';
import './rule-common.less';
class If extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 40,
      path: []
    };
    this.condition = React.createRef();
  }
  componentDidMount() {}
  resetPaper = data => {
    let len = 0;
    if (data && data.criterions) {
      len = this.getTotal(data.criterions, len);
      const totalHeight = len * 30 + 10;
      return {
        height: totalHeight,
        len: len
      };
    } else {
      return {
        height: 40,
        len: 0
      };
    }
  };
  getTotal = (data, len) => {
    if (data.length > 0) {
      data.forEach(item => {
        if (item.criterions) {
          len = this.getTotal(item.criterions, len);
        } else {
          len++;
        }
      });
    } else {
      len++;
    }

    return len;
  };
  getProps = () => {
    const props = {
      updateRule: this.props.updateRule,
      copyConditions: this.props.copyConditions,
      pasteCondition: this.props.pasteCondition,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return props;
  };
  getLine = len => {
    const nums = [];
    for (let i = 0; i < len; i++) {
      nums.push(i + 1);
    }
    return (
      <div className="line-wrap">
        {nums.map(num => {
          return (
            <div className="line-number" style={{ lineHeight: '30px' }} key={num}>
              {num}
            </div>
          );
        })}
      </div>
    );
  };
  getContext = () => {
    const { data } = this.props;
    return <Condition data={data} unit={this.props.unit} {...this.getProps()} />;
  };
  componentWillUnmount() {}
  render() {
    const { title, data } = this.props;
    const { height, len } = this.resetPaper(data);
    return (
      <div className="con-container">
        <div className="con-text">
          <strong>{title}</strong>
        </div>

        <div className="condition-area" style={{ height, position: 'relative' }}>
          <PaperCondition data={data} key={randomKey()} />
          <>
            {this.getLine(len)}
            {this.getContext()}
          </>
        </div>
      </div>
    );
  }
}
export default If;
