import React, { Component } from 'react';
import { Input } from 'antd';
import './index.less';
class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      error: false
    };
  }
  componentDidMount() {
    this.input = React.createRef();
    if (this.props.value === '' || this.props.editable) {
      this.edit();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value === '' || nextProps.editable) {
      this.edit();
    }
  }
  edit = e => {
    this.setState(
      {
        editable: true,
        error: false
      },
      () => {
        this.input.current.focus();
      }
    );
    if (e) {
      e.stopPropagation();
    }
  };
  onBlur = e => {
    const value = e.target.value;
    // 如果为空，则用之前的value
    if (!value && this.props.value) {
      this.setState({
        editable: false
      });
      return;
    }
    if (this.props.callback) {
      this.props.callback(value).then(
        () => {
          if (!this.props.editable) {
            this.setState({
              editable: false
            });
          }
        },
        err => {
          this.setState({
            error: true,
            errorTxt: err
          });
        }
      );
    }
  };
  handleChange = () => {};
  render() {
    const { value, color, width = 150, errorTxt } = this.props;
    const { editable, error } = this.state;
    const style = error ? { borderColor: '#ff0000', width: width + 'px' } : { width: width + 'px' };
    const inputClassName = this.props.inputClassName !== undefined ? this.props.inputClassName : 'small';
    return (
      <span>
        {editable ? (
          <>
            <Input
              style={style}
              className={inputClassName}
              defaultValue={value}
              onBlur={this.onBlur}
              ref={this.input}
              onChange={this.handleChange}
              onClick={e => e.stopPropagation()}
            />
            {error && errorTxt && <span style={{ color: '#ff0000', marginLeft: '5px' }}>{errorTxt}</span>}
            {error && this.state.errorTxt && (
              <span style={{ color: '#ff0000', marginLeft: '5px' }}>{this.state.errorTxt}</span>
            )}
          </>
        ) : (
          <span onClick={this.edit} className="input-text-editor" style={{ color: color }}>
            {value}
          </span>
        )}
      </span>
    );
  }
}
export default InputText;
