import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as event from '../../../utils/event/componentEvent';
import './index.less';
class IFrame extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }
  componentDidMount() {
    window.componentEvent = event;
    event.eventEmitter.emit(event.SHOW_LOADING);

    ReactDOM.findDOMNode(this).onload = () => {
      if (this.props.toOrigin) {
        const str = JSON.stringify(this.props.prevActiveInfo);
        ReactDOM.findDOMNode(this).contentWindow._metaInfo = str;
        ReactDOM.findDOMNode(this).contentWindow._toggleBtn();
      }
    };
  }
  componentDidUpdate() {
    const { id } = this.props;
    if (id && this.props.isReload && this.props.isReload[id]) {
      this.reload();
    }
  }
  isLoadingDependByRequest = type => {
    const arr = ['rule', 'variable', 'v2', 'rule_template'];
    if (arr.includes(type)) {
      return true;
    }
    return false;
  };
  reload = () => {
    const { id } = this.props;
    event.eventEmitter.emit(event.SHOW_LOADING);
    ReactDOM.findDOMNode(this).contentWindow.location.reload();
    this.props.reload(id, false);
  };
  render() {
    const { src, title } = this.props;
    return (
      <iframe
        src={src}
        className="pane-iframe"
        title={title}
        style={{ width: '100%', height: '100%', border: 'none' }}
        ref={this.iframe}
      />
    );
  }
}

export default IFrame;
