import * as componentEvent from './event/componentEvent';
const event = window.top.componentEvent || componentEvent;
const handleCode = function(res, { ERROR_CODE, SUCCESS_CODE, WARNING_CODE }) {
  const { code, msg } = res;
  if (ERROR_CODE.includes(code)) {
    console.log('错误', event, componentEvent, window.top.componentEvent);
    event && event.eventEmitter.emit(event.SHOW_ERROR_MSG, msg);
  }
  if (SUCCESS_CODE.includes(code)) {
    console.log('成功', event, componentEvent, window.top.componentEvent);
    event && event.eventEmitter.emit(event.SHOW_SUCCESS_MSG, msg);
  }
  if (WARNING_CODE.includes(code)) {
    console.log('警告', event, componentEvent, window.top.componentEvent, event.SHOW_WARNING_MSG);
    event && event.eventEmitter.emit(event.SHOW_WARNING_MSG, msg);
  }
};
export default handleCode;
