export const host = '';
export const apiHost = process.env.NODE_ENV === 'development' ? '' : '';
export const clipboardConstant = {
  rules: 'CLIPBOARD_RULES', // 复制规则
  conditions: 'CLIPBOARD_CONDITIONS', // 复制条件
  actions: 'CLIPBOARD_ACTIONS', // 复制工作
  units: 'CLIPBOARD_UNITS' // 复制工作
};

export const ERROR_CODE = [1, 3, 9, 3001, 3002, 3003, 500];
export const SUCCESS_CODE = [];
export const WARNING_CODE = [3000, 3004, 3005, 3006, 3007];

// 项目权限码表
export const PROJECT_AUTH_CODE = {
  read: 'PROJECT_READ',
  write: 'PROJECT_WRITE',
  publish: 'PROJECT_PUBLISH',
  config: 'PROJECT_AUTHORITY_CONFIG'
};
