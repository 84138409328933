import React from 'react';
import InputText from './index';
import { AutoComplete, Input, Popover } from 'antd';

class InputSearch extends InputText {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.input = React.createRef();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value === '') {
      this.edit();
    }
  }
  onSelect = (value, option) => {
    // 如果为空，则用之前的value
    if ((!value && this.props.value) || (!option && this.props.value)) {
      this.setState({
        editable: false
      });
      return;
    }
    if (this.props.callback) {
      this.props.callback(option).then(
        () => {
          this.setState({
            editable: false
          });
        },
        err => {
          this.setState({
            error: true
          });
        }
      );
    }
  };
  handleSearch = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  render() {
    const { value, dataSource, content, placeholder } = this.props;
    const { editable, error } = this.state;
    const style = error ? { borderColor: '#ff0000' } : {};
    return (
      <span>
        {editable ? (
          <AutoComplete
            className="tantuer-rule"
            dataSource={dataSource}
            onSelect={this.onSelect}
            onSearch={this.handleSearch}
            onBlur={this.onSelect}
            placeholder={placeholder}
            optionLabelProp="text"
            defaultValue={value}
            ref={this.input}
          >
            <Input style={style} className="small" />
          </AutoComplete>
        ) : (
          <Popover overlayClassName="tantuer" content={content} placement="bottomLeft">
            <span onClick={this.edit} className="input-text-editor" style={{ color: '#19885f' }}>
              {value}
            </span>
          </Popover>
        )}
      </span>
    );
  }
}

export default InputSearch;
