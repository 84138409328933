import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { ruleObj } from '../../../utils/rule';

@connect(mapStateToProps)
class NavLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: ['1']
    };
    this.else = null;
  }
  componentDidMount() {}

  // 选中pannel
  selectThis = () => {
    const { rule } = this.props;
    const newSelectedRules = [rule];

    // 把选中的存储全局变量ruleObj
    ruleObj.selectedRules = newSelectedRules;

    // 控制样式，这样避免react多次render对比
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });
  };

  scrollToRule = id => {
    let rule = document.getElementById(id);
    let scroller = document.getElementById('scroller');
    scroller.scrollTop = rule.offsetTop - 138;
    this.props.setNavCurrentId(id);
    this.selectThis();
  };

  toggleRule = id => {
    const { rule } = this.props;
    if (rule.isHide) {
      rule.isHide = false;
    } else {
      rule.isHide = true;
    }
    this.updateRule();
  };

  updateRule = () => {
    const { rule, id } = this.props;
    this.props.dispatch({
      type: 'rule/updateRule',
      data: JSON.parse(JSON.stringify({ [id]: rule }))
    });
  };

  render() {
    const { id, rule, currentNavLinkId } = this.props;
    const isSelectedStyle = currentNavLinkId === id ? { background: 'rgb(245, 251, 255)' } : {};
    return (
      <div data-id={id} key={id} className="anchor" style={isSelectedStyle} onClick={this.scrollToRule.bind(this, id)}>
        {!rule.isHide ? (
          <Icon type="eye" onClick={this.toggleRule.bind(this, id)} />
        ) : (
          <Icon type="eye-invisible" onClick={this.toggleRule.bind(this, id)} style={{ color: '#d3531c' }} />
        )}
        <span>{rule.name}</span>
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    rule: state.rule[props.id]
  };
}
export default NavLink;
