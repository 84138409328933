import React from 'react';
import { Icon, Button } from 'antd';
import TabPage from '../../../components/TabPage';
import IFrame from '../../../components/layout/Content/Iframe';
import * as event from '../../../utils/event/componentEvent';
import './index.less';

class TabWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loaded = false;
    this.iframe = React.createRef();
  }
  componentDidMount() {}
  loadingCallback = activeKey => {
    if (activeKey === '2') {
      if (!this.loaded) {
        window.top.componentEvent && window.top.componentEvent.eventEmitter.emit(event.SHOW_LOADING);
        this.loaded = true;
      } else {
        // 请求子iframe test的方法filePublish，更新数据
        const contentWindow = this.iframe.current.iframe.current.contentWindow;
        contentWindow.filePublish(1);
      }
    }
  };
  backFlow = () => {
    const componentEvent = window.parent.componentEvent;
    let info = JSON.parse(window._metaInfo);
    let { originInfo } = info;
    if (componentEvent) {
      componentEvent.eventEmitter.emit('tree_node_click', JSON.parse(originInfo));
    }
  };
  render() {
    const { file, children } = this.props;
    return (
      <div className="tantuer tantuer-tabWrapper" style={{ height: '100%' }}>
        <Button
          icon="rollback"
          id="backBtn"
          onClick={this.backFlow}
          style={{ position: 'absolute', right: '5px', top: '5px', zIndex: 99, display: 'none' }}
        >
          返回流程
        </Button>
        <TabPage defaultActiveKey="1" callback={this.loadingCallback}>
          <TabPage.Item
            title={
              <>
                <Icon type="file" theme="filled" />
                <span>规则</span>
              </>
            }
            key="1"
          >
            {children}
          </TabPage.Item>
          <TabPage.Item
            title={
              <>
                <Icon type="thunderbolt" theme="filled" />
                <span>测试</span>
              </>
            }
            key="2"
          >
            {file && <IFrame src={`/frame/test.html?file=${file}`} ref={this.iframe} />}
          </TabPage.Item>
        </TabPage>
      </div>
    );
  }
}

export default TabWrapper;
