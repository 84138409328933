/**
 * deepCopy 深拷贝
 * @source : 对象或者数组
 * @maxLevel: 最大拷贝的深度
 * @level:当前拷贝深度
 *  */
export const deepCopy = (source, maxLevel, level) => {
  const sourceCopy = source instanceof Array ? [] : {};
  for (let key in source) {
    if (level >= maxLevel) {
      sourceCopy[key] = source[key];
      return;
    }
    if (source[key] instanceof Object) {
      sourceCopy[key] = deepCopy(source[key], maxLevel, level++);
    } else {
      sourceCopy[key] = source[key];
    }
  }
  return sourceCopy;
};

export const randomKey = () => {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 16), 1);
  }
  s[0] = 'x';
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[8] = s[13] = s[18] = s[23] = '_';
  return s.join('');
};

let watermark = {};

let setWatermark = str => {
  let id = '1.23452384164.123412415';

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }

  let can = document.createElement('canvas');
  can.width = 150;
  can.height = 120;

  let cans = can.getContext('2d');
  cans.rotate((-20 * Math.PI) / 180);
  cans.font = '20px Vedana';
  cans.fillStyle = 'rgba(200, 200, 200, 0.18)';
  cans.textAlign = 'left';
  cans.textBaseline = 'Middle';
  cans.fillText(str, can.width / 3, can.height / 2);

  let div = document.createElement('div');
  div.id = id;
  div.style.pointerEvents = 'none';
  div.style.top = '70px';
  div.style.left = '0px';
  div.style.position = 'fixed';
  div.style.zIndex = '100000';
  div.style.width = document.documentElement.clientWidth - 100 + 'px';
  div.style.height = document.documentElement.clientHeight - 100 + 'px';
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  document.body.appendChild(div);
  return id;
};

export function escapeXml(xml) {
  return xml.replace(/[<>&"]/g, function(c) {
    return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c];
  });
}

watermark.set = str => {
  let id = setWatermark(str);
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str);
    }
  }, 500);
  window.onresize = () => {
    setWatermark(str);
  };
};

export function getCodeByKey(search, key) {
  const searchGroup = search.replace('?', '').split('&');
  let code = '';
  for (let i = 0; i < searchGroup.length; i++) {
    const keycodes = searchGroup[i].split('=');
    if (keycodes[0] === key) {
      code = keycodes[1];
      break;
    }
  }
  if (code) {
    return code;
  } else {
    return null;
  }
}

export default watermark;
