import { escapeXml } from '../../utils';
import ConditionsToXml from './ConditionsToXml';
import ActionsToXml from './ActionsToXml';
import CommonToXml from './CommonToXml';

/**  RulesToXml
 *   rule 转xml
 *   @data：条件构成的数组
 */
export default class RulesToXml extends CommonToXml {
  constructor(data) {
    super();
    this.data = data;
  }

  toXml() {
    let rulesXml = '';
    this.data.forEach(item => {
      if (item.loopRule) {
        rulesXml += this.loopRuleToXml(item);
      } else {
        rulesXml += this.ruleToXml(item);
      }
    });

    return rulesXml;
  }

  // 普通规则
  ruleToXml(data) {
    const propertyXml = this.propertyToXml(data);
    const ifXml = this.ifToXml(data.lhs);
    const thenXml = this.thenToXml(data.rhs.actions);
    const elseXml = this.elseToXml(data.other.actions);
    return `<rule name="${escapeXml(data.name)}" ${propertyXml}>${this.remarkToXml(
      data.remark || ''
    )}${ifXml}${thenXml}${elseXml}</rule>`;
  }

  // 循环规则
  loopRuleToXml(data) {
    const propertyXml = this.propertyToXml(data);
    const loopTargetXml = this.loopTargetToXml(data.loopTarget);
    const loopTypeXml = this.loopTypeToXml(data.loopType);
    const loopStartXml = this.loopStartToXml(data.loopStart.actions);
    const unitsXml = this.unitsToXml(data.units);
    const loopEndXml = this.loopEndToXml(data.loopEnd.actions);

    return `<loop-rule name="${escapeXml(data.name)}" ${propertyXml}>${this.remarkToXml(
      data.remark || ''
    )}${loopTargetXml}${loopTypeXml}${loopStartXml}${unitsXml}${loopEndXml}</loop-rule>`;
  }

  propertyToXml(data) {
    const properties = [
      'salience',
      'loop',
      'effectiveDate',
      'expiresDate',
      'enabled',
      'debug',
      'activationGroup',
      'agendaGroup',
      'autoFocus',
      'ruleflowGroup'
    ];
    let xml = ``;
    for (let i = 0; i < properties.length; i++) {
      const key = properties[i];
      const value = data[key];
      if (value !== undefined && value !== null) {
        if (key === 'effectiveDate') {
          xml += ` effective-date="${value}"`;
        } else if (key === 'expiresDate') {
          xml += ` expires-date="${value}"`;
        } else if (key === 'activationGroup') {
          if (!value) {
            throw '请输入属性互斥组的具体值!';
          }
          xml += ` activation-group="${value}"`;
        } else if (key === 'agendaGroup') {
          if (!value) {
            throw '请输入属性执行组的具体值!';
          }
          xml += ` agenda-group="${value}"`;
        } else if (key === 'autoFocus') {
          xml += ` auto-focus="${value}"`;
        } else if (key === 'ruleflowGroup') {
          xml += ` ruleflow-group="${value}"`;
        } else {
          xml += ` ${key}="${value}"`;
        }
      }
    }
    return xml;
  }

  // remark
  remarkToXml(data) {
    return '<remark><![CDATA[' + data + ']]></remark>';
  }

  ifToXml(lhs) {
    const conditionsToXml = new ConditionsToXml([lhs.criterion]);
    const xml = conditionsToXml.toXml();
    return `<if>${xml}</if>`;
  }
  thenToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<then>${xml}</then>`;
  }
  elseToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<else>${xml}</else>`;
  }

  loopTargetToXml(data) {
    return `<loop-target>${this.valueToXml(data.value)}</loop-target>`;
  }

  loopTypeToXml(data) {
    return `<loop-type>${this.valueToXml(data.value)}</loop-type>`;
  }

  loopStartToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<loop-start>${xml}</loop-start>`;
  }

  loopEndToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<loop-end>${xml}</loop-end>`;
  }

  unitsToXml(data) {
    let unitsXml = '';
    data.forEach(item => {
      const ifXml = this.ifToXml(item.lhs);
      const thenXml = this.thenToXml(item.rhs.actions);
      const elseXml = this.elseToXml(item.other.actions);
      unitsXml += `<unit name="${item.name}">
        ${ifXml}${thenXml}${elseXml}
      </unit>`;
    });
    return `<units>${unitsXml}</units>`;
  }
}
