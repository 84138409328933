/**
 * VariableAssign 变量赋值设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import ConditionValue from '../ConditionValue';
import ParameterValue from './ParameterValue';
import VariableValue from './VariableValue';
import { ruleObj } from '../../../../utils/rule';

class VariableAssign extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMenus = () => {
    const menus = [];
    const { variables, params, hasVariables, hasParams } = ruleObj.file;
    if (variables && hasVariables) {
      menus.push({
        name: '选择变量',
        action: null,
        children: this.getVariableMenus(variables[0] || [], true)
      });
    }
    if (params && hasParams) {
      menus.push({
        name: '选择参数',
        action: null,
        children: this.getParameterMenus(params[0] || [])
      });
    }
    return menus;
  };
  choose = (item, type) => {
    const { data } = this.props;
    switch (type) {
      case 'variable':
        this.setVariableValue(data, item, true, true);
        break;
      case 'parameter':
        this.setParameterValue(data, item, true, true);
        break;
      default:
        break;
    }
  };
  renderLeftValue = data => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    const type = data.type || data.valueType;
    switch (type) {
      case 'variable':
      case 'Variable':
      case 'variableCategory':
      case 'VariableCategory':
        this.isDisabledSelector = false;
        return <VariableValue data={data} isValue={true} isAssignLeft={true} {...props} />;
      case 'parameter':
      case 'Parameter':
        this.isDisabledSelector = false;
        return <ParameterValue data={data} isValue={true} isAssignLeft={true} {...props} />;
      default:
        this.isDisabledSelector = false;
        break;
    }
  };
  renderValueText = data => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <>
        {this.renderLeftValue(data)} =
        <ConditionValue item={data} {...props} />
      </>
    );
  };
  getValue = () => {
    const { data } = this.props;
    return <>{this.renderValueText(data)}</>;
  };
  getPopText = () => {
    const { data } = this.props;
    const { id, actionType, value, ...otherData } = data;
    return (
      <span style={{ color: '#002b8d', cursor: 'pointer' }}>
        {Object.keys(otherData).length !== 0 ? (
          <span className='dashed-selector' />
        ) : (
          <span style={{ color: '#2fa2ca' }} className='selector-text'>
            请选择值类型
          </span>
        )}
      </span>
    );
  };
}
export default VariableAssign;
