import services from '../createServices';

export const variable = {
  getList: params => services.post('/common/loadXml', { params, isLoading: true }),
  saveFile: params => services.post('/common/saveFile', { params, isLoading: true }),
  refactorContent: params => services.post('/common/refactorContent', { params, isFormdata: true, isLoading: true }),
  loadRefs: params => services.post('/common/loadReferenceFiles', { params, isLoading: true }),
  getFeats: params => services.get('/feature/list', { params, isFormdata: true, isLoading: true }),
  onRefresh: params => services.get('/feature/sync/remote', { params, isFormdata: true, isLoading: true }),
  searchType: params => services.get('/feature/searchType', { params, isFormdata: true, isLoading: true }),
  search: params => services.post('/feature/search', { params }),
  refactorVar: params => services.post('/refactor/var', { params, isLoading: true }),
  refactorVarCategory: params => services.post('/refactor/varCategory', { params, isLoading: true })
};
