import React from 'react';
import Selector from '../Components/Selector';

class ComparisonOperator extends Selector {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMenus = () => {
    return [
      {
        name: '大于',
        type: 'GreaterThen',
        action: this.choose.bind(this, 'GreaterThen')
      },
      {
        name: '大于或等于',
        type: 'GreaterThenEquals',
        action: this.choose.bind(this, 'GreaterThenEquals')
      },
      {
        name: '小于',
        type: 'LessThen',
        action: this.choose.bind(this, 'LessThen')
      },
      {
        name: '小于或等于',
        type: 'LessThenEquals',
        action: this.choose.bind(this, 'LessThenEquals')
      },
      {
        name: '等于',
        type: 'Equals',
        action: this.choose.bind(this, 'Equals')
      },
      {
        name: '等于(不分大小写)',
        type: 'EqualsIgnoreCase',
        action: this.choose.bind(this, 'EqualsIgnoreCase')
      },
      {
        name: '开始于',
        type: 'StartWith',
        action: this.choose.bind(this, 'StartWith')
      },
      {
        name: '不开始于',
        type: 'NotStartWith',
        action: this.choose.bind(this, 'NotStartWith')
      },
      {
        name: '结束于',
        type: 'EndWith',
        action: this.choose.bind(this, 'EndWith')
      },
      {
        name: '不结束于',
        type: 'NotEndWith',
        action: this.choose.bind(this, 'NotEndWith')
      },
      {
        name: '不等于',
        type: 'NotEquals',
        action: this.choose.bind(this, 'NotEquals')
      },
      {
        name: '不等于(不分大小写)',
        type: 'NotEqualsIgnoreCase',
        action: this.choose.bind(this, 'NotEqualsIgnoreCase')
      },
      {
        name: '在集合',
        type: 'In',
        action: this.choose.bind(this, 'In')
      },
      {
        name: '不在集合',
        type: 'NotIn',
        action: this.choose.bind(this, 'NotIn')
      },
      {
        name: '为空',
        type: 'Null',
        action: this.choose.bind(this, 'Null', '', true)
      },
      {
        name: '不为空',
        type: 'NotNull',
        action: this.choose.bind(this, 'NotNull', '', true)
      },
      {
        name: '匹配正则表达式',
        type: 'Match',
        action: this.choose.bind(this, 'Match')
      },
      {
        name: '不匹配正则表达式',
        type: 'NotMatch',
        action: this.choose.bind(this, 'NotMatch')
      },
      {
        name: '包含',
        type: 'Contain',
        action: this.choose.bind(this, 'Contain')
      },
      {
        name: '不包含',
        type: 'NotContain',
        action: this.choose.bind(this, 'NotContain')
      },
      {
        name: '在区间',
        type: 'InRange',
        children: [
          {
            name: '左开右闭',
            type: 'LORC',
            action: this.choose.bind(this, 'InRange', 'LORC')
          },
          {
            name: '左开右开',
            type: 'LORO',
            action: this.choose.bind(this, 'InRange', 'LORO')
          },
          {
            name: '左闭右开',
            type: 'LCRO',
            action: this.choose.bind(this, 'InRange', 'LCRO')
          },
          {
            name: '左闭右闭',
            type: 'LCRC',
            action: this.choose.bind(this, 'InRange', 'LCRC')
          }
        ]
      },
      {
        name: '不在区间',
        type: 'OutRange',
        children: [
          {
            name: '左开右闭',
            type: 'LORC',
            action: this.choose.bind(this, 'OutRange', 'LORC')
          },
          {
            name: '左开右开',
            type: 'LORO',
            action: this.choose.bind(this, 'OutRange', 'LORO')
          },
          {
            name: '左闭右开',
            type: 'LCRO',
            action: this.choose.bind(this, 'OutRange', 'LCRO')
          },
          {
            name: '左闭右闭',
            type: 'LCRC',
            action: this.choose.bind(this, 'OutRange', 'LCRC')
          }
        ]
      }
    ];
  };
  choose = (type, inType = '', isClearValue = false) => {
    const { item } = this.props;
    const rangeType = ['LORC', 'LORO', 'LCRO', 'LCRC'];
    let inTypeValue = {};
    item.op = type;
    if (rangeType.includes(inType)) {
      inTypeValue.rangeType = inType;
      inTypeValue.valueType = 'Range';
      inTypeValue.parameters = [
        {
          name: '左边界',
          type: 'String',
          value: {}
        },
        {
          name: '右边界',
          type: 'String',
          value: {}
        }
      ];
    }
    //重新选择条件比较符之后，value重置
    item.value = isClearValue ? null : rangeType.includes(inType) ? inTypeValue : {};
    this.updateRule();
  };
  updateRule = () => {
    this.props.updateRule();
  };
  getPopText = () => {
    const { item } = this.props;
    return (
      <span>
        {item.op ? (
          <span style={{ color: '#d90013' }} className="selector-text">
            {this.renderComparison(item.op)}
          </span>
        ) : (
          <span style={{ color: '#d90013' }} className="selector-text">
            请选择比较操作符
          </span>
        )}
      </span>
    );
  };
  renderComparison = op => {
    const arr = this.getMenus();
    const current = arr.find(item => item.type === op);
    return current.name;
  };
}
export default ComparisonOperator;
