const initialState = {
  tabs: [],
  activeId: ''
};
export default {
  name: 'tabs',
  state: initialState,
  reducers: {
    updateTabs: (state, { data }) => {
      return {
        ...state,
        tabs: [...data]
      };
    },
    updateAtiveTabs: (state, { data }) => {
      return {
        ...state,
        activeId: data
      };
    }
  }
};
