import React from 'react';
import { Input } from 'antd';
class Remark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorTxt: ''
    };
  }
  componentDidMount() {
    this.input = React.createRef();
  }
  parseBreak = data => {
    data
      .replace(new RegExp('<', 'gm'), '&lt;')
      .replace(new RegExp('>', 'gm'), '&gt;')
      .replace(new RegExp('\n', 'gm'), '</br>');

    return data;
  };
  edit = e => {
    this.setState(
      {
        editable: true,
        error: false
      },
      () => {
        this.input.current.focus();
      }
    );
    if (e) {
      e.stopPropagation();
    }
  };
  onBlur = e => {
    const value = e.target.value;

    if (this.props.callback) {
      this.props.callback(value).then(
        () => {
          this.setState({
            editable: false
          });
        },
        err => {
          this.setState({
            error: true,
            errorTxt: err
          });
        }
      );
    }
  };
  handleChange = () => {};
  render() {
    const { value = '', color } = this.props;
    const { editable, error, errorTxt } = this.state;
    return (
      <span>
        {editable ? (
          <>
            <Input.TextArea
              onChange={this.handleChange}
              autosize={false}
              style={{ resize: 'none', height: '80px' }}
              defaultValue={value}
              onBlur={this.onBlur}
              ref={this.input}
              onClick={e => e.stopPropagation()}
            />
            {error && errorTxt && <span style={{ color: '#ff0000', marginLeft: '5px' }}>{errorTxt}</span>}
            {error && this.state.errorTxt && (
              <span style={{ color: '#ff0000', marginLeft: '5px' }}>{this.state.errorTxt}</span>
            )}
          </>
        ) : (
          <div
            onClick={this.edit}
            className="remark"
            style={{ color: color }}
            dangerouslySetInnerHTML={{ __html: this.parseBreak(value||'') || '请输入备注内容' }}
          />
        )}
      </span>
    );
  }
}

export default Remark;
