import React, { Component } from 'react';
import { Input, Icon, Popover } from 'antd';
import PopMenu from './index';
class Menus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filterData: ''
    };
  }
  componentDidMount() {}
  action = (action, e) => {
    if (action) {
      action();
      PopMenu.close();
    }
    e.stopPropagation();
  };
  hide = () => {
    this.setState({
      filterData: ''
    });
  };
  clearFilter = () => {
    this.setState({
      filterData: ''
    });
  };
  filterMenu = e => {
    const value = e.target.value;
    this.setState({
      filterData: value
    });
  };
  showChildren = (key, e) => {
    document.getElementById(key).style.display = 'block';
    const popTarget = document.getElementById(key);
    const clickTarget = this.findParent(e.target, 'menu-item');
    if (clickTarget) {
      this.adjustArrowDirection(popTarget, clickTarget);
    } else {
      console.log('不存在', e.target, clickTarget);
    }
    e.stopPropagation();
  };
  findParent = (target, className) => {
    if (target.className === className) {
      return target;
    } else {
      return this.findParent(target.parentNode, className);
    }
  };
  hideChildren = (key, e) => {
    document.getElementById(key).style.display = 'none';
  };

  /**
   *
   * @param {Object} popTarget 弹出菜单对象
   * @param {Object} clickTarget 点击对象
   */
  adjustArrowDirection(popTarget, clickTarget) {
    const wrapW = document.body.offsetWidth - 8; //视口宽度,去除滚动条8px
    const wrapH = document.body.offsetHeight; //视口高度
    const clientRect = clickTarget.getBoundingClientRect();
    const clickTargetMiddleY = clientRect.top + clientRect.height / 2; // 点击对象垂直方向居中的top值
    const clickTargetOffsetMiddleY = clickTarget.offsetTop + clickTarget.offsetHeight / 2;
    const marginY = 20; // 垂直方向留白偏移量
    const marginX = 6; // 水平方向留白偏移量（箭头位置）
    let top = 0;
    let left = 0;

    //调整垂直方向
    // 箭头垂直居中
    if (
      popTarget.offsetHeight / 2 + clickTargetMiddleY <= wrapH &&
      clickTargetMiddleY - popTarget.offsetHeight / 2 >= 0
    ) {
      top = clickTargetOffsetMiddleY - popTarget.offsetHeight / 2;
      //箭头在上方
    } else if (clickTargetMiddleY - marginY >= 0 && popTarget.offsetHeight - marginY + clickTargetMiddleY <= wrapH) {
      top = clickTarget.offsetTop;
      //箭头在下方
    } else if (clickTargetMiddleY + marginY <= wrapH && clickTargetMiddleY - (popTarget.offsetHeight - marginY) >= 0) {
      top = clickTarget.offsetTop + clickTarget.offsetHeight - popTarget.offsetHeight;
      //箭头根据计算，在垂直方向随机位置
    } else {
      top = -(clientRect.top - clickTarget.offsetTop);
    }

    //调整水平方向
    // 箭头在弹窗菜单左侧
    if (popTarget.offsetWidth + clientRect.right + marginX <= wrapW) {
      left = clickTarget.offsetWidth;
      // 箭头在弹窗菜单右侧
    } else {
      left = -popTarget.offsetWidth;
    }
    popTarget.style.top = top + 'px';
    popTarget.style.left = left + 'px';
  }

  getSubMenus = menu => {
    const { filterData } = this.state;
    if (menu.children && menu.children.length > 0) {
      const beforeMenus = menu.children.filter(item => item.name.indexOf(filterData) !== -1);
      const afterMenus = menu.children.filter(item => item.name.indexOf(filterData) === -1);
      const menusFilter = menu.filter ? beforeMenus.concat(afterMenus) : menu.children;
      const key = menu.key || menu.name;
      const disabled = menu.disabled || false;
      return (
        <div
          className='menu-item'
          key={key}
          onMouseEnter={this.showChildren.bind(this, key)}
          onMouseLeave={this.hideChildren.bind(this, key)}
        >
          <div className='menu-item-sub' onClick={!disabled ? this.action.bind(this, menu.action) : null}>
            {menu.name}
            <Icon type='right' />
          </div>
          <div className='menu-sub' id={key} title={menu.name}>
            <div className='popmenu-filter' hidden={!menu.filter} style={{ width: '100%' }}>
              <Input
                allowClear
                prefix={<Icon type='filter' />}
                className='popmenu-filter-input'
                onChange={this.filterMenu}
                value={filterData}
              />
            </div>
            <div className='tantuer-menus'>
              {menusFilter.map(item => {
                return this.getSubMenus(item);
              })}
            </div>
          </div>
        </div>
      );
    } else {
      const index = menu.name.indexOf(filterData);
      const beforeStr = menu.name.substr(0, index);
      const afterStr = menu.name.substr(index + filterData.length);
      const item =
        index > -1 ? (
          <span style={{ display: 'block' }}>
            {beforeStr}
            <span style={{ color: '#f50' }}>{filterData}</span>
            {afterStr}
          </span>
        ) : (
          <span style={{ display: 'block' }}>{menu.name}</span>
        );
      const disabled = menu.disabled || false;
      return (
        <div
          className={disabled ? 'menu-item menu-item-disabled' : 'menu-item'}
          key={menu.key || menu.name}
          onClick={!disabled ? this.action.bind(this, menu.action) : null}
        >
          {menu.popover ? (
            <Popover overlayClassName='tantuer' content={menu.popover} placement='rightTop'>
              {item}
            </Popover>
          ) : (
            item
          )}
        </div>
      );
    }
  };
  render() {
    const { menus, isFilter } = this.props;
    const { filterData } = this.state;
    const beforeMenus = menus.filter(item => item.name.indexOf(filterData) !== -1);
    const afterMenus = menus.filter(item => item.name.indexOf(filterData) === -1);
    const menusFilter = isFilter ? beforeMenus.concat(afterMenus) : menus;
    return (
      <div className='tantuer-menus'>
        {menusFilter.map(item => {
          return this.getSubMenus(item);
        })}
      </div>
    );
  }
}

export default Menus;
