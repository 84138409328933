import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';

@connect(mapStateToProps)
class RuleSaveNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  onClose = e => {};
  render() {
    return (
      <>
        {this.props.isNeedSave && <Alert message="编辑完请记得保存！" type="warning" closable onClose={this.onClose} />}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    isNeedSave: state.rule.isNeedSave
  };
}
export default RuleSaveNotification;
