/**
 * InputValue 输入值设置组件
 *   */
import React, { Component } from 'react';
import InputText from '../../../../components/InputText';

class InputValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false
    };
  }
  componentDidMount() {}
  setValue = value => {
    const { data } = this.props;
    return new Promise((resolve, reject) => {
      if (value) {
        data.content = value;
        this.updateRule();
        resolve();
      } else {
        reject();
      }
    });
  };
  updateRule = () => {
    this.props.updateRule();
  };
  render() {
    const { content } = this.props.data;
    return (
      <>
        {this.props.disabled ? (
          <span style={{ color: 'rgb(156, 100, 10)' }}>{content}</span>
        ) : (
          <InputText callback={this.setValue} value={content} color="rgb(156, 100, 10)" />
        )}
      </>
    );
  }
}
export default InputValue;
