import React from 'react';
import Selector from '../Components/Selector';
import NextType from './NextType';

class ComplexArithmetic extends Selector {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMenus = () => {
    return [
      {
        name: '+',
        type: 'Add',
        action: this.choose.bind(this, 'Add')
      },
      {
        name: '-',
        type: 'Sub',
        action: this.choose.bind(this, 'Sub')
      },
      {
        name: 'x',
        type: 'Mul',
        action: this.choose.bind(this, 'Mul')
      },
      {
        name: '÷',
        type: 'Div',
        action: this.choose.bind(this, 'Div')
      },
      {
        name: '%',
        type: 'Mod',
        action: this.choose.bind(this, 'Mod')
      },
      {
        name: '删除',
        type: '',
        action: this.clear.bind(this)
      }
    ];
  };

  choose = type => {
    const { item } = this.props;
    item.arithmetic = { type };
    this.updateRule();
  };

  clear = () => {
    const { item } = this.props;
    item.arithmetic = {};
    this.updateRule();
  };

  updateRule = () => {
    this.props.updateRule();
  };

  getValue = () => {
    const { item } = this.props;
    return item.arithmetic && Object.keys(item.arithmetic).length > 0 ? (
      <span>{this.renderValue(item.arithmetic)}</span>
    ) : null;
  };

  getPopText = () => {
    const { item } = this.props;

    return (
      <span>
        {item.arithmetic && Object.keys(item.arithmetic).length > 0 ? (
          <span className="selector-text">{this.renderArithmetic(item.arithmetic)}</span>
        ) : (
          <span className="dashed-selector" />
        )}
      </span>
    );
  };

  renderArithmetic = arithmetic => {
    const type = arithmetic.type;
    const arr = this.getMenus();
    const current = arr.find(item => item.type === type);
    return current.name;
  };
  renderValue = arithmetic => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return <NextType item={arithmetic} {...props} />;
  };
}
export default ComplexArithmetic;
