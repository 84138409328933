/**
 * FunctionProperty 函数-属性选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import { ruleObj } from '../../../../utils/rule';
class FunctionProperty extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getMenus = () => {
    const { variables } = ruleObj.file;
    const data = (variables[0] && variables[0][0].variables) || [];
    return this.getVariableMenus(data, true);
  };
  choose = value => {
    const { data } = this.props;
    this.setPropertyValue(data, value);
  };
  renderValueText = data => {
    const { propertyLabel } = data;
    return `${propertyLabel}`;
  };
  getPopText = () => {
    const { data } = this.props;
    return (
      <span style={{ color: '#19885f', cursor: 'pointer' }}>
        {Object.keys(data).length !== 0 && data.property !== '' ? this.renderValueText(data) : '请选择属性'}
      </span>
    );
  };
}
export default FunctionProperty;
