import ConditionsToXml from './ConditionsToXml';
import ActionsToXml from './ActionsToXml';

/**  UnitsToXml
 *   units 转xml
 *   @data：条件构成的数组
 */
export default class UnitsToXml {
  constructor(data) {
    this.data = data;
  }

  toXml() {
    let unitsXml = '';
    this.data.forEach(item => {
      const ifXml = this.ifToXml(item.lhs);
      const thenXml = this.thenToXml(item.rhs.actions);
      const elseXml = this.elseToXml(item.other.actions);
      unitsXml += `<unit name="${item.name}">
        ${ifXml}${thenXml}${elseXml}
      </unit>`;
    });

    return unitsXml;
  }

  ifToXml(lhs) {
    const conditionsToXml = new ConditionsToXml([lhs.criterion]);
    const xml = conditionsToXml.toXml();
    return `<if>${xml}</if>`;
  }
  thenToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<then>${xml}</then>`;
  }
  elseToXml(actions) {
    const actionsToXml = new ActionsToXml(actions);
    const xml = actionsToXml.toXml();
    return `<else>${xml}</else>`;
  }
}
