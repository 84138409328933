import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import routerConfig from './router';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './model/index';
import storage from './utils/storage';
import * as services from './services/api';

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<div></div>}>
            {routerConfig.map(({component: Component, path, redirect, auth, exact}, key) => {
              return (
                  <Route
                      path={path}
                      exact={exact}
                      key={key}
                      render={props => {
                        if (redirect) {
                          return <Redirect to={redirect}/>;
                        }
                        if (auth) {
                          if (storage.token) {
                            return <Component {...props} />;
                          } else {
                            window.top.location.href = '/login';
                          }
                        } else {
                          return <Component {...props} />;
                        }
                      }}
                  />
              );
            })}
          </Suspense>
          <Redirect to="/404"/>
        </Switch>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

async function getConfig() {
  const res = await services.system.config();
  if (res.code === 0) {
    storage.config = res.data;
  }
}

const defaultTitle = '决策引擎';
if (storage.config.title) {
  document.title = storage.config.title || defaultTitle;
} else {
  getConfig().then(() => {
    document.title = storage.config.title || defaultTitle;
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
