import { lazy } from 'react';
const ruleTemplateRouter = [
  {
    path: '/editor/ruleTemplate/list',
    name: '规则模板列表',
    exact: false,
    auth: true,
    component: lazy(() => import('./list'))
  },
  {
    path: '/editor/ruleTemplate/edit',
    name: '规则模板编辑',
    exact: false,
    auth: true,
    component: lazy(() => import('./edit'))
  },
  {
    path: '/editor/ruleTemplate/detail',
    name: '规则模板查看',
    exact: false,
    auth: true,
    component: lazy(() => import('./detail'))
  },
  {
    path: '/editor/ruleTemplate/import',
    name: '规则模板导入',
    exact: false,
    auth: true,
    component: lazy(() => import('../RuleTemplateImport'))
  },
  {
    path: '/editor/ruleTemplate/syncOnline',
    name: '规则模板同步到线上',
    exact: false,
    auth: true,
    component: lazy(() => import('../RuleTemplateImport/RuleTemplateSyncOnline'))
  },
  {
    path: '/editor/ruleTemplate/defaultActions',
    name: '默认动作配置',
    exact: false,
    auth: true,
    component: lazy(() => import('../DefaultAction/DefaultActionTemplate'))
  }
];
export default ruleTemplateRouter;
