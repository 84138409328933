/**
 * Range 在区间选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import ConditionValue from '../ConditionValue';

class Range extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
    this.leftText = '';
    this.rightText = '';
  }
  renderValueText = data => {
    const { parameters } = data;
    const rangeTypeArr = data.rangeType.split('');
    const leftLetter = rangeTypeArr[1];
    const rightLetter = rangeTypeArr[3];
    this.leftText = leftLetter === 'C' ? '包含' : leftLetter === 'O' ? '不包含' : null;
    this.rightText = rightLetter === 'C' ? '包含' : rightLetter === 'O' ? '不包含' : null;
    return <>[{this.mapParameters(parameters)}]</>;
  };
  mapParameters = parameters => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return parameters.map(item => {
      return (
        <span key={item.name}>
          <span style={{ color: '#333333' }}>
            {item.name}({item.name === '左边界' ? this.leftText : this.rightText}):
          </span>
          <ConditionValue item={item} {...props} />;
        </span>
      );
    });
  };
  getValue = () => {
    const { data } = this.props;
    return <>{this.renderValueText(data)}</>;
  };
}
export default Range;
