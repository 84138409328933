import React, { Component } from 'react';
import { Collapse, Icon, Divider, Tooltip, Message } from 'antd';
import { connect } from 'react-redux';
import If from '../Components/If';
import Then from '../Components/Then';
import Else from '../Components/Else';
import LoopTarget from '../Components/LoopTarget';
import LoopType from '../Components/LoopType';
import LoopStart from '../Components/LoopStart';
import LoopEnd from '../Components/LoopEnd';
import Units from '../Components/Units';
import './RulePanel.less';
import RuleProperty from './RuleProperty';
import { IconFont } from '../../../components/IconFont/index';
import { ruleObj } from '../../../utils/rule';
import InputText from '../../../components/InputText';
import Remark from './Remark';

const Panel = Collapse.Panel;

@connect(mapStateToProps)
class RulePannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderBody: true
    };
    this.else = null;
    this.ruleNameIsOk = true;
  }
  componentDidMount() {
    this.props.setChildrensToParent && this.props.setChildrensToParent('rulePannels', this);
    if (this.props.rule.open) {
      this.openPannel(this.props.id);
    }
    if (this.props.defaultExpendRuleName === this.props.rule.name) {
      this.scrollToRuleAndExpend(this.props.rule.id);
    }
  }
  componentWillReceiveProps(nextProps) {
    // 为了能连续ctrl+v 粘贴，需要获取到更新后的选中rule 的引用
    if (ruleObj.selectedRules.length === 1 && nextProps.id === ruleObj.selectedRules[0].id) {
      ruleObj.selectedRules = [nextProps.rule];
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { rule } = nextProps;
    const { selectedRules } = ruleObj;
    const isCurrent = selectedRules.some(it => it.id === rule.id);

    if (
      nextProps.rule === this.props.rule &&
      nextProps.index === this.props.index &&
      nextProps.renderBody === this.props.renderBody &&
      nextState.renderBody === this.state.renderBody
    ) {
      if (!isCurrent) {
        return false;
      }
    }
    return true;
  }

  // 滚动并高亮展开
  scrollToRuleAndExpend = id => {
    let rule = document.getElementById(id);
    let scroller = document.getElementById('scroller');
    scroller.scrollTop = rule.offsetTop - 98;
    this.openPannel(id); // 默认展开
    this.selectThis();
  };

  openPannel = id => {
    document.querySelectorAll('.rule-pannel-collapse').forEach(item => {
      const cid = item.getAttribute('id');

      if (cid === id) {
        this.renderBody();
        item.className = 'rule-pannel-collapse open';
      }
    });
  };

  renderBody = () => {
    if (!this.state.renderBody) {
      this.setState({
        renderBody: true
      });
    }
  };

  setChildToParent = el => {
    this.else = el;
  };

  // 选中pannel
  selectThis = (e, multiple) => {
    const { rule } = this.props;
    const { selectedRules, keyPressForCtrl } = ruleObj;
    let newSelectedRules = [];
    // 如果是多选，按住ctrl/command
    if (multiple || keyPressForCtrl) {
      newSelectedRules = selectedRules.some(it => it.id === rule.id)
        ? selectedRules.filter(it => it.id !== rule.id)
        : selectedRules.concat([rule]);
      // 单选
    } else {
      newSelectedRules = [rule];
    }
    // 把选中的存储全局变量ruleObj
    ruleObj.selectedRules = newSelectedRules;
    // 控制样式，这样避免react多次render对比
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });
  };

  onCheckChange = e => {
    this.selectThis(e, true);
    e.stopPropagation();
  };

  // 复制
  copy = e => {
    const { rule } = this.props;
    this.props.dispatch({ type: 'rule/copyRules', rules: [rule] }).catch(err => {
      Message.warning(err);
    });
  };

  func = ({ id, index, rule }, type, e) => {
    this.props[type]({ id, index: index + 1, rule });
  };

  componentWillUnmount() {
    ruleObj.selectedRules = ruleObj.selectedRules.filter(it => it.id !== this.props.id);
  }

  setElse = data => {
    this.else.action.addAction(data);
  };

  // 设置规则名
  setRuleName = value => {
    const { rule } = this.props;
    return new Promise((resolve, reject) => {
      if (value && !this.compareRuleNameIsRepeat(value)) {
        rule.name = value;
        this.updateRule();
        this.ruleNameIsOk = true;
        resolve();
      } else {
        this.ruleNameIsOk = false;
        reject();
      }
    });
  };

  setRuleRemark = value => {
    const { rule } = this.props;

    return new Promise((resolve, reject) => {
      rule.remark = value;
      this.updateRule();
      resolve();
    });
  };

  // 对比是否有规则名重复,true->有重复，false->无重复
  compareRuleNameIsRepeat = value => {
    return ruleObj.rules.some(it => it.name === value && it.id !== this.props.id);
  };

  updateRule = () => {
    const { rule, id } = this.props;
    this.props.dispatch({
      type: 'rule/updateRule',
      data: JSON.parse(JSON.stringify({ [id]: rule }))
    });
  };

  /** 复制条件
   */
  copyConditions = conditions => {
    this.props.dispatch({ type: 'rule/copyConditions', conditions }).catch(err => {
      Message.warning(err);
    });
  };

  /** 粘贴到联合条件
   *  */
  pasteCondition = criterion => {
    const { id } = this.props;
    this.props.dispatch({ type: 'rule/pasteCondition', criterion, ruleId: id });
  };

  /** 复制动作
   */
  copyActions = actions => {
    this.props.dispatch({ type: 'rule/copyActions', actions }).catch(err => {
      Message.warning(err);
    });
  };

  // 粘贴动作
  pasteActions = data => {
    const { id } = this.props;
    this.props.dispatch({ type: 'rule/pasteActions', data, ruleId: id });
  };

  // 复制
  copyUnits = data => {
    this.props.dispatch({ type: 'rule/copyUnits', units: data }).catch(err => {
      Message.warning(err);
    });
  };

  // 修改属性
  onChangeProperty = (key, value) => {
    const { rule } = this.props;
    rule[key] = value;
    this.updateRule();
  };

  // 删除某些属性
  onRemoveProperty = key => {
    const { rule } = this.props;
    delete rule[key];
    this.updateRule();
  };

  genExtra = () => {
    const { index, rule } = this.props;

    const { id, agendaGroup, effectiveDate, expiresDate, enabled, salience, activationGroup } = rule || {};
    const ruleOps = (
      <>
        <span onClick={this.copy}>
          <Tooltip title='复制'>
            <IconFont type='iconfuzhi1' className='rule-icon' />
          </Tooltip>
        </span>
        <span onClick={this.func.bind(this, { rule: rule, index: index }, 'copyAdd')}>
          <Tooltip title='复制并新增'>
            <IconFont type='iconfuzhi' className='rule-icon' />
          </Tooltip>
        </span>
        <span onClick={this.func.bind(this, { id: id }, 'delRule')}>
          <Tooltip title='删除'>
            <IconFont type='iconweb-icon-' className='rule-icon' />
          </Tooltip>
        </span>
      </>
    );
    const txt = (
      <span className='open-no-exist'>
        {effectiveDate !== '' && effectiveDate !== undefined ? <pre>生效：{effectiveDate}</pre> : ''}
        {expiresDate !== '' && expiresDate !== undefined ? <pre>失效：{expiresDate}</pre> : ''}
      </span>
    );
    return (
      <>
        <span className='open-no-exist'>
          {agendaGroup !== undefined ? (
            <Tooltip title='同为执行组的规则文件每个都会被执行'>执行组({agendaGroup})</Tooltip>
          ) : activationGroup !== undefined ? (
            <Tooltip title='同为互斥组的规则文件命中其中一个就不会再执行同组其他'>互斥组({activationGroup})</Tooltip>
          ) : (
            <Tooltip title='默认按优先级顺序执行普通组内所有规则'>普通组</Tooltip>
          )}
        </span>

        {(agendaGroup === '' || effectiveDate === undefined) && (expiresDate === '' || expiresDate === undefined) ? (
          ''
        ) : (
          <span className='open-no-exist'>
            <Tooltip title={txt}>
              <Icon type='clock-circle' />
            </Tooltip>
          </span>
        )}

        <span className='open-no-exist'>
          {enabled ? (
            <>
              <Icon type='play-circle' />
              {salience}
            </>
          ) : (
            <Icon type='pause-circle' />
          )}
        </span>
        {ruleOps}
      </>
    );
  };
  toggle = id => {
    document.querySelectorAll('.rule-pannel-collapse').forEach(item => {
      const cid = item.getAttribute('id');

      if (cid === id) {
        if (item.className.indexOf('open') === -1) {
          this.renderBody();
          item.className = 'rule-pannel-collapse open';
        } else {
          item.className = 'rule-pannel-collapse';
        }
      }
    });
  };

  render() {
    const { renderBody } = this.state;
    const { rule } = this.props;
    const { remark, name, lhs, rhs, other, id, isHide } = rule || {};
    const ruleTxt = rule.loopRule ? '循环规则' : '规则';
    const title = (
      <strong>
        {ruleTxt}:
        <InputText callback={this.setRuleName} value={name} color='#333' width={300} errorTxt='规则名重复！' />
      </strong>
    );
    const props = {
      updateRule: this.updateRule,
      copyConditions: this.copyConditions,
      pasteCondition: this.pasteCondition,
      copyActions: this.copyActions,
      pasteActions: this.pasteActions,
      copyUnits: this.copyUnits,
      rule: this.props.rule,
      disabled: false,
      projectName: this.props.projectName
    };
    return (
      <div className='rule-pannel' onClick={this.selectThis} id={id} style={{ display: !isHide ? 'block' : 'none' }}>
        <span className='checkbox rule-checkbox' onClick={this.onCheckChange}>
          <Icon type='border' />
          <Icon type='check-square' />
        </span>
        <div className='rule-pannel-collapse' id={id}>
          <div className='rule-pannel-collapse-hd' onDoubleClick={this.toggle.bind(this, id)}>
            <div className='rule-pannel-collapse-hd-l'>
              <span onClick={this.toggle.bind(this, id)}>
                <Icon type='caret-right' />
                <Icon type='caret-down' />
                <span>{title}</span>
              </span>
            </div>
            <div className='rule-pannel-collapse-hd-r'>
              <Tooltip title={remark}>
                <span className='extra-remark'>{remark !== '' ? `备注:${remark}` : ''}</span>
              </Tooltip>
              <span className='extra-other'>{this.genExtra()}</span>
            </div>
          </div>
          {renderBody && (
            <div className='rule-pannel-collapse-body'>
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <Icon type='caret-right' rotate={isActive ? 90 : 0} />}
              >
                <Panel header={<span className=''>备注：</span>} key='1'>
                  <Remark value={remark} callback={this.setRuleRemark} />
                </Panel>
              </Collapse>
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <Icon type='caret-right' rotate={isActive ? 90 : 0} />}
              >
                <Panel header={<span className=''>属性：</span>} key='1'>
                  <RuleProperty
                    rule={this.props.rule}
                    onChangeProperty={this.onChangeProperty}
                    onRemoveProperty={this.onRemoveProperty}
                  />
                </Panel>
              </Collapse>
              {!rule.loopRule ? (
                <>
                  <If data={lhs.criterion} id={id} title='条件' {...props} />
                  <Divider style={{ margin: '0' }} />
                  <Then data={rhs} id={id} currentId={rhs.id} setElse={this.setElse} {...props} />
                  <Divider style={{ margin: '0' }} />
                  <Else data={other} id={id} currentId={other.id} setChildToParent={this.setChildToParent} {...props} />
                </>
              ) : (
                <>
                  <LoopTarget data={rule.loopTarget} id={id} {...props} />
                  <Divider style={{ margin: '0' }} />
                  <LoopType data={rule.loopType} id={id} {...props} />
                  <Divider style={{ margin: '0' }} />
                  <LoopStart data={rule.loopStart} id={id} currentId={rule.loopStart.id} {...props} />
                  <Divider style={{ margin: '0' }} />
                  <Units data={rule.units} id={id} {...props} />
                  <Divider style={{ margin: '0' }} />
                  <LoopEnd data={rule.loopEnd} id={id} currentId={rule.loopEnd.id} {...props} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    rule: state.rule[props.id],
    file: state.file
  };
}
export default RulePannel;
