/*
 * 默认展开树
 * @maxLevel：要展开的最大层级
 * @currentLevel: 当前层级
 */
export function defaultExpend(data, maxLevel, currentLevel, ids) {
  if (currentLevel > maxLevel) {
    return ids;
  }
  ids.push(data.id);
  if (data.children && data.children.length) {
    currentLevel++;
    for (let i = 0; i < data.children.length; i++) {
      defaultExpend(data.children[i], maxLevel, currentLevel, ids);
    }
  }
  return ids;
}
export function findThisNode(data, id) {
  if (data.id === id) {
    return data;
  }
  if (data.children && data.children.length > 0) {
    for (let i = 0; i < data.children.length; i++) {
      const res = findThisNode(data.children[i], id);
      if (res) {
        return res;
      }
    }
  }
}

export function toMatch(o, id) {
  if (o.id === id) {
    return [o.id];
  }
  if (o.children && o.children.length) {
    for (var idx = 0; idx < o.children.length; idx++) {
      var tm = toMatch(o.children[idx], id);
      if (tm) {
        const arr = [o.id];
        tm.forEach(it => {
          arr.push(it);
        });
        return arr;
      }
    }
  }
}
export function resetNameArr(item, arrs) {
  arrs.push({
    name: item.name
  });
  if (item.children && item.children.length > 0) {
    for (let i = 0; i < item.children.length; i++) {
      resetNameArr(item.children[i], arrs);
    }
  }
  return arrs;
}

export function buildTreeData(item, { projectName, folderPath, isSystem }) {
  setEditorPath(item, isSystem);
  item.projectName = projectName
    ? projectName
    : item.type === 'project'
    ? item.name
    : item.type === 'projects'
    ? ''
    : getProjectName(item.fullPath);
  item.folderPath = folderPath ? folderPath : item.type === 'folder' ? item.fullPath : '';
  if (
    item.type !== 'test' &&
    item.type !== 'publish' &&
    item.type !== 'rules' &&
    item.type !== 'lib' &&
    item.type !== 'ruleLib' &&
    item.type !== 'decisionTableLib' &&
    item.type !== 'decisionTreeLib' &&
    item.type !== 'scorecardLib' &&
    item.type !== 'flowLib' &&
    item.type !== 'folder'
  ) {
    item.id = item.fullPath;
  } else {
    if (item.type === 'folder') {
      item.id = `${getNewPath(item.fullPath, '')}${item.folderType}/${item.name}`;
    } else {
      item.id = `${item.fullPath}/${item.name}`;
    }
  }
  if (item.children && item.children.length > 0) {
    for (let i = 0; i < item.children.length; i++) {
      buildTreeData(item.children[i], { projectName: item.projectName, isSystem });
    }
  }
}
export function getProjectName(fullpath) {
  const paths = fullpath.split('/');
  return paths[1];
}

export function getNewPath(path, value) {
  const arr = path.split('/');
  const len = arr.length;
  let newPath = '';
  arr.forEach((item, i) => {
    if (i === len - 1 || i === 0) {
      return;
    }
    newPath += `/${item}`;
  });
  return newPath + '/' + value;
}

// 设置url
export function setEditorPath(item, isSystem) {
  const frameUrl = '/frame';
  switch (item.type) {
    case 'variable':
      // item.editorPath = `${frameUrl}/variable-editor.html`;
      item.editorPath = `/variable`;
      break;
    case 'constant':
      item.editorPath = `${frameUrl}/constant-editor.html`;
      break;
    case 'parameter':
      item.editorPath = `${frameUrl}/parameter-editor.html`;
      break;
    case 'action':
      item.editorPath = `${frameUrl}/action-editor.html`;
      break;
    case 'rule': //向导式决策集
      // item.editorPath = `${frameUrl}/ruleset-editor.html`;
      item.editorPath = `/editor/rule`;
      break;
    case 'ul': // 脚本式决策集
      item.editorPath = `${frameUrl}/ul-editor.html`;
      break;
    case 'decisionTable': //决策表
      item.editorPath = `${frameUrl}/decisiontable-editor.html`;
      break;
    case 'crosstab': //交叉决策表
      item.editorPath = `${frameUrl}/crosstab-editor.html`;
      break;
    case 'decisionTree': //决策树
      item.editorPath = `${frameUrl}/decisiontree-editor.html`;
      break;
    case 'scorecard': //评分卡
      item.editorPath = `${frameUrl}/scorecard-editor.html`;
      break;
    case 'complexscorecard': //评分卡
      item.editorPath = `${frameUrl}/complexScoreCard.html`;
      break;
    case 'flow': //决策流
      item.editorPath = `${frameUrl}/rule-flow-designer.html`;
      break;

    case 'constant_template': //模板管理-常量
      item.editorPath = `${frameUrl}/constant-editor.html`;
      break;
    case 'parameter_template': //模板管理-参数
      item.editorPath = `${frameUrl}/parameter-editor.html`;
      break;
    case 'action_template': //模板管理-函数
      item.editorPath = `${frameUrl}/action-editor.html`;
      break;
    case 'rule_template_dir': //模板管理-规则
      item.editorPath = `/editor/ruleTemplate/list`;
      item.isNotFile = true;
      break;
    case 'scriptDecisionTable':
      item.editorPath = `${frameUrl}/scriptdecisiontable-editor.html`;
      break;
    case 'test': //测试管理
    case 'resourcePackage':
      item.editorPath = `${frameUrl}/package-editor.html`;
      break;
    case 'publish': //发布管理
      if (isSystem) {
        item.editorPath = `${frameUrl}/oncePush.html`;
      } else {
        item.editorPath = `${frameUrl}/publish.html`;
      }
      break;
    case 'global_conf': //全局配置
      item.editorPath = `${frameUrl}/baseSetting.html`;
      item.isNotFile = true;
      break;
    case 'publish_server_version': //服务端
      item.editorPath = `${frameUrl}/serverManage.html`;
      item.isNotFile = true;
      break;
    case 'publish_client_version': //客户端
      item.editorPath = `${frameUrl}/clientManage.html`;
      item.isNotFile = true;
      break;

    case 'api_config': //api管理
      item.editorPath = `${frameUrl}/apiConfig.html`;
      item.isNotFile = true;
      item.isCheckPermission = true; // 需要检查权限
      break;
    case 'feat_config': //特征数据源配置
      item.editorPath = `${frameUrl}/featConfig.html`;
      item.isNotFile = true;
      item.isCheckPermission = true; // 需要检查权限
      break;
    case 'user_info': //用户信息
      // item.editorPath = `${frameUrl}/userManage.html`;
      item.editorPath = `/userManage`;
      item.isNotFile = true;
      // item.isCheckPermission = true; // 需要检查权限
      break;
    case 'login_log': //登录日志
      item.editorPath = `${frameUrl}/loginlog.html`;
      item.isNotFile = true;
      item.isCheckPermission = true; // 需要检查权限
      break;
    case 'workspace': //工作区管理
      item.editorPath = `${frameUrl}/workspace.html`;
      item.isNotFile = true;
      item.isCheckPermission = true; // 需要检查权限
      break;
    case 'user_group': //用户组管理
      item.editorPath = `/userGroup`;
      item.isNotFile = true;
      break;
    case 'first_login': //工作区管理
      item.editorPath = `/firstLogin`;
      item.isNotFile = true;
      break;
    case 'operation_log': //操作日志
      item.editorPath = `/operationLog`;
      item.isNotFile = true;
      break;
    default:
      return undefined;
  }
}
