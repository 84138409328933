import React, { Component } from 'react';
import { Button, Dropdown, Icon, Menu, Collapse, Message } from 'antd';
import { ruleObj, resetRuleData } from '../../../utils/rule';
import Modal from '../../../components/Modal';
import VersionComment from '../../../components/VersionComment/index';
import * as services from '../../../services/api/index';
import RuleImportTemplates from './RuleImportTemplates';
import RuleToXml from '../../../utils/ToXml/Rules/index';
import Remark from './Remark';
import { connect } from 'react-redux';

const Panel = Collapse.Panel;

@connect(mapStateToProps)
class RuleTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openStatus: false
    };
    this.modalKey = null;
    this.saveXml = '';
  }

  componentDidMount() {}

  // 折叠/打开规则
  toggleRules = (value, e) => {
    e.stopPropagation();
    const { openStatus } = this.state;
    this.setState({ openStatus: !openStatus });
    document.querySelectorAll('.rule-pannel-collapse').forEach(item => {
      if (!openStatus) {
        item.className = 'rule-pannel-collapse open';
        setTimeout(() => {
          this.props.updateRenderBody && this.props.updateRenderBody(item.getAttribute('id'));
        }, 100);
      } else {
        item.className = 'rule-pannel-collapse';
      }
    });
  };

  // 修改备注
  onHandleChange = e => {
    this.props.changeRemark && this.props.changeRemark(e.target.value);
  };

  // 规则排序
  sort = async e => {
    e.stopPropagation();
    let haveOrderRules = ruleObj.rules.filter(item => item.salience !== undefined);
    let haveNotOrderRules = ruleObj.rules
      .filter(item => item.salience === undefined)
      .map(item => {
        return item.id;
      });
    const newOrderRules = haveOrderRules
      .sort((a, b) => {
        return b.salience - a.salience;
      })
      .map(item => {
        return item.id;
      });
    const newRuleIds = [...newOrderRules, ...haveNotOrderRules];
    this.props.updateRuleIds(newRuleIds);
  };

  // 从模板导入
  loadFromTemplates = async e => {
    e.stopPropagation();
    this.modalKey = Modal.open({
      title: '规则模板',
      content: <RuleImportTemplates loadNow={this.loadNow} />,
      maskClosable: false,
      isOkBtn: false,
      isCancelBtn: false,
      width: 800
    });
  };

  // 正式导入模板
  loadNow = async files => {
    const file = files.join(';');
    const res = await services.common.loadRuleTemplate({ filePath: file }, true);
    if (res.code !== 0) {
      return;
    }
    const rules = res.data && res.data.length > 0 ? res.data.map(item => item.rules[0]) : [];
    if (rules.length > 0) {
      // 初始化 rules
      const newRules = rules
        .map(item => {
          return resetRuleData(item);
        })
        .map(item => {
          item.open = true;
          return item;
        });
      this.props.addRule({ index: 0, rules: newRules, isNotCopied: true });
      Message.success('插入操作成功！');
    } else {
      Message.warning('模板内容为空！');
    }
    Modal.close(this.modalKey);
  };

  // 保存
  save = e => {
    const isRuleNameOK = this.props.rulePannels.every(item => {
      return item.ruleNameIsOk;
    });
    if (!isRuleNameOK) {
      Message.warning('请检查规则名称');
      return;
    }
    const ruleToXml = new RuleToXml(this.props.remark, ruleObj.rules.reverse());
    try {
      this.saveXml = ruleToXml.toXml();
      this.modalKey = Modal.open({
        title: '请输入新版本描述',
        content: <VersionComment saveNow={this.saveNow} />,
        maskClosable: false,
        isOkBtn: false,
        isCancelBtn: false,
        width: 600
      });
    } catch (err) {
      Message.warning(err);
    }
    e.stopPropagation();
  };

  // 正式提交服务端
  saveNow = ({ comment }) => {
    const params = {
      content: encodeURIComponent(this.saveXml),
      filePath: this.props.file,
      newVersion: true,
      version: this.props.version,
      versionComment: comment
    };
    services.common.saveFile(params).then(res => {
      if (res.code === 0) {
        Message.success('保存成功');
        Modal.close(this.modalKey);
        this.props.updateIsNeedSave();
        this.props.getFileVersions && this.props.getFileVersions();
      }
    });
  };
  add = e => {
    e.stopPropagation();
  };
  addRule = ({ key }, e) => {
    e.domEvent.stopPropagation();
    if (key === 0) {
      this.props.addRule && this.props.addRule({ index: key });
    } else {
      this.props.addRule && this.props.addRule({});
    }
  };
  addLoopRule = e => {
    this.props.addRule && this.props.addRule({ index: 0, loopRule: true });
    e.stopPropagation();
  };

  // 设置remark
  changeRemark = value => {
    return new Promise((resolve, reject) => {
      this.props.changeRemark && this.props.changeRemark(value);
      resolve();
    });
  };

  render() {
    const { openStatus } = this.state;
    const { isNeedSave } = this.props;
    const menu = (
      <Menu>
        <Menu.Item onClick={this.addRule.bind(this, { key: 0 })}>
          <span>前置添加</span>
        </Menu.Item>
        <Menu.Item onClick={this.addRule.bind(this, { key: 1 })}>
          <span>后置添加</span>
        </Menu.Item>
      </Menu>
    );
    const genExtra = isActive =>
      openStatus === true ? (
        <span onClick={this.toggleRules.bind(this, true)}>折叠规则</span>
      ) : (
        <span onClick={this.toggleRules.bind(this, false)}>打开规则</span>
      );
    return (
      <div className="tool-area">
        <div className="btn-area">
          <Dropdown overlay={menu} placement="bottomLeft" onClick={this.add}>
            <Button type="primary">
              <Icon type="plus" />
              添加规则
            </Button>
          </Dropdown>
          <Button type="primary" onClick={this.addLoopRule}>
            <Icon type="plus-circle" />
            添加循环规则
          </Button>
          <Button type="primary" onClick={this.sort}>
            <Icon type="ordered-list" />
            规则排序
          </Button>
          <Button type="primary" onClick={this.loadFromTemplates}>
            <Icon type="import" />
            从模板导入
          </Button>
          <Button type="primary" onClick={this.save} disabled={!isNeedSave}>
            <Icon type="save" />
            {isNeedSave && '*'}保存新版本
          </Button>
        </div>
        <div className="remark-area">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          >
            <Panel header={<span className="">备注：</span>} key="1" extra={genExtra()}>
              <Remark value={this.props.remark} callback={this.changeRemark} />
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isNeedSave: state.rule.isNeedSave
  };
}

export default RuleTool;
