import request from '../utils/request';
import storage from '../utils/storage';

export default class Services {
  constructor(url) {
    this.requestHeader = {
      'Content-Type': 'application/json'
    };
    this.url = url;
  }

  // get
  get(api, { params, withAuth, headers, isLoading, errorCatch, errorCode, successCode, warningCode }) {
    const newRequestHeader = withAuth
      ? Object.assign({}, this.requestHeader, headers, { token: storage.token })
      : Object.assign({}, this.requestHeader, headers);
    const newUrl = params && Object.keys(params).length > 0 ? this.getParamsUrl(api, params) : api;
    return request(
      this.url + newUrl,
      {
        method: 'GET',
        headers: newRequestHeader
      },
      { isLoading, errorCatch, errorCode, successCode, warningCode }
    );
  }

  // post
  post(api, { params, withAuth, headers, isFormdata, isLoading, errorCatch, errorCode, successCode, warningCode }) {
    const newRequestHeader = withAuth
      ? Object.assign({}, this.requestHeader, headers, { token: storage.token })
      : Object.assign({}, this.requestHeader, headers);
    const newheaders = isFormdata
      ? Object.assign({}, newRequestHeader, {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      : Object.assign({}, newRequestHeader);
    return request(
      this.url + api,
      {
        method: 'POST',
        headers: newheaders,
        body: isFormdata ? this.parseParams(params) : JSON.stringify(params)
      },
      { isLoading, errorCatch, errorCode, successCode, warningCode }
    );
  }

  // 编辑
  put(api, { params, withAuth, headers, isLoading, errorCatch, errorCode, successCode, warningCode }) {
    const newRequestHeader = withAuth
      ? Object.assign({}, this.requestHeader, headers, { token: storage.token })
      : Object.assign({}, this.requestHeader, headers);
    return request(
      this.url + api,
      {
        method: 'PUT',
        headers: newRequestHeader,
        body: JSON.stringify(params)
      },
      { isLoading, errorCatch, errorCode, successCode, warningCode }
    );
  }

  // 删除
  del(api, { withAuth, headers, isLoading, errorCatch, errorCode, successCode, warningCode }) {
    const newRequestHeader = withAuth
      ? Object.assign({}, this.requestHeader, headers, { token: storage.token })
      : Object.assign({}, this.requestHeader, headers);
    return request(
      this.url + api,
      {
        method: 'DELETE',
        headers: newRequestHeader
      },
      { isLoading, errorCatch, errorCode, successCode, warningCode }
    );
  }

  // upload
  upload(api, { params, isLoading, errorCode, successCode, warningCode }) {
    return request(
      this.url + api,
      {
        method: 'POST',
        headers: {},
        body: params
      },
      { isLoading, errorCode, successCode, warningCode }
    );
  }

  // 处理get 参数
  getParamsUrl(url, params) {
    if (Object.keys(params).length !== 0) {
      let str = '';
      for (const key in params) {
        str = str !== '' ? `${str}&${key}=${params[key]}` : `${key}=${params[key]}`;
      }
      return `${url}?${str}`;
    } else {
      return url;
    }
  }

  // form data post body
  parseParams(params) {
    let newParams = '';
    const keys = Object.keys(params);
    if (keys.length === 0) {
      return '';
    }
    keys.forEach((key, index) => {
      newParams =
        index === 0
          ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          : `${newParams}&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    });
    return newParams;
  }
}
