import ConditionsToXml from './ConditionsToXml';
/**  JoinConditionToXml
 *   联合条件转xml
 *   @data：联合条件对象
 */
export default class JoinConditionToXml {
  constructor(data) {
    this.data = data;
  }

  toXml() {
    const conditionsXml = new ConditionsToXml(this.data.criterions).toXml();
    const junctionCategoryXml = this.data.junctionCategory ? `junctionCategory="${this.data.junctionCategory}"` : '';
    switch (this.data.junctionType) {
      case 'or':
        return `<or ${junctionCategoryXml}>${conditionsXml}</or>`;
      case 'and':
        return `<and ${junctionCategoryXml}>${conditionsXml}</and>`;
      default:
        break;
    }
  }
}
