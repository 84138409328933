import { lazy } from 'react';
import editorRouter from './routers/Editor/index';

const routerConfig = [
  {
    path: '/',
    exact: true,
    auth: true,
    component: lazy(() => import('./routers/Index'))
  },
  ...editorRouter,
  {
    path: '/login.html',
    redirect: '/login',
    auth: false
  },
  {
    path: '/login',
    component: lazy(() => import('./routers/Login')),
    exact: false,
    auth: false
  },
  {
    path: '/callback.html',
    component: lazy(() => import('./routers/Callback')),
    exact: false,
    auth: false
  },
  {
    path: '/oa/callback.html',
    component: lazy(() => import('./routers/Callback/oaCallback')),
    exact: false,
    auth: false
  },
  {
    path: '/jump.html',
    component: lazy(() => import('./routers/Jump')),
    exact: false,
    auth: false
  },
  {
    path: '/404',
    component: lazy(() => import('./routers/NotFound')),
    exact: false,
    auth: false
  },
  {
    path: '/projectOnline',
    component: lazy(() => import('./routers/ProjectOnline')),
    exact: false,
    auth: true
  },
  {
    path: '/variable',
    component: lazy(() => import('./routers/Variable')),
    exact: false,
    auth: true
  },
  {
    path: '/search',
    component: lazy(() => import('./routers/Search/index')),
    exact: false,
    auth: true
  },
  {
    path: '/userGroup',
    component: lazy(() => import('./routers/UserGroup')),
    exact: false,
    auth: true
  },
  {
    path: '/userManage',
    component: lazy(() => import('./routers/User')),
    exact: false,
    auth: true
  },
  {
    path: '/version',
    component: lazy(() => import('./routers/Version'))
  },
  {
    path: '/operationLog',
    component: lazy(() => import('./routers/OperationLog/index')),
    exact: false,
    auth: true
  }
];
export default routerConfig;
