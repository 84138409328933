import { Button, Message } from 'antd';
import React from 'react';
import ActionArea from './ActionArea';
import * as services from '../../../services/api/index';
import { randomKey } from '../../../utils/utils';

class Then extends ActionArea {
  constructor(props) {
    super(props);
    this.areaTitle = '那么';
    this.action = null;
  }

  getDefaultActions = async () => {
    let isThenNull = false;
    let isElseNull = false;
    const projectName = this.props.projectName;
    const res = await services.common.getDefaultActions({ projectName: projectName });
    if (res.code !== 0) {
      return;
    }
    const data = res.data;
    if (data && data.rhs && data.rhs.actions && data.rhs.actions.length > 0) {
      const rhs = data.rhs.actions.map(item => {
        item.id = randomKey();
        return item;
      });
      this.action.addAction({ actionsForAdd: rhs });
    } else {
      isThenNull = true;
    }
    if (data && data.other && data.other.actions && data.other.actions.length > 0) {
      const other = data.other.actions.map(item => {
        item.id = randomKey();
        return item;
      });
      this.props.setElse({ actionsForAdd: other });
    } else {
      isElseNull = true;
    }
    if (isThenNull && isElseNull) {
      Message.warning('暂无默认动作，请到项目配置中进行配置');
    }
  };

  getButtons = () => {
    return (
      <>
        {!this.props.disabled && (
          <>
            <Button className="btn-small lightblue" type="primary" onClick={this.addAction}>
              添加动作
            </Button>
            {!this.props.noAutoCreate && (
              <Button className="btn-small" onClick={this.getDefaultActions}>
                自动生成
              </Button>
            )}
          </>
        )}
      </>
    );
  };
}

export default Then;
