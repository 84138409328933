/**
 * MethodValue 方法选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import ConditionValue from '../ConditionValue';
import { ruleObj } from '../../../../utils/rule';

class MethodValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getMenus = () => {
    const { methods } = ruleObj.file;
    return this.getMethodsMenus(methods);
  };
  choose = value => {
    const { data, isValue } = this.props;
    this.setMethodsValue(data, value, isValue);
  };
  renderValueText = data => {
    const { parameters } = data;
    return (
      <>
        {parameters.length > 0 ? this.mapParameters(parameters) : <span style={{ color: '#999' }}>无参数</span>}
        <span style={{ color: '#70196b' }}>)</span>]
      </>
    );
  };
  mapParameters = parameters => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return parameters.map(item => {
      return (
        <span key={item.name}>
          <span style={{ color: '#333333' }}>{item.name}:</span>
          <ConditionValue item={item} {...props} />;
        </span>
      );
    });
  };
  getPopText = () => {
    const { data, isValue } = this.props;
    return (
      <span style={{ color: 'darkcyan', cursor: 'pointer' }}>
        {!isValue ? (
          data.leftPart ? (
            <span className="selector-text">
              [<span style={{ color: '#70196b' }}>{data.leftPart.methodLabel}(</span>
            </span>
          ) : (
            '请选择方法'
          )
        ) : Object.keys(data).length !== 0 ? (
          <span className="selector-text">
            [<span style={{ color: '#70196b' }}>{data.methodLabel}(</span>
          </span>
        ) : (
          '请选择值'
        )}
      </span>
    );
  };
  getValue = () => {
    const { data, isValue } = this.props;
    return <>{!isValue ? this.renderValueText(data.leftPart) : this.renderValueText(data)}</>;
  };
}
export default MethodValue;
