import React, { Component } from 'react';
import { Input, Switch, Row, Col, Select, DatePicker, Tooltip } from 'antd';
import './ruleProperty.less';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';
const { Option } = Select;
moment.locale('zh-cn');

class RuleProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logic: '',
      showInput: false,
      groupValue: ''
    };
  }
  componentDidMount() {
    this.propsToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.propsToState(props);
  }

  propsToState = props => {
    const { activationGroup, agendaGroup } = props.rule || {};
    if (activationGroup === undefined && agendaGroup === undefined) {
      this.setState({ showInput: false });
    } else {
      this.setState({ showInput: true, groupValue: activationGroup || agendaGroup || '' });
    }
  };

  getLogic = () => {
    const { activationGroup, agendaGroup } = this.props.rule || {};
    if (activationGroup !== undefined) {
      return 'activationGroup';
    } else if (agendaGroup !== undefined) {
      return 'agendaGroup';
    } else {
      return 'default';
    }
  };

  onInputChange = (key, e) => {
    this.props.onChangeProperty && this.props.onChangeProperty(key, e.target.value);
  };
  onSwitchChange = (key, value) => {
    this.props.onChangeProperty && this.props.onChangeProperty(key, value);
  };
  onLogicChange = value => {
    if (value === 'default') {
      this.props.onRemoveProperty && this.props.onRemoveProperty('agendaGroup');
      this.props.onRemoveProperty && this.props.onRemoveProperty('activationGroup');
    } else if (value === 'agendaGroup') {
      this.props.onRemoveProperty && this.props.onRemoveProperty('activationGroup');
      this.props.onChangeProperty && this.props.onChangeProperty('agendaGroup', this.state.groupValue);
    } else if (value === 'activationGroup') {
      this.props.onRemoveProperty && this.props.onRemoveProperty('agendaGroup');
      this.props.onChangeProperty && this.props.onChangeProperty('activationGroup', this.state.groupValue);
    }
  };
  changeGroupValue = e => {
    const value = e.target.value;
    this.setState({ groupValue: value }, () => {
      this.onLogicChange(this.getLogic(value));
    });
  };
  onChangeDate = (key, date, dateString) => {
    this.props.onChangeProperty && this.props.onChangeProperty(key, dateString);
  };
  render() {
    const { effectiveDate, expiresDate, debug, enabled, loop, salience } = this.props.rule || {};
    const { showInput, groupValue } = this.state;
    const logic = this.getLogic();
    const span = this.props.rule.loopRule
      ? {
          col1: 5,
          col2: 10,
          col3: 9
        }
      : {
          col1: 4,
          col2: 8,
          col3: 8,
          col4: 4
        };
    return (
      <>
        <Row type="flex">
          <Col className="gutter-row prop-item" span={span.col1}>
            <span className="prop-label prop-label-small ">是否启用</span>
            <Switch
              checkedChildren="是"
              onChange={this.onSwitchChange.bind(this, 'enabled')}
              unCheckedChildren="否"
              checked={enabled}
            />
          </Col>
          <Col className="gutter-row prop-item" span={span.col2}>
            <span className="prop-label">执行优先级</span>
            <span className="prop-input">
              <Input
                type="number"
                value={salience}
                onChange={this.onInputChange.bind(this, 'salience')}
                style={{ minWidth: '120px' }}
              />
            </span>
          </Col>
          <Col className="gutter-row prop-item" span={span.col3}>
            <span className="prop-label">生效时间</span>
            <span className="prop-input">
              <DatePicker
                locale={locale}
                showTime
                placeholder="Select Time"
                defaultValue={effectiveDate && moment(effectiveDate, 'YYYY-MM-DD hh:mm:ss')}
                onChange={this.onChangeDate.bind(this, 'effectiveDate')}
                style={{ width: '120px' }}
              />
            </span>
          </Col>
          {!this.props.rule.loopRule && (
            <Col className="gutter-row prop-item" span={span.col4}>
              <span className="prop-label prop-label-small">允许循环触发</span>
              <Switch
                checkedChildren="是"
                onChange={this.onSwitchChange.bind(this, 'loop')}
                unCheckedChildren="否"
                checked={loop}
              />
            </Col>
          )}
        </Row>
        <Row type="flex">
          <Col className="gutter-row prop-item" span={span.col1}>
            <span className="prop-label">允许调试信息输出</span>
            <Switch
              checkedChildren="是"
              onChange={this.onSwitchChange.bind(this, 'debug')}
              unCheckedChildren="否"
              checked={debug}
            />
          </Col>

          <Col className="gutter-row prop-item" span={span.col2}>
            <span className="prop-label">执行逻辑</span>
            <span className="prop-input flex-cont">
              <Select value={logic} className="prop-select" onChange={this.onLogicChange}>
                <Option value="default">
                  <Tooltip placement="right" title="默认按优先级顺序执行普通组内所有规则">
                    <span style={{ display: 'block' }}>普通组</span>
                  </Tooltip>
                </Option>
                <Option value="agendaGroup">
                  <Tooltip placement="right" title="同为执行组的规则文件每个都会被执行">
                    <span style={{ display: 'block' }}>执行组</span>
                  </Tooltip>
                </Option>
                <Option value="activationGroup">
                  <Tooltip placement="right" title="同为互斥组的规则文件命中其中一个就不会再执行同组其他">
                    <span style={{ display: 'block' }}>互斥组</span>
                  </Tooltip>
                </Option>
              </Select>
              <Input
                value={groupValue}
                className="prop-value"
                style={{ display: showInput ? 'block' : 'none' }}
                onChange={this.changeGroupValue}
              />
            </span>
          </Col>
          <Col className="gutter-row prop-item" span={span.col3}>
            <span className="prop-label">失效时间</span>
            <span className="prop-input">
              <DatePicker
                locale={locale}
                showTime
                placeholder="Select Time"
                defaultValue={expiresDate && moment(expiresDate, 'YYYY-MM-DD hh:mm:ss')}
                onChange={this.onChangeDate.bind(this, 'expiresDate')}
                style={{ width: '120px' }}
              />
            </span>
          </Col>
        </Row>
      </>
    );
  }
}
export default RuleProperty;
