/**
 * ParameterValue 参数选择设置组件
 *
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import { ruleObj } from '../../../../utils/rule';

class ParameterValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {
      isFilter: true
    };
  }
  getMenus = () => {
    const { params } = ruleObj.file;
    return this.getParameterMenus(params[0] || []);
  };
  choose = value => {
    const { data, isValue, isAssignLeft } = this.props;
    this.setParameterValue(data, value, isValue, isAssignLeft);
  };
  renderValueText = data => {
    const { variableLabel } = data;
    return `参数.${variableLabel}`;
  };
  getPopText = () => {
    const { data, isValue } = this.props;
    return (
      <span style={{ color: '#002b8d', cursor: 'pointer' }}>
        {!isValue
          ? data.leftPart
            ? this.renderValueText(data.leftPart)
            : '请选择参数'
          : Object.keys(data).length !== 0
          ? this.renderValueText(data)
          : '请选择值'}
      </span>
    );
  };
}
export default ParameterValue;
