import React, { Component } from 'react';
import ConditionType from './ConditionType';
import ConditionValue from './ConditionValue';
import ComparisonOperator from './ComparisonOperator';

class ConditionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { item } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      // file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <span>
        <ConditionType item={item} {...props} />
        <ComparisonOperator item={item} {...props} />
        <ConditionValue item={item} {...props} />
      </span>
    );
  }
}

export default ConditionInfo;
