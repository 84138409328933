import React, { Component } from 'react';
import PopMenu from '../../../../components/PopMenu';
import Menus from '../../../../components/PopMenu/Menus';

import './index.less';
class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      placement: 'rightBottom'
    };
  }
  componentDidMount() {}

  getMenus = () => {
    return [];
  };
  getPopText = () => {
    return '';
  };
  getValue = () => {
    return '';
  };
  // 动态设置菜单的arraw位置
  handleClick = e => {
    const { isFilter } = this.state;
    const { disabled } = this.props;

    const menus = this.getMenus();
    if (disabled) {
      return;
    }
    PopMenu.open(
      {
        content: <Menus menus={menus} isFilter={isFilter} />
      },
      e
    );
  };
  render() {
    const val = this.getValue();
    const selectorClass = this.isDisabledSelector ? '' : 'selector';
    return (
      <span className={selectorClass}>
        <span onClick={this.handleClick}>{this.getPopText()}</span>
        {val && <span>{val}</span>}
      </span>
    );
  }
}

export default Selector;
