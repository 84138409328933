import { lazy } from 'react';
import ruleTemplateRouter from './RuleTemplate/index';
const editorRouter = [
  {
    path: '/editor/rule/:projectName',
    name: '决策集',
    exact: false,
    auth: true,
    component: lazy(() => import('./Rule/index'))
  },
  {
    path: '/editor/defaultActionsProject',
    name: '默认动作配置',
    exact: false,
    auth: true,
    component: lazy(() => import('./DefaultAction/DefaultActionProject'))
  },
  ...ruleTemplateRouter
];

export default editorRouter;
