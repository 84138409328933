import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import * as services from '../../services/api/index';
import { setEditorPath, getProjectName } from '../../utils/tree';
import './index.less';
class FileReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    if (this.props.data && this.props.data.length > 0) {
      this.setState({
        data: this.props.data
      });
    } else {
      this.getList(decodeURI(this.props.file));
    }
  }
  getList = filePath => {
    services.common.reference({ filePath }).then(res => {
      if (res.code !== 0) {
        return;
      }
      this.setState({
        data: res.data
      });
    });
  };
  open = item => {
    const fullPath = encodeURI(item.fullPath);
    const projectName = getProjectName(item.fullPath);
    const obj = { type: item.type };
    setEditorPath(obj);
    const url = obj.editorPath === '/editor/rule' ? `${obj.editorPath}/${item.project}` : obj.editorPath;
    const tabItem = {
      title: `${item.fullPath}-${projectName}`,
      id: item.fullPath,
      url: `${url}?file=${fullPath}`,
      type: item.type
    };
    this.props.event.eventEmitter.emit(this.props.event.SHOW_TAB, tabItem);
    if (this.props.openCallback) {
      this.props.openCallback();
    }
  };
  render() {
    const { data } = this.state;
    return (
      <div className="file-reference">
        <h4>{this.props.title}</h4>
        {data.length > 0 && (
          <ul className="f-list">
            {data.map(item => {
              if (item.type !== 'resourcePackage') {
                return (
                  <li className="f-item" key={item.fullPath}>
                    <div className="f-l">
                      <div className="f-l-c">
                        <Tooltip title={item.fullPath}>{item.fullPath}</Tooltip>
                      </div>
                    </div>
                    <div className="f-r" onClick={this.open.bind(this, item)}>
                      <Icon type="folder-open" />
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="f-item" key={item.fileId}>
                    <div className="f-l">
                      <div className="f-l-half f-l-half-l">
                        <Tooltip title={item.fileId}>{item.fileId}</Tooltip>
                      </div>
                      <div className="f-l-half f-l-half-r">
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </div>
                    </div>
                    <div className="f-r disabled">
                      <Icon type="inbox" />
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default FileReference;
