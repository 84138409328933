import React, { Component } from 'react';
import InputText from '../../../components/InputText';
import If from './If';
import Then from './Then';
import Else from './Else';
import { IconFont } from '../../../components/IconFont/index';
import { Divider, Tooltip } from 'antd';
import { ruleObj } from '../../../utils/rule';

class Unit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  updateRule = () => {
    this.props.updateRule();
  };
  setChildToParent = el => {
    this.else = el;
  };
  setElse = data => {
    this.else.action.addAction(data);
  };
  // 设置Unit名
  setUnitName = value => {
    const { data } = this.props;
    return new Promise((resolve, reject) => {
      if (value && !this.compareUnitNameIsRepeat(value)) {
        data.name = value;
        this.updateRule();
        resolve();
      } else {
        reject();
      }
    });
  };

  // 对比是否有规则名重复,true->有重复，false->无重复
  compareUnitNameIsRepeat = value => {
    return this.props.units.some(it => it.name === value && it.id !== this.props.id);
  };

  func = ({ id, index, unit }, type, e) => {
    this.props[type]({ id, index: index + 1, unit });
  };

  // 选中Unit
  selectThis = (e, multiple) => {
    const { id, data, rule, ruleId } = this.props;
    const { selectedUnits, selectedRules, keyPressForCtrl } = ruleObj;
    let newSelectedRules = [];
    let newSelectedUnits = [];
    // 如果是多选，按住ctrl/command
    if (multiple || keyPressForCtrl) {
      newSelectedRules = selectedRules.some(it => it.id === ruleId) ? selectedRules : selectedRules.concat(rule);
      newSelectedUnits = selectedUnits.some(it => it.id === id)
        ? selectedUnits.filter(it => it.id !== id)
        : selectedUnits.concat([data]);
      // 单选
    } else {
      newSelectedRules = [rule];
      newSelectedUnits = [data];
    }
    // 把选中的存储全局变量ruleObj
    ruleObj.selectedActionAreas = [];
    ruleObj.selectedActions = [];
    ruleObj.selectedConditions = [];
    ruleObj.selectedRules = newSelectedRules;
    ruleObj.selectedUnits = newSelectedUnits;

    // 控制样式，这样避免react多次render对比
    document.querySelectorAll('.unit').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedUnits.some(it => it.id === id)) {
        item.className = 'unit active';
      } else {
        item.className = 'unit ';
      }
    });

    // pannel
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });

    // condition
    document.querySelectorAll('.condition-item').forEach(item => {
      item.style.background = '';
    });

    // action
    document.querySelectorAll('.action-item').forEach(item => {
      item.style.background = '';
    });

    // actions-area
    document.querySelectorAll('.actions-area').forEach(item => {
      item.style.background = '';
    });

    e.stopPropagation();
  };

  // 复制
  copy = e => {
    const { data } = this.props;
    this.props.copyUnits([data]);
  };

  render() {
    const { data, index, id } = this.props;
    const { name, lhs, rhs, other } = data || {};
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    const ruleOps = (
      <div className='unit-operate'>
        <span onClick={this.copy}>
          <Tooltip title='复制'>
            <IconFont type='iconfuzhi1' className='rule-icon' />
          </Tooltip>
        </span>
        <span onClick={this.func.bind(this, { unit: data, index: index }, 'copyAdd')}>
          <Tooltip title='复制并新增'>
            <IconFont type='iconfuzhi' className='rule-icon' />
          </Tooltip>
        </span>
        <span onClick={this.props.delUnitConfirm.bind(this, { id: id })}>
          <Tooltip title='删除'>
            <IconFont type='iconweb-icon-' className='rule-icon' />
          </Tooltip>
        </span>
      </div>
    );
    return (
      <div className='unit' id={id} onClick={this.selectThis}>
        {ruleOps}
        名称:
        <InputText callback={this.setUnitName} value={name} color='#333' width={300} />
        <If data={lhs.criterion} {...props} title='条件' unit={data} />
        <Divider style={{ margin: '0' }} />
        <Then
          data={rhs}
          {...props}
          currentId={rhs.id}
          projectName={this.props.projectName}
          setElse={this.setElse}
          unit={data}
        />
        <Divider style={{ margin: '0' }} />
        <Else
          data={other}
          {...props}
          currentId={other.id}
          projectName={this.props.projectName}
          setChildToParent={this.setChildToParent}
          unit={data}
        />
      </div>
    );
  }
}

export default Unit;
