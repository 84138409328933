import React from 'react';
import ConditionSelector from './Selector/ConditionSelector';
import ConditionValue from './ConditionValue';
import MethodValue from './Common/MethodValue';
import FunctionValue from './Common/FunctionValue';
import VariableAssign from './Common/VariableAssign';
import { ruleObj } from '../../../utils/rule';
class ActionType extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  getMenus = () => {
    const { methods } = ruleObj.file;
    const actionMenus = [
      {
        name: '打印内容到控制台',
        type: 'ConsolePrint'
      },
      {
        name: '变量赋值',
        type: 'VariableAssign'
      },
      {
        name: '执行函数',
        type: 'ExecuteCommonFunction'
      }
    ];
    const menus = [...actionMenus, ...methods];
    return this.getActionsMenus(menus);
  };
  choose = value => {
    const { data, isValue } = this.props;
    this.setActionsValue(data, value, isValue);
  };
  renderInputType = data => {
    const type = data.actionType;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    switch (type) {
      case 'ExecuteMethod':
        this.isDisabledSelector = true;
        return <MethodValue data={data} isValue={true} {...props} />;
      case 'ConsolePrint':
        this.isDisabledSelector = false;
        return <ConditionValue item={data} {...props} />;
      case 'ExecuteCommonFunction':
        this.isDisabledSelector = false;
        return <FunctionValue data={data} isValue={true} {...props} />;
      case 'VariableAssign':
        this.isDisabledSelector = false;
        return <VariableAssign data={data} {...props} />;
      default:
        this.isDisabledSelector = false;
        break;
    }
  };
  getActionText = data => {
    const type = data.actionType;
    switch (type) {
      case 'ExecuteMethod':
        return '执行方法';
      case 'ConsolePrint':
        return '输出';
      case 'ExecuteCommonFunction':
        return '执行函数';
      case 'VariableAssign':
        return '变量赋值';
      default:
        break;
    }
  };
  getPopText = () => {
    const { data } = this.props;
    const { id, ...exceptId } = data;
    return (
      <span style={{ color: 'rgb(0, 128, 0)', cursor: 'pointer', marginRight: '5px' }}>
        {Object.keys(exceptId).length !== 0 ? (
          <span className="selector-text">{this.getActionText(data)}:</span>
        ) : (
          '请选择动作类型:'
        )}
      </span>
    );
  };
  getValue = () => {
    const { data } = this.props;
    return <>{this.renderInputType(data)}</>;
  };
}
export default ActionType;
