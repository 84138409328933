import React, { Component } from 'react';
// import { Raphael, Paper, Set, Circle, Ellipse, Image, Rect, Text, Path, Line } from 'react-raphael';

import ReactDOM from 'react-dom';
import Raphael from 'raphael';
class PaperCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.paths = [];
    this.renderIndex = 0;
  }
  componentDidMount() {
    this.paper = new Raphael(ReactDOM.findDOMNode(this), '100%', '100%');
    this.paths.forEach(item => {
      this.drawPath(item);
    });
  }

  getPath = ({ startX, endX, startY, endY }) => {
    var left = 0;
    var top = 8;
    const path =
      'M' +
      (startX + left) +
      ',' +
      (startY + top) +
      ' C' +
      (startX + left) +
      ',' +
      (endY + top) +
      ',' +
      (startX + left) +
      ',' +
      (endY + top) +
      ',' +
      (endX + left) +
      ',' +
      (endY + top);
    this.paths.push(path);
  };
  drawPath = item => {
    if (this.paper) {
      const path = this.paper.path(item);
      path.attr({ stroke: '#777' });
    }
  };
  componentWillUnmount() {
    this.paper.clear();
    this.paper = null;
  }
  /**
   * render 条件的方法
   * @type: 或者、并且
   * @criterion: 条件对象
   * @criterionParent:
   * @pos: 位置信息
   * @isFirst: 是否为第一个联合节点，true 则不渲染删除功能
   * @index: 索引
   */
  renderCondition({ criterion, pos }) {
    this.getPath(pos);
    criterion &&
    criterion.criterions &&
      criterion.criterions.length > 0 &&
      criterion.criterions.forEach((item, index) => {
        this.renderIndex = index === 0 ? (this.renderIndex - 1 < 0 ? 0 : this.renderIndex - 1) : this.renderIndex;
        const position = {
          startX: pos.endX + 50,
          endX: pos.endX + 80,
          startY: pos.endY + 12,
          endY: 30 * this.renderIndex
        };
        this.renderIndex++;
        if (item.criterions && item.criterions instanceof Array) {
          this.renderCondition({
            criterion: item,
            pos: position
          });
        } else {
          this.getPath(position);
        }
      });
  }
  render() {
    const position = {
      startX: 10,
      endX: 10,
      startY: 0,
      endY: 0
    };
    const criterion = this.props.data;
    this.renderCondition({ criterion, pos: position });
    return <div style={{ width: '100%', height: '100%' }}></div>;
  }
}

export default PaperCondition;
