import projectTree from './projectTree';
import systemTree from './systemTree';
import tabs from './tabs';
import global from './global';
import variable from './variable';
import rule from './rule';
import file from './file';
import selectedPath from './selectedPath';

export default {
  projectTree,
  systemTree,
  tabs,
  global,
  variable,
  rule,
  file,
  selectedPath
};
