import React, { Component } from 'react';
import VariableValue from './Common/VariableValue';

class LoopTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { data } = this.props;
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <div className="con-container">
        <div className="con-text">
          <strong>循环类型</strong>
        </div>

        <div className="condition-area">
          <VariableValue data={data.value} isValue={true} {...props} />
        </div>
      </div>
    );
  }
}

export default LoopTarget;
