/**
 * ConstantsValue 常量选择设置组件
 *   */
import React from 'react';
import ConditionSelector from '../Selector/ConditionSelector';
import { ruleObj } from '../../../../utils/rule';
class ConstantsValue extends ConditionSelector {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getMenus = () => {
    const { constants } = ruleObj.file;
    return this.getConstantsMenus(constants);
  };
  choose = value => {
    const { data, isValue } = this.props;
    this.setConstantsValue(data, value, isValue);
  };
  renderValueText = data => {
    const { constantCategoryLabel, constantLabel } = data;
    return `${constantCategoryLabel}.${constantLabel}`;
  };

  getPopText = () => {
    const { data, isValue } = this.props;
    return (
      <span style={{ color: 'rgb(1, 116, 223)', cursor: 'pointer' }}>
        {!isValue
          ? data.leftPart
            ? this.renderValueText(data.leftPart)
            : '请选择常量'
          : Object.keys(data).length !== 0
          ? this.renderValueText(data)
          : '请选择值'}
      </span>
    );
  };
}
export default ConstantsValue;
