import { Button } from 'antd';
import React from 'react';
import ActionArea from './ActionArea';

class LoopStart extends ActionArea {
  constructor(props) {
    super(props);
    this.areaTitle = '开始前动作';
  }
  componentDidMount() {}

  getButtons = () => {
    return (
      <>
        <Button className="btn-small lightblue" type="primary" onClick={this.addAction}>
          添加动作
        </Button>
      </>
    );
  };
}

export default LoopStart;
