/**  CommonToXml
 *   通用数据转xml
 *   @data：条件构成的数组
 */
import { escapeXml } from '../../../utils/utils';

export default class CommonToXml {
  constructor() {}

  valueToXml(value) {
    if (!value.valueType) {
      throw '请选择值';
    }
    let valueXml = ``;
    switch (value.valueType) {
      case 'Variable':
      case 'VariableCategory':
        valueXml = this.variableValueToXml(value, value.valueType);
        break;
      case 'Input':
        valueXml = this.inputValueToXml(value, value.valueType);
        break;
      case 'Method':
        // dynamicVariable
        if (value.methodName === 'getRemoteData' || value.methodName === 'getRemoteDataV2') {
          valueXml = this.dynamicVariableValueToXml(value, value.valueType);
        } else {
          valueXml = this.methodsValueToXml(value, value.valueType);
        }
        break;
      case 'Parameter':
        valueXml = this.parameterValueToXml(value, value.valueType);
        break;
      case 'Constant':
        valueXml = this.constantsValueToXml(value, value.valueType);
        break;
      case 'CommonFunction':
        valueXml = this.functionValueToXml(value, value.valueType);
        break;
      case 'Range':
        valueXml = this.rangeToXml(value, value.valueType);
        break;
      default:
        valueXml = '>';
    }
    return `<value ${valueXml}${value.arithmetic ? this.complexArithmeticToXml(value.arithmetic) : ''}</value>`;
  }

  // 变量
  variableValueToXml(data, type) {
    if (!data.variableCategory && !data.variableName) {
      throw '变量不能为空！';
    }
    const variableLabelXml =
      type !== 'VariableCategory' && type !== 'variableCategory'
        ? `var="${data.variableName}" var-label="${data.variableLabel}" datatype="${data.datatype}"`
        : '';
    const newType = type === 'variableCategory' ? 'variable' : type;
    return `var-category="${data.variableCategory}" var-category-label="${data.variableCategoryLabel}" ${variableLabelXml} type="${newType}">`;
  }

  // 输入值
  inputValueToXml(data, type) {
    if (!data.content) {
      throw '输入值不能为空！';
    }
    return `content="${escapeXml(data.content)}" type="${type}">`;
  }

  // 搜索变量
  dynamicVariableValueToXml(data, type) {
    let xml = `bean-name="ApiBuiltInAction" bean-label="Api函数" dynamic-type="true" method-name="getRemoteDataV2" 
    method-label="获取远程指标V2" type="${type}" return-type="${data.returnType}">`;
    data.parameters.forEach(item => {
      if (item.name === 'apiNo' && !item.value.content) {
        throw '变量不能为空！';
      }
      xml += `<parameter name="${item.name}" type="${item.type}"> 
      <value ${item.value.name ? `name="${item.value.name}"` : ''} content="${item.value.content}" type="${
        item.value.valueType
      }"/> 
    </parameter> `;
    });
    return xml;
  }

  // 参数
  parameterValueToXml(data, type) {
    if (!data.variableLabel) {
      throw '参数不能为空！';
    }
    return `var-category="parameter" var-category-label="参数" var="${data.variableName}" var-label="${data.variableLabel}" datatype="${data.datatype}" type="${type}">`;
  }

  // 常量
  constantsValueToXml(data, type) {
    if (!data.constantCategoryLabel) {
      throw '常量不能为空！';
    }
    return `const-category-label="${data.constantCategoryLabel}" const="${data.constantName}" const-label="${data.constantLabel}"  type="${type}">`;
  }

  // 方法
  methodsValueToXml(data, type) {
    if (!data.beanLabel || !data.beanId) {
      throw '执行方法不能为空！';
    }
    let parameterXml = '';
    if (data.parameters && data.parameters.length > 0) {
      data.parameters.forEach(item => {
        parameterXml += `<parameter name="${item.name}" type="${item.type}">${
          item.value ? this.valueToXml(item.value) : ''
        }</parameter>`;
      });
    }
    return `bean-name="${data.beanId}" bean-label="${data.beanLabel}" method-name="${data.methodName}" method-label="${data.methodLabel}" type="${type}" > ${parameterXml}`;
  }

  // 函数
  functionValueToXml(data, type) {
    if (!data.label || !data.name) {
      throw '请选择函数';
    }
    let parameterXml = '';
    if (data.parameter) {
      const propertyXml =
        data.parameter.property === undefined
          ? ''
          : ` property-name="${data.parameter.property}" property-label="${data.parameter.propertyLabel}"`;
      parameterXml += `<function-parameter name="${data.parameter.name}"${propertyXml}>${
        data.value ? this.valueToXml(data.value) : ''
      }</function-parameter>`;
    }
    return `function-label="${data.label}" function-name="${data.name}" type="${type}"> ${parameterXml}`;
  }

  rangeToXml(data, type) {
    let parameterXml = '';
    if (data.parameters && data.parameters.length > 0) {
      data.parameters.forEach(item => {
        parameterXml += `<parameter name="${item.name}" type="${item.type}">${
          item.value ? this.valueToXml(item.value) : ''
        }</parameter>`;
      });
    }
    return `type="${type}" rangeType="${data.rangeType}">${parameterXml}`;
  }

  // 加减乘除等
  complexArithmeticToXml(data) {
    if (!data.value) {
      return;
    }
    return `<complex-arith type="${data.type}">${this.nextTypeToXml(data)}</complex-arith>`;
  }

  nextTypeToXml(data) {
    switch (data.value.valueType) {
      case 'Paren':
        return `<paren>${this.valueToXml(data.value.value)}</paren>`;

      default:
        return this.valueToXml(data.value);
    }
  }
}
