import RulesToXml from './RulesToXml';

/**  RuleToXml
 *   整个rule 转xml
 */
export default class RuleToXml {
  constructor(remark, rules) {
    this.rules = rules;
    this.remark = remark;
  }
  toXml() {
    const rulesToXml = new RulesToXml(this.rules);
    const remarkXml = rulesToXml.remarkToXml(this.remark || '');
    const rulesXml = rulesToXml.toXml();
    return `<?xml version="1.0" encoding="UTF-8"?><rule-set>${remarkXml}${rulesXml}</rule-set>`;
  }
}
