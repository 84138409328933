import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import storage from '../../../utils/storage';
import { clipboardConstant } from '../../../utils/constant';
class RuleAddBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  addRule = ({ index }) => {
    this.props.addRule && this.props.addRule({ index });
  };
  pasteRule = ({ index }) => {
    this.props.pasteRule && this.props.pasteRule({ index });
  };
  getContextmenu = () => {
    const { index } = this.props;
    const { isPastedAbled } = this.state;
    return (
      <Menu>
        <Menu.Item key="1" disabled={isPastedAbled} onClick={this.pasteRule.bind(this, { index })}>
          粘贴(V)
        </Menu.Item>
      </Menu>
    );
  };
  onHandleVisibleChange = visible => {
    const isPastedAbled = storage.clipboard.type === clipboardConstant.rules ? false : true;
    this.setState({
      isPastedAbled
    });
  };
  render() {
    const { index } = this.props;
    return (
      <Dropdown overlay={this.getContextmenu()} trigger={['contextMenu']} onVisibleChange={this.onHandleVisibleChange}>
        <div className="add-btn" onClick={this.addRule.bind(this, { index })}>
          <span>+ 添加</span>
        </div>
      </Dropdown>
    );
  }
}

export default RuleAddBtn;
