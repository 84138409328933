import services from '../createServices';

export const user = {
  login: params => services.post('/loginDo', { params, errorCatch: true }),
  info: params => services.get('/user/current', { params }),
  logout: params => services.get('/logout', { params }),
  wxlogin: params => services.get('/weixin/auth', { params }),
  callback: params => services.get('/weixin/callback', { params }),
  oaAuth: params => services.get('/oa/auth', { params }),
  oaCallback: params => services.get('/oa/callback', { params }),
  binding: (params, headers) => services.get('/binding', { params, headers }),
  getUrlByEnv: params => services.get('/env/sso/loginUrl', { params }),
  workspaceList: params => services.get('/workspace/list/all', { params }),
  fillReqSpaceCode: params => services.post('/fillReqSpaceCode', { params })
};
