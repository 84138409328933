import React, { Component } from 'react';
import moment from 'moment';
import { Form, Input, Button } from 'antd';
import FormItem from '../FormItem/index';

class VersionComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    };
  }
  componentDidMount() {
    this.autoComment();
  }
  autoComment = () => {
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    this.setState({
      comment: date
    });
  };
  handleSubmit = (e, values) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.saveNow && this.props.saveNow({ comment: values.comment });
      }
    });
  };

  render() {
    const { comment } = this.state;

    const formItems1 = [
      {
        label: {
          className: 'tantuer-form-item'
        },
        name: 'comment',
        options: {
          initialValue: comment,
          rules: [
            {
              max: 200,
              message: '描述最长不超过200'
            }
          ]
        },
        component: <Input />
      }
    ];
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="tantuer-form">
          <div className="pl10">
            <div className="tantuer-field-area">
              {formItems1.map(item => {
                return <FormItem key={item.name} form={this.props.form} item={item} />;
              })}
            </div>
            <div className="submit-ft">
              <Button type="primary" htmlType="submit" className="login-form-button">
                保存
              </Button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default Form.create()(VersionComment);
