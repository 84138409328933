import * as services from '../../services/api/index';
import { buildTreeData, resetNameArr, defaultExpend } from '../../utils/tree';
const initialState = {
  dataTreelists: {},
  dataTree: [], // name组成的数组，重组之后的一维的数组
  filterDataTreelists: {},
  treeLoad: false, // 树加载
  selectedTreeKeys: [],
  expandedTreeKeys: [],
  selected: {}, // 当前被选中的节点对象
  currentSelectedId: '' // 当前被选中的节点id
};
export default {
  name: 'systemTree',
  state: initialState,
  reducers: {
    updateLists: (state, { data }) => {
      buildTreeData(data, { isSystem: true });
      const dataTree = resetNameArr(data, []);
      return {
        ...state,
        dataTreelists: data,
        dataTree,
        filterDataTreelists: data
      };
    },

    updateSelectedTreeKeys: (state, { data }) => {
      return {
        ...state,
        selectedTreeKeys: data
      };
    },
    updateExpandedTreeKeys: (state, { data }) => {
      return {
        ...state,
        expandedTreeKeys: data
      };
    },

    updateSelected: (state, { data }) => {
      return {
        ...state,
        selected: data
      };
    },
    updateCurrentSelectedId: (state, { data }) => {
      return {
        ...state,
        currentSelectedId: data
      };
    },
    updateTreeLoad: (state, { data }) => {
      return {
        ...state,
        treeLoad: data
      };
    }
  },
  effects: {
    queryLists: async ({ params }, { dispatch }) => {
      dispatch({ type: 'systemTree/updateTreeLoad', data: true });
      const res = await services.system.lists(params);
      dispatch({ type: 'systemTree/updateTreeLoad', data: false });
      if (res.code !== 0) {
        return;
      }
      dispatch({ type: 'systemTree/updateLists', data: res.data });
      const ids = defaultExpend(res.data, 2, 0, []);
      dispatch({ type: 'systemTree/updateExpandedTreeKeys', data: ids }); // 展开2层
      return res.data;
    }
  }
};
