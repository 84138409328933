import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import './index.less';
import storage from '../../../../utils/storage';
import { ruleObj } from '../../../../utils/rule';
import { clipboardConstant } from '../../../../utils/constant';
import Action from '../Action';

class ActionArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    // 为了能连续ctrl+v 粘贴，需要获取到更新后的选中selectedActionAreas 的引用
    if (ruleObj.selectedActionAreas.length === 1 && nextProps.currentId === ruleObj.selectedActionAreas[0].id) {
      ruleObj.selectedActionAreas = [nextProps.data];
    }
  }
  setChildToParent = el => {
    this.action = el;
  };
  getAction = () => {
    const props = {
      updateRule: this.props.updateRule,
      file: this.props.file,
      copyActions: this.props.copyActions,
      pasteActions: this.props.pasteActions,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return <Action data={this.props.data} setChildToParent={this.setChildToParent} {...props} unit={this.props.unit} />;
  };
  //
  addAction = () => {
    if (this.props.disabled) {
      return;
    }
    this.action.addAction({});
  };
  pasteActions = () => {
    if (this.props.disabled) {
      return;
    }
    this.action.pasteActions({});
  };
  delAllConfirm = () => {
    if (this.props.disabled) {
      return;
    }
    this.action.delAllConfirm({});
  };
  getContextmenu = () => {
    const { data } = this.props;
    const { isPastedAbled } = this.state;
    const isDelAbled = data.actions && data.actions.length > 0 ? false : true;
    return (
      <Menu>
        <Menu.Item key="1" disabled={isPastedAbled} onClick={this.pasteActions}>
          粘贴(V)
        </Menu.Item>
        <Menu.Item key="2" disabled={isDelAbled} onClick={this.delAllConfirm}>
          删除全部
        </Menu.Item>
      </Menu>
    );
  };
  handleClick = e => {
    const { data } = this.props;
    this.action.selectedThisAction(data, e);
  };
  onHandleVisibleChange = visible => {
    const isPastedAbled = storage.clipboard.type === clipboardConstant.actions ? false : true;
    this.setState({
      isPastedAbled
    });
  };

  render() {
    const contextMenu = this.getContextmenu();
    const content = (
      <div className="actions-area action-area con-container" id={this.props.currentId} onClick={this.handleClick}>
        <div>
          <span className="area-title">{this.areaTitle}</span>
          {this.getButtons()}
        </div>
        <div className="action-area">{this.getAction()}</div>
      </div>
    );
    return (
      <>
        {contextMenu && !this.props.disabled ? (
          <Dropdown
            overlay={this.getContextmenu()}
            trigger={['contextMenu']}
            onVisibleChange={this.onHandleVisibleChange}
          >
            {content}
          </Dropdown>
        ) : (
          content
        )}
      </>
    );
  }
}

export default ActionArea;
