import React, { Component } from 'react';
import { Table, Button, Icon } from 'antd';
import * as services from '../../../services/api/index';
import SearchTool from '../../../components/SearchTool';
import moment from 'moment';

class RuleImportTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      selectedFiles: []
    };
  }
  componentDidMount() {
    this.loadTemplates({ status: 'SUBMITTED' });
  }
  columns = () => {
    return [
      {
        title: '序号',
        key: 'index',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: '规则模板名称',
        dataIndex: 'ruleName',
        key: 'ruleName'
      },
      {
        title: '版本号',
        dataIndex: 'lastVersion',
        key: 'lastVersion'
      },
      {
        title: '上次修改时间',
        dataIndex: 'createTime',
        key: 'createTime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          return moment.utc(a.createTime).valueOf() - moment.utc(b.createTime).valueOf();
        }
      },
      {
        title: '修改人',
        dataIndex: 'createUser',
        key: 'createUser',
        width: 80
      }
    ];
  };
  rowSelection = () => {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        const selectedFiles = selectedRows.map(it => {
          return it.filePath;
        });
        this.setState({
          selectedFiles
        });
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name
      }),
      selectedRowKeys: this.state.selectedFiles
    };
  };
  loadNow = selectedFiles => {
    if (selectedFiles.length > 0) {
      this.props.loadNow && this.props.loadNow(selectedFiles);
    }
  };
  setRuleTemplateName = e => {
    this.setState({
      ruleTemplateName: e.target.value
    });
  };
  loadTemplates = async params => {
    console.log(params)
    const res = await services.ruleTemplate.queryRuleTemplate(params);
    if (res.code === 0) {
      this.setState({
        dataSource: res.data
      });
    }
    return res;
  };
  handleSubmit = value => {
    const params = {};
    params.ruleTemplateName = value;
    this.setState({
      clearAndReload: false
    });
    console.log(params)
    this.loadTemplates({ ...params, status: 'SUBMITTED' });
  };
  resetAndLoad = () => {
    this.setState({
      clearAndReload: true
    });
  };
  render() {
    const { selectedFiles, dataSource, clearAndReload } = this.state;
    return (
      <div className="tantuer">
        <div className="pl10" style={{ padding: '15px' }}>
          <div className="flex-space-between" style={{ paddingBottom: '10px' }}>
            <Button type="primary" className="filter-btn" onClick={this.resetAndLoad}>
              <Icon type="sync" />
              刷新
            </Button>
            <SearchTool
              placeholder="规则模板名称"
              search={this.handleSubmit}
              clearAndReload={clearAndReload}
              width={200}
            />
          </div>

          <Table
            rowKey="filePath"
            className="fixed"
            rowSelection={this.rowSelection()}
            columns={this.columns()}
            dataSource={dataSource}
          />
          <div className="submit-ft submit-ft-1">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={this.loadNow.bind(this, selectedFiles)}
            >
              导入模板
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default RuleImportTemplates;
