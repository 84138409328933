import JoinConditionToXml from './JoinConditionToXml';
import CommonToXml from './CommonToXml';
/**  ConditionsToXml
 *   条件数组转xml
 *   @data：条件构成的数组
 */
export default class ConditionsToXml extends CommonToXml {
  constructor(data) {
    super();
    this.data = data;
  }

  toXml() {
    let conditionsXml = '';
    this.data.forEach(item => {
      // 如果是单纯的条件
      if (!item.junctionType) {
        const leftXml = this.leftToXml(item.left);
        if (!item.op) {
          throw '请选择操作比较符';
        }
        conditionsXml += `<atom op="${item.op}">
          <left ${leftXml} ${item.left.arithmetic ? this.complexArithmeticToXml(item.left.arithmetic) : ''}</left>
          ${item.value ? this.valueToXml(item.value) : ''}
        </atom>`;
        // 如果是联合条件
      } else {
        const joinCondition = new JoinConditionToXml(item);
        conditionsXml += joinCondition.toXml();
      }
    });
    return conditionsXml;
  }

  leftToXml(left) {
    if (!left.type) {
      throw '请选择类型';
    }
    switch (left.type) {
      case 'variable':
      case 'variableCategory':
        return this.variableValueToXml(left.leftPart, left.type);
      case 'method':
        // dynamicVariable
        if (left.leftPart.methodName === 'getRemoteData' || left.leftPart.methodName === 'getRemoteDataV2') {
          return this.dynamicVariableValueToXml(left.leftPart, left.type);
        }
        // method
        return this.methodsValueToXml(left.leftPart, left.type);
      case 'parameter':
        return this.parameterValueToXml(left.leftPart, left.type);
      case 'commonfunction':
        return this.functionValueToXml(left.leftPart, left.type);
      default:
        return '>';
    }
  }
}
