import * as services from '../../services/api/index';
import { ruleObj } from '../../utils/rule';
const initialState = {
  libraries: [],
  nodes: [],
  methods: [], //动作方法
  constants: [], //常量
  variables: [], //变量
  params: [], //参数
  functions: [], //函数
  hasConstants: false,
  hasVariables: false,
  hasParams: false,
  hasFunctions: false,
  hasMethods: false
};
export default {
  name: 'file',
  state: initialState,
  reducers: {
    updateLibraries: (state, { data }) => {
      return {
        ...state,
        libraries: data
      };
    },
    updateFiles: (state, { data }) => {
      return {
        ...state,
        ...data
      };
    },
    updateNodes: (state, { data }) => {
      return {
        ...state,
        nodes: data
      };
    },
    updateMethods: (state, { data }) => {
      return {
        ...state,
        methods: data,
        hasMethods: true
      };
    },
    updateConstants: (state, { data }) => {
      return {
        ...state,
        constants: data,
        hasConstants: true
      };
    },
    updateVariables: (state, { data }) => {
      return {
        ...state,
        variables: data,
        hasVariables: true
      };
    },
    updateParams: (state, { data }) => {
      return {
        ...state,
        params: data,
        hasParams: true
      };
    },
    updateFunctions: (state, { data }) => {
      return {
        ...state,
        functions: data,
        hasFunctions: true
      };
    }
  },
  effects: {
    loadFlowDefinition: async ({ params }, { dispatch }, { state }) => {
      const res = await services.common.loadFlowDefinition(params);
      dispatch({ type: 'file/updateLibraries', data: res.libraries });
      dispatch({ type: 'file/updateNodes', data: res.nodes });
      res.libraries.forEach(item => {
        dispatch({ type: 'file/loadXml', params: { filePath: decodeURI(item.path) }, loadTtype: item.type });
      });
      return res.data;
    },
    loadXml: async ({ params, loadTtype }, { dispatch }, { state }) => {
      const res = await services.common.loadXml(params);
      const data = res.data;
      // return res;
      return new Promise((resolve, reject) => {
        switch (loadTtype) {
          case 'Action':
            const newMethods1 = data[0].springBeans && data[0].springBeans.length ? data[0].springBeans : [];
            const newMethods2 = data[1].springBeans && data[1].springBeans.length ? data[1].springBeans : [];
            const newMethods = newMethods1.concat(newMethods2);
            ruleObj.file.methods = newMethods;
            ruleObj.file.hasMethods = true;
            break;
          case 'Constant':
            const constants = data[0] && data[0].categories;
            ruleObj.file.constants = constants;
            ruleObj.file.hasConstants = true;

            break;
          case 'Variable':
            ruleObj.file.variables = data;
            ruleObj.file.hasVariables = true;

            break;
          case 'Parameter':
            ruleObj.file.params = data;
            ruleObj.file.hasParams = true;

            break;
          default:
            break;
        }
        resolve(ruleObj);
      });
    },
    loadFunctions: async ({ params }, { dispatch }, { state }) => {
      const res = await services.common.loadFunctions(params);
      if (res.code !== 0) {
        return;
      }
      ruleObj.file.functions = res.data;
      ruleObj.file.hasFunctions = true;
      return res.data;
    }
  }
};
