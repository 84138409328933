import React, { Component } from 'react';
import { Icon } from 'antd';
import Modal from '../../../components/Modal';
import ConditionItem from './ConditionItem';
import PopMenu from '../../../components/PopMenu';
import { randomKey } from '../../../utils/utils';
import storage from '../../../utils/storage';
import { ruleObj } from '../../../utils/rule';
import { clipboardConstant } from '../../../utils/constant';
import Menus from '../../../components/PopMenu/Menus';

//添加条件
export function addCondition({ criterion, index, conditions }) {
  return new Promise((resolve, reject) => {
    let items = [];
    const len = criterion.criterions.length;
    const i = index !== undefined ? index : len;
    if (conditions && conditions.length > 0) {
      items = conditions.map(item => {
        return conditionsRandomId(item);
      });
    } else {
      items = [{ id: randomKey(), left: {}, op: '', value: {} }];
    }
    criterion.criterions.splice(i, 0, ...items);
    resolve();
  });
}

// conditionsRandomId
export function conditionsRandomId(data) {
  if (data.criterions && data.criterions.length > 0) {
    const criterions = data.criterions.map(item => {
      return conditionsRandomId(item);
    });
    return { ...data, criterions, id: randomKey() };
  } else {
    return Object.assign({}, data, { id: randomKey() });
  }
}

class Condition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.joinCondition = {
      junctionType: 'or',
      criterions: []
    };
    this.renderIndex = 0;
  }
  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.renderIndex = 0;
  }

  updateRule = () => {
    this.props.updateRule();
  };

  //添加条件
  addCondition = ({ criterion, index, conditions }) => {
    if (this.props.disabled) {
      return;
    }
    addCondition({ criterion, index, conditions }).then(() => {
      this.updateRule();
    });
  };

  // 添加联合条件
  addJoinCondition = criterion => {
    if (this.props.disabled) {
      return;
    }
    criterion.criterions.push({ ...this.joinCondition, id: randomKey() });
    this.updateRule();
  };

  /**  复制联合条件
   * @criterion： 当前联合条件对象
   * @isContain: 是否包含联合条件， true:包含，false:不包含
   */
  copyJoinCondition = (criterion, isContain) => {
    if (this.props.disabled) {
      return;
    }
    if (isContain) {
      this.copyConditions([criterion]);
    } else {
      this.copyConditions(criterion.criterions);
    }
  };

  /** 复制条件
   */
  copyConditions = conditions => {
    this.props.copyConditions(conditions);
  };

  /** 粘贴到联合条件
   *  */
  pasteCondition = criterion => {
    // console.log(criterion);
    this.props.pasteCondition(criterion);
  };

  /** 移动条件
   * moveCondition
   *
   * criterion：条件对象
   * @direction:方向，up->上移，down->下移
   */
  moveCondition = ({ criterion, index, direction }) => {
    if (this.props.disabled) {
      return;
    }
    let i = 0;
    switch (direction) {
      case 'up':
        i = index - 1 <= 0 ? 0 : index - 1;
        break;
      case 'down':
        i = index + 1 >= criterion.criterions.length ? criterion.criterions.length - 1 : index + 1;
        break;
      default:
        break;
    }
    this.sortableChange(criterion.criterions, index, i);
  };

  // 排序条件
  sortableChange = (items, oldIndex, newIndex) => {
    const current = items.splice(oldIndex, 1);
    items.splice(newIndex, 0, current[0]);
    this.updateRule();
  };

  //删除条件
  delCondition = ({ item, criterion, isContain, isFirst }) => {
    if (this.props.disabled) {
      return;
    }
    // 根联合节点
    if (isFirst) {
      criterion.criterions = [];
    } else {
      criterion.criterions = isContain
        ? criterion.criterions.filter(it => {
            return it.id !== item.id;
          })
        : criterion.criterions.map(it => {
            if (it.id === item.id) {
              it.criterions = [];
            }
            return it;
          });
    }
    this.updateRule();
  };

  // 确认删除
  delJoinConditionConfirm = ({ item, criterion, isContain, isFirst }) => {
    if (this.props.disabled) {
      return;
    }
    const tips = isContain
      ? '真的要删除当前联合条件(含)下的所有子条件？'
      : '真的要删除当前联合条件（不含）下的所有子条件？';
    Modal.open({
      title: '提示',
      content: tips,
      maskClosable: false,
      isOkBtn: true,
      isCancelBtn: true,
      ok: () => {
        this.delCondition({ item, criterion, isContain, isFirst });
        Modal.close();
      }
    });
  };

  // 修改添加连接符
  changeCondition = (criterion, value) => {
    if (this.props.disabled) {
      return;
    }
    criterion.junctionType = value;
    this.updateRule();
  };

  getConditionType = junctionType => {
    switch (junctionType) {
      case 'and':
        return '并且';
      case 'or':
        return '或者';
      default:
        return '或者';
    }
  };

  // 选择条件
  selectedThis = (item, e) => {
    if (this.props.disabled) {
      return;
    }
    const { ruleId, rule, unit } = this.props;
    const { selectedConditions, selectedRules, selectedUnits, keyPressForCtrl } = ruleObj;
    let newSelectedConditions = [];
    let newSelectedRules = [];
    let newSelectedUnits = [];

    // 如果是多选，按住ctrl/command
    if (keyPressForCtrl) {
      newSelectedConditions = selectedConditions.some(it => it.id === item.id)
        ? selectedConditions.filter(it => it.id !== item.id)
        : selectedConditions.concat([item]);
      newSelectedRules = selectedRules.some(it => it.id === ruleId) ? selectedRules : selectedRules.concat(rule);

      // 单选
    } else {
      newSelectedConditions = [item];
      newSelectedRules = [rule];
    }

    // 把选中的存储全局变量ruleObj
    ruleObj.selectedConditions = newSelectedConditions;
    ruleObj.selectedRules = newSelectedRules;
    ruleObj.selectedActions = [];
    ruleObj.selectedActionAreas = [];

    // actions-area
    document.querySelectorAll('.actions-area').forEach(item => {
      item.style.background = '';
    });
    // condition
    document.querySelectorAll('.condition-item').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedConditions.some(it => it.id === id)) {
        item.style.background = 'rgb(209, 228, 243)';
      } else {
        item.style.background = '';
      }
    });

    // pannel
    document.querySelectorAll('.rule-pannel').forEach(item => {
      const id = item.getAttribute('id');
      if (newSelectedRules.some(it => it.id === id)) {
        item.className = 'rule-pannel active';
        item.querySelector('.checkbox').className = 'checkbox checked';
      } else {
        item.className = 'rule-pannel ';
        item.querySelector('.checkbox').className = 'checkbox';
      }
    });

    // action
    document.querySelectorAll('.action-item').forEach(item => {
      item.style.background = '';
    });

    // 如果是条件单元
    if (unit) {
      // 如果是多选，按住ctrl/command
      if (keyPressForCtrl) {
        newSelectedUnits = selectedUnits.some(it => it.id === unit.id) ? selectedUnits : selectedUnits.concat(unit);
        //单选
      } else {
        newSelectedUnits = [unit];
      }
      ruleObj.selectedUnits = newSelectedUnits;
      document.querySelectorAll('.unit').forEach(item => {
        const id = item.getAttribute('id');
        if (newSelectedUnits.some(it => it.id === id)) {
          item.className = 'unit active';
        } else {
          item.className = 'unit ';
        }
      });
    } else {
      ruleObj.selectedUnits = [];
      document.querySelectorAll('.unit').forEach(item => {
        item.className = 'unit ';
      });
    }

    e.stopPropagation(); //阻止冒泡
  };

  getNewMenusAndSelectedThis = (item, menus, e) => {
    if (this.props.disabled) {
      return;
    }
    const isPastedAbled = storage.clipboard.type === clipboardConstant.conditions ? false : true;
    if (isPastedAbled !== this.state.isPastedAbled) {
      this.renderIndex = 0;
      this.setState({
        isPastedAbled
      });
    }

    this.selectedThis(item, e);
    PopMenu.open(
      {
        content: <Menus menus={menus} />
      },
      e
    );
  };

  getProps = () => {
    const props = {
      updateRule: this.props.updateRule,
      copyConditions: this.props.copyConditions,
      pasteCondition: this.props.pasteCondition,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return props;
  };

  /**
   * render 条件的方法
   * @type: 或者、并且
   * @criterion: 条件对象
   * @criterionParent:
   * @pos: 位置信息
   * @isFirst: 是否为第一个联合节点，true 则不渲染删除功能
   * @index: 索引
   */
  renderCondition({ type, criterion, criterionParent, pos, isFirst, index }) {
    const joinSelectedClass = 'condition-select condition-item';
    const menus = [
      {
        name: '并且',
        action: this.changeCondition.bind(this, criterion, 'and')
      },
      {
        name: '或者',
        action: this.changeCondition.bind(this, criterion, 'or')
      },
      {
        name: '添加条件',
        action: this.addCondition.bind(this, { criterion })
      },
      {
        name: '添加联合条件',
        action: this.addJoinCondition.bind(this, criterion)
      },
      {
        name: '复制(C)',
        action: this.copyJoinCondition.bind(this, criterion, true)
      },
      {
        name: '复制子条件',
        action: this.copyJoinCondition.bind(this, criterion, false),
        disabled: criterion.criterions && criterion.criterions.length > 0 ? false : true
      },
      {
        name: '上移',
        action: this.moveCondition.bind(this, { criterion: criterionParent, index, direction: 'up' }),
        disabled:
          !isFirst && criterionParent.criterions && criterionParent.criterions.length > 0 && index !== 0 ? false : true
      },
      {
        name: '下移',
        action: this.moveCondition.bind(this, { criterion: criterionParent, index, direction: 'down' }),
        disabled:
          !isFirst &&
          criterionParent.criterions &&
          criterionParent.criterions.length > 0 &&
          index !== criterionParent.criterions.length - 1
            ? false
            : true
      },
      {
        name: '粘贴(V)',
        action: this.pasteCondition.bind(this, criterion),
        disabled: this.state.isPastedAbled
      },
      {
        name: '删除',
        action: this.delJoinConditionConfirm.bind(this, {
          item: criterion,
          criterion: criterionParent,
          isContain: true,
          isFirst
        }),
        disabled: !isFirst ? false : true
      },
      {
        name: '删除子条件',
        action: this.delJoinConditionConfirm.bind(this, {
          item: criterion,
          criterion: criterionParent,
          isContain: false,
          isFirst
        }),
        disabled: criterion.criterions && criterion.criterions.length > 0 ? false : true
      }
    ];
    const style = {
      position: 'absolute',
      left: pos.endX + 'px',
      top: pos.endY + 'px'
    };

    return (
      <div>
        <div
          className={joinSelectedClass}
          style={style}
          id={criterion.id}
          onClick={this.getNewMenusAndSelectedThis.bind(this, criterion, menus)}
        >
          {type} <Icon type="down" />
        </div>
        <div className="conditions">
          {criterion.criterions &&
            criterion.criterions.length > 0 &&
            criterion.criterions.map((item, index) => {
              this.renderIndex = index === 0 ? (this.renderIndex - 1 < 0 ? 0 : this.renderIndex - 1) : this.renderIndex;
              const position = {
                startX: pos.endX + 50,
                endX: pos.endX + 80,
                startY: pos.endY + 12,
                endY: 30 * this.renderIndex
              };
              this.renderIndex++;
              if (item.criterions && item.criterions instanceof Array) {
                return (
                  <div key={item.id}>
                    {this.renderCondition({
                      type: this.getConditionType(item.junctionType),
                      criterion: item,
                      criterionParent: criterion,
                      pos: position,
                      index
                    })}
                  </div>
                );
              } else {
                return (
                  <ConditionItem
                    {...position}
                    key={item.id}
                    item={item}
                    index={index}
                    criterion={criterion}
                    del={this.delCondition}
                    copy={this.copyConditions}
                    move={this.moveCondition}
                    copyAdd={this.addCondition}
                    selectedThis={this.selectedThis}
                    {...this.getProps()}
                  />
                );
              }
            })}
        </div>
      </div>
    );
  }

  render() {
    // window.a = window.a + 1;
    // console.log(this.props, window.a);
    const position = {
      startX: 10,
      endX: 10,
      startY: 0,
      endY: 0
    };

    const criterion = this.props.data;
    const type = this.getConditionType(criterion.junctionType);
    return (
      <div className="condition">
        {this.renderCondition({ type, criterion, criterionParent: criterion, pos: position, isFirst: true })}
      </div>
    );
  }
}

export default Condition;
