/**
 * DynamicVariableValue 搜索变量设置组件
 * props: @isValue 是否为值，true->值类型，false/undefined->type类型
 * props: @data 数据对象
 *   */
import React, { Component } from 'react';
import { Popover, Descriptions, AutoComplete, Icon, Row, Col } from 'antd';
import InputSearch from '../../../../components/InputText/InputSearch';
import * as services from '../../../../services/api/index';
import { randomKey } from '../../../../utils/utils';
import storage from '../../../../utils/storage';
const { Option } = AutoComplete;
class DynamicVariableValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      newData: {},
      dataSource: []
    };
  }
  componentDidMount() {
    const { isValue } = this.props;
    if (isValue) {
      this.initData(this.props.data);
    } else {
      this.initData(this.props.data.leftPart);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isValue } = this.props;
    if (isValue) {
      this.initData(nextProps.data);
    } else {
      this.initData(nextProps.data.leftPart);
    }
  }
  setValue = item => {
    const { data, isValue } = this.props;
    return new Promise((resolve, reject) => {
      if (item) {
        if (isValue) {
          const dataJson = this.resetDataToJson(item.props.item);
          for (let key in dataJson) {
            data[key] = dataJson[key];
          }
          data.valueType = 'Method';
        } else {
          data.leftPart = this.resetDataToJson(item.props.item);
          data.type = 'method';
        }
        this.updateRule();
        resolve();
      }
    });
  };
  updateRule = () => {
    this.props.updateRule();
  };
  // 数据转成服务端json格式
  resetDataToJson = data => {
    const newJsonData = {
      beanId: 'ApiBuiltInAction',
      beanLabel: 'Api函数',
      methodLabel: '获取远程指标V2',
      methodName: 'getRemoteDataV2',
      returnType: data.returnType
    };
    const meta = { ...data.meta, name: data.name, description: data.description };
    const parameters = [
      {
        name: 'apiNo',
        type: 'String',
        value: {
          name: 'sourceType',
          valueType: 'Input',
          content: data.sourceType
        }
      },
      {
        name: '指标名称',
        type: 'String',
        value: {
          valueType: 'Input',
          content: data.name ? `${data.categoryCN}.${data.nameCN}` : data.nameCN
        }
      },
      {
        name: 'feature_name',
        type: 'String',
        value: {
          valueType: 'Input',
          content: data.name ? data.name : data.nameCN
        }
      },
      {
        name: 'category',
        type: 'String',
        value: {
          valueType: 'Input',
          content: data.name ? data.category : storage.projectName
        }
      },
      {
        name: 'version',
        type: 'String',
        value: {
          valueType: 'Input',
          content: data.name ? data.version : 'LATEST'
        }
      },
      {
        name: 'meta',
        type: 'String',
        value: {
          valueType: 'Input',
          content: encodeURIComponent(JSON.stringify(meta))
        }
      }
    ];
    newJsonData.parameters = parameters;
    return newJsonData;
  };
  // 服务端json数据转前端用的数据
  initData = data => {
    const newData = {};
    for (let i in data.parameters) {
      if (data.parameters[i].name === 'apiNo') {
        newData.sourceType = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === '指标名称') {
        newData.nameCN = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === 'feature_name') {
        newData.name = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === 'category') {
        newData.category = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === 'version') {
        newData.version = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === 'meta') {
        newData.meta = data.parameters[i].value.content;
      }
      if (data.parameters[i].name === 'description') {
        newData.description = data.parameters[i].value.content;
      }
    }
    try {
      newData.meta =
        newData.meta instanceof Object ? newData.meta || {} : JSON.parse(decodeURIComponent(newData.meta || {})) || {};
      newData.description = newData.meta.description;
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        newData
      });
    }
  };
  // 描述信息
  getMeta = data => {
    const meta = data.meta;
    return (
      <div className='tantuer'>
        {!data.nameCN && data.msg ? (
          <span style={{ padding: '5px' }}>
            {data.name}：{data.msg}
          </span>
        ) : (
          <>
            {Object.keys(data).length > 0 && (
              <Descriptions bordered column={1}>
                {data.name && <Descriptions.Item label='名称'>{data.name}</Descriptions.Item>}
                {meta && meta.channelName && meta.channelName !== 'null' && (
                  <Descriptions.Item label='通道名'>{meta.channelName}</Descriptions.Item>
                )}
                {meta && meta.interfaceName && meta.interfaceName !== 'null' && (
                  <Descriptions.Item label='接口名'>{meta.interfaceName}</Descriptions.Item>
                )}
                {meta && meta.featureType && meta.featureType !== 'null' && (
                  <Descriptions.Item label='特征类型'>
                    {meta.featureType === 'interior' ? '自有' : '三方'}
                  </Descriptions.Item>
                )}
                {data.returnType && <Descriptions.Item label='返回类型'>{data.returnType}</Descriptions.Item>}

                {meta && meta.expensesType && meta.expensesType !== 'null' && (
                  <Descriptions.Item label='收费类型'>
                    {meta.expensesType === 'free' ? '免费' : '付费'}
                  </Descriptions.Item>
                )}
                {meta && meta.description && meta.description !== 'null' && (
                  <Descriptions.Item label='描述'>{meta.description}</Descriptions.Item>
                )}
              </Descriptions>
            )}
          </>
        )}
      </div>
    );
  };

  // onSearch
  onSearch = async value => {
    const params = {
      key: value,
      pageNum: 1,
      pageSize: 10,
      project: storage.projectName
    };
    const res = await services.common.search(params);
    if (res.code !== 0) {
      return;
    }

    this.setState({
      dataSource: res.data.featureResources.concat(res.data.featureSearchFail),
      isFail: res.data.featureSearchFail.length > 0 ? true : false
    });
  };

  renderDataSource = () => {
    const { dataSource } = this.state;
    return dataSource.map(item => {
      return (
        <Option key={randomKey()} text={item.nameCN} item={item} disabled={!item.nameCN}>
          <Popover overlayClassName='tantuer' content={this.getMeta(item)} placement='rightTop'>
            <Row type='flex' align='middle' justify='space-between'>
              {item.nameCN ? (
                <Col>{item.nameCN}</Col>
              ) : (
                <>
                  <Col>
                    <span style={{ color: '#ff0000' }}>错误信息</span>
                  </Col>
                  <Col>
                    <Icon type='right' />
                  </Col>
                </>
              )}
            </Row>
          </Popover>
        </Option>
      );
    });
  };

  render() {
    const { newData } = this.state;
    const props = {
      updateRule: this.props.updateRule,
      // file: this.props.file,
      rule: this.props.rule,
      disabled: this.props.disabled
    };
    return (
      <>
        {this.props.disabled ? (
          <span style={{ color: '#19885f' }}>{newData.nameCN || ''}</span>
        ) : (
          <InputSearch
            callback={this.setValue}
            value={newData.nameCN || ''}
            onChange={this.onSearch}
            placeholder='搜索变量'
            dataSource={this.renderDataSource()}
            content={this.getMeta(newData)}
            {...props}
          />
        )}
      </>
    );
  }
}
export default DynamicVariableValue;
