import events from 'events';
export const SHOW_LOADING = 'show_loading';
export const HIDE_LOADING = 'hide_loading';
export const SHOW_TAB = 'show_tab';
export const CLOSE_ALL_TAB = 'close_all_tab';
export const TREE_NODE_CLICK = 'tree_node_click';
export const TREE_DIR_NODE_CLICK = 'tree_dir_node_click';
export const OPEN_KNOWLEDGE_TREE_DIALOG = 'open_knowledge_tree_dialog';
export const HIDE_KNOWLEDGE_TREE_DIALOG = 'hide_knowledge_tree_dialog';
export const OPEN_VERSION_SELECT_DIALOG = 'open_version_select_dialog';
export const HIDE_VERSION_SELECT_DIALOG = 'hide_version_select_dialog';
export const OPEN_CATEGORY_REFACTOR_DIALOG = 'open_category_refactor_dialog';
export const HIDE_CATEGORY_REFACTOR_DIALOG = 'hide_category_refactor_dialog';
export const OPEN_FEATURE_REFACTOR_DIALOG = 'open_category_refactor_dialog';
export const HIDE_FEATURE_REFACTOR_DIALOG = 'hide_category_refactor_dialog';
export const OPEN_CONTENT_REFACTOR_DIALOG = 'open_content_refactor_dialog';
export const HIDE_CONTENT_REFACTOR_DIALOG = 'hide_content_refactor_dialog';

export const OPEN_EFFECT_FILE_DIALOG = 'open_effect_file_dialog';
export const CLOSE_EFFECT_FILE_DIALOG = 'hide_effect_file_dialog';
export const CHECK_USERINFO = 'check_userinfo';

export const SHOW_ERROR_MSG = 'show_error_msg'; //弹出错误信息
export const SHOW_WARNING_MSG = 'show_warning_msg'; //弹出警告信息
export const SHOW_SUCCESS_MSG = 'show_success_msg'; //弹出成功信息
export const SHOW_INFO_MSG = 'show_info_msg'; //弹出详细信息

export const eventEmitter = new events.EventEmitter();
