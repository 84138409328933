const initialState = {
  path: {
    width: 0,
    height: 0,
    left: 0,
    top: 0
  },
  wp: {
    left: 32,
    top: 166
  }
};
export default {
  name: 'selectedPath',
  state: initialState,
  reducers: {
    updatePath: (state, { data }) => {
      return {
        ...state,
        path: data
      };
    },
    updateWp: (state, { data }) => {
      return {
        ...state,
        wp: data
      };
    }
  }
};
